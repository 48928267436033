
@if(!isPassengerDetails)
{
    <div class="text-xl font-semibold mt-3">
      {{ 'bookings.passenger_information' | translate }}
    </div>
    <div class="text-base mt-2 mb-3">
      {{ 'bookings.selected_passenger_message' | translate:{passengerCount: addedPassengerList?.length || 0} }}
    </div>

}

<p-dropdown [options]="addedPassengerList" [ngModel]="selectAddedPassenger" placeholder="Select added passenger"
[ngModelOptions]="{standalone : true}" optionLabel="name" [showClear]="false"
  (onChange)="onSelectAddedPassenger($event)"  [ngClass]="{ 'passenger-details': isPassengerDetails}">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center gap-2">
      <div class="passenger-popup bold-name w-full">
        <span class="ellipsis mr-4 w-6">
          {{selectAddedPassenger?.pass_full_name}}
          {{selectAddedPassenger?.name}}
        </span>
        <span class="unique ellipsis mr-2">
          <span class="booking-car-search-bar justify-content-center align-items-center mr-2"><i class="{{miIcons.BOOKING_CAR}} inline-block cursor-pointer wh-24"></i></span>
          <span class="booking-car-search-bar justify-content-center align-items-center"><i class="{{miIcons.BOOKING_AIRPORT}} inline-block cursor-pointer wh-24"></i></span>
        </span>
      </div>
    </div>
  </ng-template>

  <ng-template let-passenger pTemplate="item">
    <div class="flex align-items-center gap-2">
      <div class="passenger-popup bold-name">

        <!-- <img [src]="passenger.passenger_profile_image" class="vertical-align-middle border-round-sm list-img mr-2" /> -->
        <app-mi-image-container [miImage]="passenger.passenger_profile_image || defaultLoaderImg" miHeight="30" miWidth="30"></app-mi-image-container>
        <span class="ellipsis mr-2">
          {{passenger.pass_full_name}}
        </span>
        <span class="unique ellipsis mr-2">
          ({{passenger.unique_id}}) ({{passenger.passenger_type}})
        </span>

        @if(!isPassengerDetails){
        <i class="{{miIcons.OFF_OUTLINE_CLOSE}} wh-24 close-icon cursor-pointer" (click)="onRemove(passenger)"></i>
    }
      </div>

    </div>
  </ng-template>
</p-dropdown>
