<div class="flex flex-wrap justify-content-center">
    <div class="flex-1 flex justify-content-end mr-4 ml-4">
        <span class="p-input-icon-left search-input">
            <i class="pi search-icon relative left-20 cursor-pointer"
                [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
                (click)="searchValue ? clearSearch() : null"></i>
            <input class="w-full search-filter" type="text" pInputText  (input)="onSearch($event)"
                [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue"/>
        </span>
    </div>
</div>

<div class="m-4">
    @for(address of addressList; track address) {
        <div class="flex justify-content-between align-items-center address-item border-none">
            <div class="">
                <div class="address-header">
                    @if (address['select_location_type'] === 'Home') {
                        <i class="{{miIcons.HOME_DARK}} wh-16 inline-block mr-2 relative top-2"></i>
                    }
                    @else if (address['select_location_type'] === 'Hotel') {
                        <i class="{{miIcons.HOTEL_DARK}} wh-16 inline-block mr-2 relative top-2"></i>
                    }
                    @else {
                        <i class="{{miIcons.DRIVER_RATING}} wh-16 inline-block mr-2 relative top-2"></i>
                    }
                    
                    <span class="font-semibold font-16">
                        {{ address['select_location_type'] }}
                    </span>
                </div>
                <div class="ml-5 mt-2">
                    @if(address['select_location_address']) {
                        {{ address['select_location_address'] }},
                    }
                    @if(address['address_line_1']) {
                        {{ address['address_line_1'] }},
                    }
                    @if(address['address_line_2']) {
                        {{ address['address_line_2'] }},
                    }
                    @if(address['nearby_landmark']) {
                        {{ address['nearby_landmark'] }},
                    }
                    @if(address['state']) {
                        {{ address['state'] }},
                    }
                    @if(address['country']) {
                       {{ address['country'] }} - {{ address['postal_code'] }}
                    }
                </div>
            </div>
            <div class="flex address-action cursor-pointer p-1 border-round">
                <i class="{{miIcons.BASIC_DELETE}} wh-21 inline-block" (click)="removeAddress(address)"></i>
            </div>
        </div>
        <hr>
    }
</div>
