import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { entityResponse } from 'app/modules/vehicles/models/attribute.models';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-settings-time-settings',
  standalone: true,
  imports: [InputTextModule, FormsModule, ReactiveFormsModule, TranslateModule, ButtonModule, MiFormComponent, TabViewModule, ToastModule, AccessProviderDirective],
  templateUrl: './settings-time-settings.component.html',
  styleUrl: './settings-time-settings.component.scss'
})
export class SettingsTimeSettingsComponent {
  timeSettingsAttributeData: any;
  attributeLabelsForTimeSettings = {};
  country: Country;
  language: Language;
  timeSettingsModuleName = AppSettings.ENTITY_TYPE.TIME_SETTINGS;
  timeSettingsEntityData: any;
  timeSettingEntityId: any;
  timeSettingsData: any;
  previousBtnLabel: any = this.cs.getLabel('lbl_cancel');
  saveTimeSettingsBtnLabel: any = this.cs.getLabel('settings.lbl_save_time_settings');
  activeIndex = 0;
  attributeValues: any;
  entityId: any;
  showForm: boolean = false;

  constructor(
    private configService: ConfigService,
    public cs: CommonBindingDataService,
    private messageService: MessageService,
    private entityService: EntityService) { }

  ngOnInit() {
    this.getTimeSettings();
    this.setAppSettings();
    this.getAttributesForTimeSetting();
    this.setTimeSettingsEntityData();
  }

  getTimeSettings() {
    this.entityService.getEntityDetailsByView(AppSettings.ENTITY_TYPE.TIME_SETTINGS, {}).subscribe({
      next: (response: any) => {
        this.entityId = response.entityId;
        this.getEntityDetails();
      },
      error: (error) => {
        this.showForm = true;
      }
    })
  }

  getEntityDetails() {
    this.entityService.getEntity(this.entityId, AppSettings.ENTITY_TYPE.TIME_SETTINGS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe({
      next: (response: any) => {
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        this.showForm = true;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  onCancel() {

  }

  private setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  onNextBtnClick() {
    const btn = document.getElementById('time');
    btn.click();
  }


  getAttributesForTimeSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_TYPE.TIME_SETTINGS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.setView(res);
      }
    });
  }

  setView(res) {
    this.attributeLabelsForTimeSettings = this.cs.getAttributeLabels(res);
    this.timeSettingsAttributeData = this.cs.getOrganizedAttribute(res);
  }

  setTimeSettingsEntityData() {
    this.timeSettingsEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.TIME_SETTINGS
    }
  }

  saveTimeSettings(event) {
    const timeSettingsFormData = event;
    if (this.entityId) {
      this.timeSettingEntityId = this.entityId;
      this.saveTimeSettingsData(this.timeSettingsEntityData.entityCode, timeSettingsFormData);
    } else {
      this.entityService.createEntities(this.timeSettingsEntityData.entityCode, this.timeSettingsEntityData).subscribe({
        next: (res: entityResponse) => {
          this.timeSettingEntityId = res.entityId;
          this.saveTimeSettingsData(this.timeSettingsEntityData.entityCode, timeSettingsFormData);
        },
        error: (error) => {
          this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
        }
      });
    }
  }

  saveTimeSettingsData(entityCode, timeSettingsFormData) {
    this.timeSettingsData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.TIME_SETTINGS,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    for (const [key, value] of Object.entries(timeSettingsFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.timeSettingsData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.timeSettingsData.data = this.cs.mapAttributeIds(this.timeSettingsData.data, this.timeSettingsAttributeData.tabs);
    this.entityService.saveAttributeData(entityCode, this.timeSettingEntityId, this.timeSettingsData).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.lbl_time_settings_saved_successfully') });
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }
}
