import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UIElementService {

  private sideDriverDrawer = signal(0);
  private geoLocationDialog = signal(0);

  // Getter for the signal
  get sideDriverDrawerState() {
    return this.sideDriverDrawer();
  }

  // Methods to update the signal
  showSideDriverDrawer() {
    this.sideDriverDrawer.set(1);
  }

  hideSideDriverDrawer() {
    this.sideDriverDrawer.set(0);
  }

  get geoLocationDialogState() {
    return this.geoLocationDialog();
  }

  showGeoLocationDialog() {
    this.geoLocationDialog.set(1);
  }

  hideGeoLocationDialog() {
    this.geoLocationDialog.set(0);
  }
  
}
