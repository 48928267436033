import { Component, Input } from '@angular/core';
import dayjs from 'dayjs';
import { AppSettings } from '../../../../../shared/app.settings';
import { TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { setWeek } from '../../../../../shared/utility-functions/schedule'
import { generateTimeSlots } from '../../../../../shared/utility-functions/schedule'
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { Country } from 'app/modules/shared/models/country';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-schedule-tab',
  templateUrl: './schedule-tab.component.html',
  styleUrl: './schedule-tab.component.scss',
  standalone: true,
  imports: [TranslateModule, DatePipe],
  providers: [DatePipe]
})
export class ScheduleTabComponent {
  @Input() driverDetail: any;
  weekDaysList: any = [];
  weekday = AppSettings.WEEK_DAYS;
  dayNumber: number = 0;
  weekNumber: number = 0;
  selectedMonthOrDay: any;
  timeSlots: string[] = [];
  country: Country;
  scheduleList: any[] = [];
  selectedDate: any;
  attributeIdForDriverEntityId: any;
  attributeIdForpickUpDate: any;
  attributeIdForPickUpTime: any;
  bookingAttributeData: any;
  currentDate: any;
  todaysTotalMinutes: any;

  constructor(private datePipe: DatePipe,
    private entityService: EntityService,
    private configService: ConfigService,
    private cs: CommonBindingDataService,
  ) {

  }

  ngOnInit() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    this.selectedDate = date.getTime();

    let cDate = new Date();
    cDate.setHours(0,0,0,0);
    this.currentDate = cDate.getTime();

    let currentDate = new Date();
    this.todaysTotalMinutes = (currentDate.getHours() * 60) + currentDate.getMinutes();
    setWeek(this.weekNumber, this.weekday);
    this.timeSlots = generateTimeSlots();
    this.updateWeek();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.getAttributes();
  }

  getAttributes() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.DRIVER_BOOKING_SCHEDULE_ON_DISPATCH_VIEW).subscribe(res => {
      if (res) {
        this.bookingAttributeData = this.cs.getOrganizedAttribute(res);
        this.attributeIdForDriverEntityId = this.getAttributeId('driver_entity_id');
        this.attributeIdForpickUpDate = this.getAttributeId('pickup_date');
        this.attributeIdForPickUpTime = this.getAttributeId('pickup_time');
        this.getSchedule();
      }
    });
  }

  getAttributeId(attributeCode: string): number | undefined {
    for (const tab of this.bookingAttributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getSchedule() {
    let requestBody = this.setRequestBodyToFetchScheduleList()
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING, requestBody, true).subscribe((result: any) => {
      this.scheduleList = [];
      this.setScheduleList(result);

    });
  }

  setRequestBodyToFetchScheduleList() {
    let date = new Date(this.selectedDate);
    date.setHours(23, 59, 59, 0);
    return {
      limit: 10,
      offset: 0,
      searchStr: '',
      filters: [
        {
          attributeId: this.attributeIdForDriverEntityId,
          attributeValue: this.driverDetail.driver.driverId
        }
      ],
      advanceFilters: [
        {
          attributeId: this.attributeIdForpickUpDate,
          fromValue: this.selectedDate,
          toValue: date.getTime(),
          comparisonOperator: 'BETWEEN'
        }
      ],
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
      actionStatus: '',
      tableViewCode: AppSettings.VIEW_CODE.DRIVER_BOOKING_SCHEDULE_ON_DISPATCH_VIEW
    }
  }

  setScheduleList(result) {
    result.data.forEach(booking => {
      this.scheduleList.push({
        bookingCode: booking.values.booking_code,
        pickupTime: booking.values.pickup_time,
        startTimeHour: this.datePipe.transform(booking.values.pickup_time, 'hh'),
        startTimeMinutes: this.datePipe.transform(booking.values.pickup_time, 'mm'),
        dropTime: null,
        distance: '5.78 Miles'
      });
    });
  }

  fetchScheduleForSelectedDay(day) {
    this.selectedDate = day.date;
    this.getSchedule();
  }

  previousClicked() {
    this.weekNumber--;
    this.weekDaysList = setWeek(this.weekNumber, this.weekday);
    this.selectedDate = this.weekDaysList[0].date;
    this.getSchedule();
  }

  nextClicked() {
    this.weekNumber++;
    this.weekDaysList = setWeek(this.weekNumber, this.weekday);
    this.selectedDate = this.weekDaysList[0].date;
    this.getSchedule();
  }

  updateWeek() {
    this.weekDaysList = setWeek(this.weekNumber, this.weekday);
  }

}
