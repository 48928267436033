import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, OnDestroy } from "@angular/core";
import { MenuItem } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { BookingListCardComponent } from "./booking-list-card/booking-list-card.component";
import data from "../../../../../../src/assets/json/dispatch/data.json";
import { EntityList } from "../../../vehicles/models/entity.models";
import { AppSettings } from "../../../shared/app.settings";
import { Country } from "../../../shared/models/country";
import { Language } from "../../../shared/models/language";
import { EntityService } from "../../../shared/services/entity.service";
import { BookingResponse } from "../../../bookings/models/booking.models";
import { ConfigService } from "../../../shared/services/config.service";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { NoResultFoundComponent } from "app/modules/shared/components/no-result-found/no-result-found.component";
import { BookingService } from "app/modules/bookings/services/booking.service";
import dayjs from "dayjs";

@Component({
  selector: "app-booking-section",
  templateUrl: "./booking-section.component.html",
  styleUrls: ["./booking-section.component.scss"],
  standalone: true,
  imports: [TabMenuModule, BookingListCardComponent, NoResultFoundComponent],
})
export class BookingSectionComponent implements OnInit, OnDestroy {
  @Output() showBookingDetails: EventEmitter<any> = new EventEmitter<any>();
  items: MenuItem[];
  bookingsList: any = [];
  newBookingStatusList: any[] = [];
  activeItem: MenuItem;
  country: Country;
  language: Language;
  entityData = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: '',
    advanceFilters: [],
    viewCode: AppSettings.VIEW_CODE.BOOKING_LIST_ON_DISPATCH_VIEW
  };
  prevSearch;
  maxDataReached = false;
  private loading = false;
  scrollTimeoutId: any;
  bookingStatuses: any = [];
  bookingStatusAttributeId: any;
  allStatusList = [AppSettings.BOOKING_STATUS_CODES.ADMIN_CONFIRMED, AppSettings.BOOKING_STATUS_CODES.RECONFIRMED,
    AppSettings.BOOKING_STATUS_CODES.DRIVER_ASSIGNED];
  newStatusList = [AppSettings.BOOKING_STATUS_CODES.ADMIN_CONFIRMED];
  assignedStatusList = [AppSettings.BOOKING_STATUS_CODES.DRIVER_ASSIGNED];
  completedStatusList = [AppSettings.BOOKING_STATUS_CODES.COMPLETED];
  selectedTab = 'ALL';
  constructor(private entityService: EntityService,
    private configService: ConfigService,
    private cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private bookingService: BookingService
  ) { }

  ngOnInit() {
    this.getBookingStatuses();
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.initializeTabs();
    this.getBookingStatusCount();
    this.getAttributeDetailsForBookingFilter();

    this.getBookingList(false);
    this.activeItem = this.items[0];

    this.scrollTimeoutId = setTimeout(() => {
      const cb = () => {
        if (!this.loading && !this.maxDataReached) {
          this.entityData.offset = this.entityData.offset + AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT;
          console.log('in timeout');
          this.getBookingList(false);
        }
      };
      this.applyInfiniteScroll('.booking-detail-container', cb);
    }, 500);
  }

  getBookingStatusCount() {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      eventCode: '',
      apiType: 'any'
    }
    this.bookingService.getBookingStatusCount(requestBody).subscribe((result: any) => {
      console.log(result);
      this.items.forEach(bookingTabs => {
        switch(bookingTabs.label) {
          case AppSettings.BOOKING_TAB_LIST_IN_DISPATCH_VIEW.ALL :
            bookingTabs.number = result.admin_confirmed + result.driver_assigned;
            break;
          case AppSettings.BOOKING_TAB_LIST_IN_DISPATCH_VIEW.NEW :
            bookingTabs.number = result.admin_confirmed;
            break;
          case AppSettings.BOOKING_TAB_LIST_IN_DISPATCH_VIEW.ASSIGNED :
            bookingTabs.number = result.driver_assigned;
            break;
          case AppSettings.BOOKING_TAB_LIST_IN_DISPATCH_VIEW.COMPLETED :
            bookingTabs.number = result.completed;
            break;
        }
      });
    })
  }

  ngOnDestroy() {
    if (this.scrollTimeoutId) {
      clearTimeout(this.scrollTimeoutId);
    }
  }
  applyInfiniteScroll(selector, onEndPageCallback) {
    const contentEl = document.querySelector(selector) as HTMLElement;
    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const scrollThreshold = heightDiff * 0.6;
      if (contentEl.scrollTop >= scrollThreshold && !this.loading) {
        onEndPageCallback();
      }
    };

    const checkNeedMoreDataImmediately = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      if (heightDiff <= 0 && !this.loading && !this.maxDataReached) {
        onEndPageCallback();
      }
    };
    contentEl.addEventListener("scroll", handleInfiniteScroll);
    setTimeout(() => checkNeedMoreDataImmediately(), 100);
  }

  getAttributeDetailsForBookingFilter() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW)
    .subscribe(result => {
      console.log(result);
      const tabs = result.tabs.find(ele => ele.tabCode === 'default');
      const groups = tabs.groups.find(ele => ele.code === 'default');
      const statusAttribute = groups.fields.find(ele => ele.attributeCode === 'booking_status');
      this.bookingStatusAttributeId = statusAttribute ? statusAttribute.attributeId : null;
      console.log(this.bookingStatusAttributeId);
      this.entityData.filters = [];
      this.setBookingStatusFilter(this.allStatusList);
    })
  }

  setBookingStatusFilter(statusList) {
    const bookingStatusesForAllTab = this.bookingStatuses.map(ele =>{
      if (statusList.includes(ele.bookingCode)) {
          return ele.bookingStatusId;
        }
    })
    this.entityData.filters.push({
      attributeId: this.bookingStatusAttributeId,
      attributeValue: bookingStatusesForAllTab.filter(ele => ele)
    });
    this.getBookingList(false);
  }

  initializeTabs() {
    this.items = [
      { label: "ALL", number: '', booking_status: '' },
      { label: "NEW", number: '', booking_status: '1' },
      { label: "ASSIGNED", number: '', booking_status: '3' },
      { label: "COMPLETED", number: '', booking_status: '9' },
    ];
  }

  ngDoCheck() {
    this.entityData.searchStr = this.cs.getSearchData();
    if (this.entityData.searchStr !== this.prevSearch && (this.entityData.searchStr.length >= 3 || this.entityData.searchStr.length === 0)) {
      this.prevSearch = this.entityData.searchStr;
      this.setScrollTopOfBookingDetailContainerDiv();
      this.maxDataReached = false;
      this.getBookingList(true);
    }
  }

  handleBookingCardClicked(event) {
    const hasData = event !== null && event !== undefined;
    const eventData = hasData ? event : null;
    const emissionData = { hasData: hasData, eventData: eventData };
    this.showBookingDetails.emit(emissionData);
  }

  getBookingStatuses() {
    this.bookingService.getBookingStatuses().subscribe({
      next: (result) => {
        this.bookingStatuses = result;
        this.entityService.setBookingStatuses(this.bookingStatuses);
      },
      error: (error) => {}
    });
  }

  getBookingList(isFromFilter) {
    this.bookingsList = isFromFilter ? [] : this.bookingsList;
    if (isFromFilter) {
      this.setFilterAccordingToSelectedTab()
    }
    if (this.loading) return;
    this.loading = true;
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING, this.entityData)
    .subscribe({
      next: (res: BookingResponse) => {
        this.loading = false;
        if (!res) {
          console.error('Invalid response.');
          return;
        }
        const newBookings = res.data?.map(data => {
          return { id: data.id, ...data.values };
        }) || [];
        this.items.find(ele => ele.label === this.selectedTab).number = res.count;
        // this.getBookingStatusCount();
        this.bookingsList = [...this.bookingsList, ...newBookings];
        this.newBookingStatusList = this.bookingsList
        if (this.newBookingStatusList.length === res.count) {
          this.maxDataReached = true;
        }
      },
       error: (error)=> {

       }
    });
  }

  onActiveItemChange(event) {
    this.items[0] = event;
    this.selectedTab = event.label;
    this.resetData();
    this.setScrollTopOfBookingDetailContainerDiv();
    this.setFilterAccordingToSelectedTab();
    this.getBookingList(false);
  }

  setFilterAccordingToSelectedTab() {
    if (this.selectedTab === 'ALL') {
      this.setBookingStatusFilter(this.allStatusList);
    } else if (this.selectedTab === 'NEW') {
      this.setBookingStatusFilter(this.newStatusList);
    } else if (this.selectedTab === 'ASSIGNED') {
      this.setBookingStatusFilter(this.assignedStatusList);
    } else if (this.selectedTab === 'COMPLETED') {
      this.setBookingStatusFilter(this.completedStatusList);
    }
  }

  resetData() {
    this.bookingsList = [];
    this.entityData.filters = [];
    this.entityData.limit = 10;
    this.entityData.offset = 0;
    this.maxDataReached = false;
  }

  setScrollTopOfBookingDetailContainerDiv() {
    var bookingDiv = document.getElementById('bookingContainer');
    bookingDiv.scrollTop = 0;
  }

}
