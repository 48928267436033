import { Injectable } from "@angular/core";
import { ConfigService } from "app/modules/shared/services/config.service";
import { RestApiService } from "app/modules/shared/services/rest-api.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AppSettings } from "../app.settings";
import dayjs from "dayjs";

@Injectable({
  providedIn: 'root'
})
export class MetaDataService {
  public countrySubject = new BehaviorSubject<any>(null);
  public languageSubject = new BehaviorSubject<any>(null);
  public menusSubject = new BehaviorSubject<any>(null);
  public eventResponseSubject = new BehaviorSubject<any>(null);
  public generalSettingsSubject = new BehaviorSubject<any>(null);
  country$ = this.countrySubject.asObservable();
  language$ = this.languageSubject.asObservable();
  menus$ = this.menusSubject.asObservable();
  eventResponse$ = this.eventResponseSubject.asObservable();
  generalSettings$ = this.generalSettingsSubject.asObservable();
  public mobileNumber: string = '';
  public currency: string = '';
  public shortDateFormat: string = '';
  public timeZone: string = '';
  public timeFormat: string = '';
  // public distanceMeasurement: string = '';
  // public fuelVolume: string = '';
  public systemOfMeasurement: string = '';

  constructor(private restApiService: RestApiService, private confService: ConfigService) { }

  updateMetaData(country: any, language: any, menus: any, eventResponse: any, generalSettings: any) {
    this.countrySubject.next(country);
    this.languageSubject.next(language);
    this.menusSubject.next(menus);
    this.eventResponseSubject.next(eventResponse);
    this.generalSettingsSubject.next(generalSettings);

    this.generalSettings$.subscribe((settings) => {
      if (settings) {
        this.mobileNumber = settings.mobile_number || '';
        this.currency = settings.currency || '';
        this.shortDateFormat = settings.short_date_format || '';
        this.timeZone = settings.time_zone || '';
        this.timeFormat = settings.time_format || '';
        // this.distanceMeasurement = settings.distance_measurement || '';
        // this.fuelVolume = settings.fuel_volume || '';
        this.systemOfMeasurement = settings.system_of_measurement || AppSettings.SET_UNIT;
        console.log(settings);
      }
    });
  }

  metaDataMultipleApi(data): Observable<any> {
    return this.restApiService.post('METADATA MULTIPLE API CALL', `${this.confService.getLoggedInTenantCode()}/api/v1/multi-api-call`, data, 'page-center');
  }

  getDate(date) {
    return dayjs(date).format(this.shortDateFormat);
  }

  showDate(date: Date): string {
    const format = this.shortDateFormat;
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return format.replace(/(DD|MM|YYYY)/g, (match) => {
      switch (match) {
        case 'DD': return day;
        case 'MM': return month;
        case 'YYYY': return year.toString();
        default: return match;
      }
    });
  }
  
}