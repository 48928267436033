import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { DashboardNavbarComponent } from "../dashboard-navbar/dashboard-navbar.component";
import { ToggleButtonModule } from "primeng/togglebutton";
import { AppSettings } from "../../../shared/app.settings";
import { FormsModule } from "@angular/forms";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import { DashboardService } from "../dashboard/dashboard.service";
import { ConfigService } from "app/modules/shared/services/config.service";
import { EventService } from "app/modules/events/services/event.service";
import { EntityService } from "app/modules/shared/services/entity.service";
import { TranslateModule } from "@ngx-translate/core";
import { NgClass } from "@angular/common";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { MultiSelect, MultiSelectModule } from "primeng/multiselect";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { Country } from "app/modules/shared/models/country";
import { SidebarModule } from "primeng/sidebar";

@Component({
  selector: "app-maps",
  templateUrl: "./maps.component.html",
  styleUrls: ["./maps.component.scss"],
  standalone: true,
  imports: [DashboardNavbarComponent, ToggleButtonModule, ButtonModule, InputTextModule, FormsModule, AccessProviderDirective, TranslateModule, NgClass, MultiSelectModule, SidebarModule,],
})
export class MapsComponent implements OnInit {
  @ViewChild('multiSelect') multiSelect!: MultiSelect;
  map: google.maps.Map;
  checked: boolean = false;
  infoWindow: google.maps.InfoWindow;
  marker: any = null;
  markers: any = [];
  records: any = [];
  vehicleTypeList;
  queueLocationList;
  queueLocations;
  searchValue;
  filterList;  
  locationFilters;
  categoryAttributeIdInLocationFilter;
  filterIcon = 'pi pi-search';
  selectedQueueLocation: any[];
  selectedVehicleType: any[];
  uniqueId: string = 'dashboard_map';
  country: Country;
  rightDriversSectionVisible: boolean = false;
  rightDialogPosition: string = "center";
  position: string;
  entityData = {
    forTenantCode: this.configService.getForTenantCode(),
    eventCode: this.eventService.getSelectedEventcode(),
    vehicleBodyTypes: [],
    queueIds: [],
    searchStr: ''
  }
  entityDataForVehicleType: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.VEHICLE_TYPE
  };
  entityDataForQueueLocation = {
    limit: 2000,
    offset: 0,
    searchStr: '',
    filters: [],
    forTenantCode: this.configService.getForTenantCode(),
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    countryCode: ''
  }

  constructor(private dashboardService: DashboardService, private configService: ConfigService,
    private eventService: EventService, private entityService: EntityService, 
    private cs: CommonBindingDataService, private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.initMap();
    this.searchVehicleTypes();
    this.getFilterViewForLocation();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
  }

  async initMap(): Promise<void> {
    const { Map, InfoWindow } = (await google.maps.importLibrary(
      "maps"
    )) as google.maps.MapsLibrary;

    this.map = new Map(document.getElementById("map") as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 10,
      zoomControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      mapId: AppSettings.MAP_ID,
    });
    this.infoWindow = new InfoWindow();
    this.setupControls();
    this.addMarkers();
  }

  setupControls() {
    const toggleControl = document.getElementById("style-selector-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      toggleControl
    );

    const geolocationControl = document.getElementById("my-location-control");
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(
      geolocationControl
    );
  }

  async addMarkers() {
    this.dashboardService.mapAvailableDriverList(this.entityData).subscribe(async (res: any) => {  
      const iconBase = "/assets/images/mi-theme/light/map/";
      const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;
      const icons: Record<string, { icon: string }> = {
        primaryColor: {
          icon: iconBase + "blue-pin.svg",
        },
      };

      res.forEach((driver) => {
        const location = driver.driverCurrentLocation.split(",");
        const lat = parseFloat(location[0]);
        const lng = parseFloat(location[1]);
        const iconImage = document.createElement("img");
        iconImage.src = icons.primaryColor.icon;
  
        const marker = new AdvancedMarkerElement({
          map: this.map,
          position: new google.maps.LatLng(lat, lng),
          content: iconImage,
          title: driver.driverName,
        });

        const infoWindow = new google.maps.InfoWindow({
          content: `<div><strong>${driver.driverName}</strong><br>Vehicle: ${driver.vehicleCode}<br>Status: ${driver.driverCurrentStatus}</div>`,
          maxWidth: 200,
        });

        marker.addListener("click", () => {
          infoWindow.open(this.map, marker);
        });
      });
    });
  }

  searchVehicleTypes() {
    this.entityService.searchAttributeSettings(AppSettings.ENTITY_TYPE.VEHICLE, this.entityDataForVehicleType).subscribe((res: any) => {
      this.vehicleTypeList = res.data.map(item => item);
    })
  }

  getFilterViewForLocation() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
       this.locationFilters = filterResponse;
       const fields = this.locationFilters?.tabs[0]?.groups[0]?.fields;
       const categoryField = fields ? fields.find(ele => ele.attributeCode === AppSettings.LOCATION_FILTER_ATTRIBUTES.LOCATION_CATEGORY) : [];
       this.categoryAttributeIdInLocationFilter = categoryField ? categoryField.attributeId : null;
       if(this.categoryAttributeIdInLocationFilter) {
         this.searchQueueLocation();
       }
      }
    })
  }

  searchQueueLocation(){
    this.entityDataForQueueLocation.countryCode = this.country[0].countryCode;
    this.entityDataForQueueLocation.filters = [{
        attributeId: this.categoryAttributeIdInLocationFilter,
        attributeValue: [AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.QUEUE]
      }];

    this.entityService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityDataForQueueLocation).subscribe((result: any) => {
      this.queueLocations = result.data;
      this.filterQueueLocation();
    })
  }

  filterQueueLocation() {
    this.queueLocationList = [];
    this.queueLocations.forEach(element => {
      this.queueLocationList.push({
        labelKey: element.values.location_display_name_for_booker,
        labelValue: element.values.location_display_name_for_booker
      });
    });
  }
  

  onChangeVehicleType(event) {
    this.entityDataForVehicleType.searchStr = event.value;
    this.searchVehicleTypes();
  }

  onChangeQueueLocation(event) {
    this.entityDataForQueueLocation.searchStr = event.value;
    this.searchQueueLocation();
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  onFilterIconClick() {
    if (this.filterIcon.includes('times')) {
      this.multiSelect.filterValue = null;
      this.filterIcon = 'pi pi-search';
    }
  }

  clearSearchField(multiSelect: any) {
    multiSelect.filterValue = null;
  }

  onSearch(event) {
    console.log(event);
    // const value = event.target.value;
    // this.cs.setSearchData(value);
  }

  clearSearch() {
    this.searchValue = "";
    this.cs.setSearchData('');
  }

  clearFilter() {
    this.selectedVehicleType = [];
    this.selectedQueueLocation = [];
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          this.infoWindow.setPosition(pos);
          this.infoWindow.setContent("Location found.");
          this.infoWindow.open(this.map);
          this.map.setCenter(pos);
        },
        () => {
          this.handleLocationError(true);
        }
      );
    } else {
      this.handleLocationError(false);
    }
  }

  handleLocationError(browserHasGeolocation: boolean) {
    const pos = this.map.getCenter();
    this.infoWindow.setPosition(pos);
    this.infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    this.infoWindow.open(this.map);
  }

  toggleDarkAndLightMap(event) {
    this.checked = event.checked;
    const mapStyles = this.checked
      ? AppSettings.LOCATION_MAP_STYLE_DARK
      : AppSettings.LOCATION_MAP_STYLE_DEFAULT;
    if (!this.checked) {
      mapStyles.push({ featureType: "poi", stylers: [{ visibility: "off" }] });
    }
    this.map.setOptions({ styles: mapStyles });
  }

  showRightDriversDialog(position: string) {
    this.rightDialogPosition = position;
    this.position = position;
    this.rightDriversSectionVisible = false;
    this.cd.detectChanges();
    this.rightDriversSectionVisible = true;
  }
}
