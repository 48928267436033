@if(field.attributeCode === 'special_login'){
    <div class="mb-3">
        <p-checkbox
            [(ngModel)]="selectedCategories"
            name="group"
            [binary]="true"
            (onChange)="onCheckboxChange()">
        </p-checkbox>
    </div>
}@else if(field?.presetValues && (field.attributeCode === 'ride_preference' || field.attributeCode === 'select_data_to_include')){
    @for (item of field.presetValues; track $index) {
        <div class="mb-3">
            <p-checkbox
            [(ngModel)]="selectedCategories"
            [label]="item.labelKey"
            name="group"
            [value]="item.labelValue"
            (onChange)="onCheckboxChange()" />
    </div>
    }
}@else {
<label></label>
    <form [formGroup]="formName" class="flex">
        <!-- <div class="field col-12 md:col-6"> -->
        @if(field?.presetValues){
        @for (item of field.presetValues; track $index) {
        <div class="mb-3 mr-2 journey-preference">
            <p-checkbox class="flex flex-row"
                [value]="item.labelValue"
                [label]="item.labelKey"
                [name]="field.attributeCode"
                [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                [inputId]="field.attributeCode+ $index" />
        </div>}
        }@else {
        <p-checkbox
            [value]="field.labelValue"
            [label]="field.labelKey"
            [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
            binary="true"
            (onChange)="onCheckBoxValueChange($event)"
            inputId="field.attributeCode" />
        }
        <!-- </div> -->
    </form>
}


