import { Injectable, signal } from '@angular/core';
import { RestApiService } from './rest-api.service';
import { ConfigService } from './config.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PermissionAccessService {
    private menuConfigSignal = signal<any>(null);

    constructor(private restApiService: RestApiService,
                private confService: ConfigService) { }

    getPermissionList() {
        return this.restApiService.get('GET PERMISSIONS', `${this.confService.getLoggedInTenantCode()}/api/v1/menus/menu-list?forTenantCode=${this.confService.getForTenantCode()}`, 'page-center');
    }

    setMenuConfig(menuConfig: any) {
        this.menuConfigSignal.set(menuConfig);
    }

    get menuConfig() {
        return this.menuConfigSignal;
    }

    hasAccess(menuCode, subMenuCode = null, pageCode = null, subPageCode = null) {
        const menuConfig = this.menuConfig();
        if (!menuConfig || !menuConfig[menuCode]) {
            return false;
        }
        if (subMenuCode) {
            const subMenu = menuConfig[menuCode][subMenuCode];
            if (!subMenu) {
                return false;
            }
        }
        if (pageCode) {
            const pages = subMenuCode ? menuConfig[menuCode][subMenuCode] : menuConfig[menuCode];
            const page = pages[pageCode];
            if (!page) {
                return false;
            }
        }
        if (subPageCode) {
            const subPages = pageCode ? (subMenuCode ? menuConfig[menuCode][subMenuCode][pageCode].subPageCodes : menuConfig[menuCode][pageCode].subPageCodes) : [];
            return subPages.includes(subPageCode);
        }

        return true; 
    }

    convertMenuConfig(dataArray: any[]) {
        const getSubPageCodes = (subPages) => subPages?.map(subPage => subPage.pageCode) || [];
    
        return dataArray.reduce((menuConfig, { menuCode, pages, subMenus }) => {
            const subMenusConfig = pages.reduce((acc, { pageCode, subPages }) => {
                acc[pageCode] = { subPageCodes: getSubPageCodes(subPages) };
                return acc;
            }, {});
    
            if (subMenus) {
                subMenus.forEach(({ menuCode: subMenuCode, pages }) => {
                    subMenusConfig[subMenuCode] = pages.reduce((subAcc, { pageCode, subPages }) => {
                        subAcc[pageCode] = { subPageCodes: getSubPageCodes(subPages) };
                        return subAcc;
                    }, {});
                });
            }
    
            menuConfig[menuCode] = subMenusConfig;
            return menuConfig;
        }, {});
    }
}
