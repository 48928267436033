import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { InputNumberInputEvent, InputNumberModule } from 'primeng/inputnumber';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { Values } from '../../../vehicles/models/listResponse.models';
import { AppIcons } from '../../app.icons';
import { Column } from '../mi-table/models/table';

import { SelectButtonModule } from 'primeng/selectbutton';
import { AppSettings } from '../../app.settings';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-per-page-columns',
  templateUrl: './per-page-columns.component.html',
  styleUrls: ['./per-page-columns.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonModule, OverlayPanelModule, DividerModule, InputNumberModule, FormsModule, TranslateModule, SelectButtonModule]
})
export class PerPageColumnsComponent implements OnInit {
  @ViewChild('op') overlay: OverlayPanel;
  miIcons = AppIcons;
  isModal: boolean = false;
  visible: boolean = false;
  selectedColumns!: Column[];
  columnValue: any = 10;
  @Input() collectionOfColumns;
  @Input() columns!: Values;
  @Input() itemsPerPage: number;
  @Output() onToggleManageColumn: EventEmitter<any> = new EventEmitter();
  @Output() itemPerPage: EventEmitter<any> = new EventEmitter();
  btnOptions: any[] = [{ icon: this.miIcons.COLUMNS, justify: 'Center' }];
  selectBtnValue: any;

  private prevColumnValue = AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT;

  constructor() {

  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.itemsPerPage && changes.itemsPerPage.currentValue !== undefined) {
      this.columnValue = changes.itemsPerPage.currentValue;
    }
  }

  onManageColumnsClick(event: any) {
    event.preventDefault();
    const toggleClass = 'toggle-menu';
    event.currentTarget.classList.toggle(toggleClass);
  }

  showManageColumn(event) {
    this.onToggleManageColumn.emit(event);
  }

  onItemPerPageChange(event: InputNumberInputEvent) {
    this.overlay.toggle('');
    if (this.columnValue) {
      this.prevColumnValue = this.columnValue;
    }

    if (event.value === null) {
      this.columnValue = null;
      event.originalEvent.preventDefault();
      event.originalEvent.stopPropagation();
    } else {
      this.columnValue = event?.value;
      if (typeof (event.value) === 'string') {
        const pageValue = parseInt(event.value, 10);

        if (pageValue < 5) {
          this.columnValue = 5;
        }
      } else {
        this.columnValue = event.value;
      }
      this.itemPerPage.emit(this.columnValue);
    }
  }

  onOverlayHide() {
    if (!this.columnValue) {
      this.columnValue = this.prevColumnValue
    }
  }

  onToggle(event) {
    this.isModal = false;
    this.visible = false;
  }

  onHide() {
    this.selectBtnValue = null;
  }

  increment() {
    if (this.columnValue < 500) {
      this.columnValue += 5;
    }
  }

  decrement() {
    if (this.columnValue > 0) {
      this.columnValue -= 5;
    }
  }

}
