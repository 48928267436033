import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { EntityService } from '../../../../shared/services/entity.service';
import { AppSettings } from '../../../../shared/app.settings';
import { Country } from '../../../../shared/models/country';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-booking-vehicle-info',
  standalone: true,
  imports: [TranslateModule, JsonPipe],
  templateUrl: './booking-vehicle-info.component.html',
  styleUrl: './booking-vehicle-info.component.scss'
})
export class BookingVehicleInfoComponent implements OnInit {
  @Input() vehicleId: string;
  @Input() entityId: string;
  @Input() vehicleRelatedData: any;
  country: Country;
  vehicleAttributeData: any;
  attributeValues: any;
  vehicleDetails: any;

  attributeLabels: any;
  constructor(private bookingService: EntityService,
    public cs: CommonBindingDataService,
    private router: Router
  ) {

  }


  ngOnInit(): void {
    this.getAttributeData();
    this.attributeValues = this.vehicleRelatedData?.[0];
  }


  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.bookingService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.VEHICLE_DETAILS_ON_BOOKING_VIEW).subscribe(res => {
      //this.vehicleAttributeData = _.find(res.relatedAttributes, { entityCode: 'vehicle' });
      this.vehicleDetails = res?.tabs[0].groups[0];
      // const labels = _.find(res.relatedAttributes, { entityCode: 'vehicle' }).attributeLabels;
      this.attributeLabels = this.cs.getAttributeLabels(res);
    });
  }



  redirectToEdit() {
    this.router.navigate(['/app/bookings/edit/' + this.entityId]);
  }


}
