@if(hideService.getVisibilityState(cardId) | async){
<div class="card shadow-card height-225px mb-16">
  <div class="flex justify-content-between align-items-center mb-3">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.vehicle_status
    </h5>
    <!-- <app-hide-button [cardId]="cardId"></app-hide-button> -->
  </div>
  <ul class="list-none p-0 m-0">
    @for(item of vehicleStatusData; track item){
    <li
      class="flex flex-column justify-content-between md:flex-row md:align-items-center md:justify-content-between list-item-padding"
    >
      <div>
        <span
          class="mi-lg mi-stops wh-18 label-icon"
          [ngClass]="item.color + ' ' + getBackgroundClass(item.color)"
        ></span>
        <span class="font-13-600">{{ item.labelKey }}</span>
      </div>
      <div class="mt-2 md:mt-0 flex align-items-center">
        <span class="font-16-600">{{ item.usage }}</span>
      </div>
    </li>
    }
  </ul>
</div>
}
