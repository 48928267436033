import { DatePipe, NgClass } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import { AppIcons } from "../../../../shared/app.icons";
import { AppSettings } from "../../../../shared/app.settings";
import { Router, RouterLink } from "@angular/router";
import { AssignDriverComponent } from "../../assign-driver/assign-driver.component";

@Component({
  selector: "app-booking-list-card",
  templateUrl: "./booking-list-card.component.html",
  styleUrls: ["./booking-list-card.component.scss"],
  standalone: true,
  imports: [TooltipModule, ButtonModule, DatePipe, NgClass, RouterLink, TooltipModule],
})
export class BookingListCardComponent implements OnInit {
  @ViewChild(AssignDriverComponent) assignDriverComponent: AssignDriverComponent;

  @Input() bookingListItem: any;
  @Input() forDetail: boolean = false;
  @Input() bookingStatuses;
  @Output() closeBtnClicked: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() bookingCardClicked: EventEmitter<any> = new EventEmitter<any>();
  miIcons = AppIcons;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  BOOKING_STATUS_IDS = AppSettings.BOOKING_STATUS_IDS;
  BOOKING_STATUS_CODES = AppSettings.BOOKING_STATUS_CODES;
  bookingStatusColor: any;

  constructor(private router: Router) { }

  ngOnInit(): void { 
    console.log(this.bookingListItem);

    if (this.bookingStatuses && this.bookingStatuses.length > 0) {
      this.bookingStatusColor = this.bookingStatuses.find(ele => ele.bookingStatusId === this.bookingListItem?.booking_status)?.bookingCode;
    }
    console.log(this.bookingStatusColor)
  }

  onCardClick(item) {
    this.bookingCardClicked.emit(item);
  }

  onCloseDetailView() {
    this.closeBtnClicked.emit(false);
  }

  onEditClick() {
    this.router.navigate(['app/bookings/edit/' + this.bookingListItem.id]);
  }
}
