import { CommonModule, DatePipe, JsonPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { EntityService } from 'app/modules/shared/services/entity.service';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { BookingService } from '../../../services/booking.service';
import { AddedPassengerInputComponent } from '../../add-booking/added-passenger-input/added-passenger-input.component';
import { BookingDriverInfoComponent } from '../booking-driver-info/booking-driver-info.component';
import { BookingVehicleInfoComponent } from '../booking-vehicle-info/booking-vehicle-info.component';
import { DropdownModule } from 'primeng/dropdown';
import { MiMobileValueComponent } from 'app/modules/shared/ui-sharable/mi-mobile-value/mi-mobile-value.component';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { PassengerTabComponent } from 'app/modules/dispatch/components/booking-section/booking-details/passenger-tab/passenger-tab.component';

@Component({
  selector: 'app-booking-info',
  standalone: true,
  imports: [TranslateModule, JsonPipe, ButtonModule, FormsModule, BookingVehicleInfoComponent, BookingDriverInfoComponent, JsonPipe, DatePipe, AddedPassengerInputComponent, InputTextareaModule, CommonModule,DropdownModule,MiMobileValueComponent, MiImageContainerComponent,InputNumberModule,
    PassengerTabComponent
  ],
  templateUrl: './booking-info.component.html',
  styleUrl: './booking-info.component.scss'
})
export class BookingInfoComponent {
  adminNote: string;
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() vehicleAttributeData: any;
  @Input() bookingAttributeData: any;
  @Input() driverAttributeData: any;
  @Input() bookingDetails: any;
  @Input() passengerRelatedData=[];
  @Input() vehicleRelatedData: any;
  @Input() driverRelatedData: any;
  @Input() bookingId: string;
  appAccess: any;
  classification: any;
  transportationRestrictions: any;
  bookingTimeRestriction: any;
  assignLocation: any;
  homeStayLocationRestriction: any;
  specialRequest: any;
  otherInformation: any;
  tab: any;
  bookingInfo;
  flightDetails;
  driverInstructions;
  passengers;
  vehicleId: string;
  driverId: string;
  BOOKING_STATUS_LABEL = AppSettings.BOOKING.STATUS_BY_ID;
  miIcons = AppIcons;
  dd_MMM_yyyy_FORMAT: any = AppSettings.dd_MMM_yyyy_FORMAT;
  HOUR_MINUTE_FORMAT:any = AppSettings.HOUR_MINUTE_FORMAT;
  hh_mm_dd_MMM_yyyy_FORMAT:any = AppSettings.hh_mm_dd_MMM_yyyy_FORMAT;
  hh_mm: string = AppSettings.hh_m_FORMAT;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  childSeats = [
    'child_seat', 'infant_carrier', 'booster'
  ]
  childSeatsHr = [
    'child_seat', 'booster'
  ];
  groups: any;
  excludeFields = ["passenger_profile_image"]
  passengersPrivileges: any;
  passengersDetails: any;
  selectAddedPassenger: any;
  addedPassengerList: any;
  adminNoteAttributeId: any;
  bookingData: any;
  country: Country;
  language: Language;
  @Input() bookingStatus: any;
  completedBooking: any;
  bookingPass: any;
  countryCodeWithName = AppSettings.COUNTRY_CODE_WITH_COUNTRY_NAME;
  countryCodeForMobileNumber: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  newbookingStatus: any;
  additionalPassengerVehicleValue;
  luggageVehicleRequired;
  initialAdditionalPassengerVehicle;
  initialLuggageVehicleCount;
  petInfo;
  petInfoFormatted;
  bookingByName;
  passFullName;
  passengerBookingId;
  passengerIdData;
  luggageBookingId;
  luggageIdData;
  combinedData;

  constructor(
    public cs: CommonBindingDataService,
    private router: Router,
    private bookingService: BookingService,
    private configService: ConfigService,
    private entityService: EntityService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.newbookingStatus = this.bookingDetails.attributeCodeValueDtoList.find(item => item.attributeCode === 'booking_status');
    this.petInfo = this.bookingDetails.attributeCodeValueDtoList.find(item => item.attributeCode === 'pet_accommodation')
    this.bookingByName = this.bookingDetails.attributeCodeValueDtoList.find(item => item.attributeCode === 'created_by');
    if (this.bookingByName && this.bookingByName.attributeValue) {
      const relatedItem = this.bookingDetails.relatedData.find(item => item.createdBy === this.bookingByName.attributeValue);
  
      if (relatedItem) {
        const passFullName = relatedItem.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'pass_full_name');
        
        if (passFullName) {
          this.passFullName = passFullName.attributeValue;
        } 
        
      }
    }
    if (this.petInfo) {
      const targetEntityIds = this.petInfo.attributeValue;
      this.petInfoFormatted = [];
      targetEntityIds.forEach(targetEntityId => {
        const matchingPetData = this.bookingDetails.relatedData.find(data => data.entityId === targetEntityId);
        if (matchingPetData) {
          
          const species = matchingPetData.attributeCodeValueDtoList.find(attribute => attribute.attributeCode === 'species')?.attributeValue;
          const weight = matchingPetData.attributeCodeValueDtoList.find(attribute => attribute.attributeCode === 'pet_weight')?.attributeValue;
          const height = matchingPetData.attributeCodeValueDtoList.find(attribute => attribute.attributeCode === 'pet_height')?.attributeValue;
  
          const formattedPetInfo = `${species} - Weight: ${weight ? weight : '--'} kg | Height: ${height ? height : '--'} In`;

          this.petInfoFormatted.push(formattedPetInfo);
        }
      });
    } else{
      this.petInfoFormatted = [];
    }
    this.bookingPassengerAndLuggageData();
    
    this.setDefaultPassenger();
    this.attributeLabels = this.cs.getAttributeLabels(this.bookingAttributeData);
    this.tab = this.bookingAttributeData.tabs.find(ele => ele.tabCode === 'details');
    if (this.tab) {
      const bookingInfoGroup = this.tab.groups.find(ele => ele.code === 'booking_details');
      if (bookingInfoGroup) {
        bookingInfoGroup.fields = _.filter(bookingInfoGroup.fields, field => 
          field.attributeCode !== 'passenger_booking_ids'
        );
        this.bookingInfo = bookingInfoGroup;
      }
    }
    this.flightDetails = this.tab.groups.find(ele => ele.code === 'flight_details');
    this.driverInstructions = this.tab.groups.find(ele => ele.code === 'instructions');
    this.passengers = this.tab.groups.find(ele => ele.code === 'passengers');
    this.bookingPass = this.bookingAttributeData.relatedAttributes.find(ele => ele.entityCode === "booking_pass")
    this.passengersDetails = this.bookingPass.tabs[0].groups.find(ele => ele.code === "details")
    this.passengersPrivileges = this.bookingPass.tabs[0].groups.find(ele => ele.code === "privileges");
    this.bookingPass = this.cs.getAttributeLabels(this.bookingPass);
    this.vehicleId = this.attributeValues.vehicle_entity_id;
    this.setBookingStatus();
    this.adminNote = this.attributeValues.admin_note
    this.adminNoteAttributeId = this.findAdminNoteAttributeId(this.tab);
    this.completedBooking = _.find(this.bookingStatus, { bookingCode: 'COMPLETED' });
    this.additionalPassengerVehicleValue = this.getAttributeValue("additional_passenger_vehicle");
    this.luggageVehicleRequired = this.getAttributeValue("luggage_vehicle_required");
    if (this.bookingInfo) {
      this.additionalPassengerVehicleValue =this.getAttributeValue("additional_passenger_vehicle");
      this.initialAdditionalPassengerVehicle = this.getAttributeValue("additional_passenger_vehicle");
      this.luggageVehicleRequired = this.getAttributeValue("luggage_vehicle_required");
      this.initialLuggageVehicleCount = this.getAttributeValue("luggage_vehicle_required");
    }
    this.setCountryAndLanguage();
    
    const details={};
    const passengerDetails = {...details, passengers: this.passengerRelatedData};
      this.entityService.setEntityView(AppSettings.ENTITY_CODE.BOOKING,AppSettings.KEYS_TO_STORE_DATA_IN_SERVICE.BOOKING_DETAILS, passengerDetails);

  }

  bookingPassengerAndLuggageData() {
    this.combinedData = [];
    this.passengerIdData = [];
    this.luggageIdData = [];

    // Helper function to process bookings
    const processBookings = (attributeCode: string, targetArray: string[]) => {
      const bookingIds = this.bookingDetails.attributeCodeValueDtoList
        .find(item => item.attributeCode === attributeCode)?.attributeValue;

      if (bookingIds) {
        const matchingData = this.bookingDetails.relatedData
          .filter(related => bookingIds.includes(related.entityId))
          .map(data => this.getBookingCode(data));

        if (matchingData.length) {
          targetArray.push(...matchingData);
          this.combinedData.push(...matchingData);
        }
      }
    };

    // Process both passenger and luggage bookings
    processBookings('passenger_booking_ids', this.passengerIdData);
    processBookings('luggage_booking_ids', this.luggageIdData);
  }

  getBookingCode(related: any): string {
    const bookingCodeObj = related.attributeCodeValueDtoList.find(
      (attribute) => attribute.attributeCode === 'booking_code'
    );
    return bookingCodeObj ? bookingCodeObj.attributeValue : 'N/A';
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  onCancel() {
    this.additionalPassengerVehicleValue = this.initialAdditionalPassengerVehicle;
    this.luggageVehicleRequired = this.initialLuggageVehicleCount;
  }

  getFlagsForMobileNumber() {
    const findFlag = (flagCode: string) =>
      this.countryCodeWithName.find(ele => ele.countryCode === flagCode)?.code;
    const mobileNumber = this.findAttributeValue(AppSettings.MOBILE_NUMBER_ATTRIBUTE_CODE);
    const flag1 = findFlag(mobileNumber?.split('-')[0])
    this.countryCodeForMobileNumber = flag1 ? flag1 : null;
  }

  findAttributeValue(attributeCode: string) {
    return this.passengersDetails?.find(ele => ele.attributeCode === attributeCode)?.attributeValue;
  }


  redirectToEdit() {
    this.router.navigate(['/app/bookings/edit/' + this.bookingDetails.entityId]);
  }

  setBookingStatus() {
  }

  getAttributeValue(attributeCode: string) {
    const foundItem = this.bookingDetails.attributeCodeValueDtoList.find(
      (item) => item.attributeCode === attributeCode
    );
    return foundItem?.attributeValue || null;
  }

  findStatusObj(selectedStatus) {
    return this.bookingStatus.find(ele => ele.bookingStatusId === selectedStatus);
  }

  getIcon(selectedStatus) {
    const booking = this.findStatusObj(selectedStatus);
    return booking?.bookingIcon.toUpperCase();
  }

  private setDefaultPassenger(): void {
    if (this.passengerRelatedData.length > 0) {
      this.selectAddedPassenger = this.passengerRelatedData[0];

    }
  }


  onSelectAddedPassenger(event) {
    console.log(event)
    this.selectAddedPassenger = event;

  }

  onRemove(event) {



  }

  getPassengerImageUrl(rowData) {
    if (rowData?.passenger_profile_image) {
      return `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/files/profile/download/${rowData?.passenger_profile_image[0]}?forTenantCode=${this.configService.getForTenantCode()}`;
    } else {
      return '../../../../../assets/images/mi-theme/light/basic/default_user.svg';
    }
  }


  findAdminNoteAttributeId(detailsTab) {
    if (detailsTab) {
      const adminNoteGroup = _.find(detailsTab.groups, { code: 'admin_note' });

      if (adminNoteGroup) {
        const adminNoteField = _.find(adminNoteGroup.fields, { attributeCode: 'admin_note' });

        if (adminNoteField) {
          return adminNoteField.attributeId;
        }
      }
    }
    return null;
  }


  onSaveAdminNote() {
    this.initializeBookingData();
    this.bookingData.data.push({
      attributeId: this.adminNoteAttributeId,
      attributeValue: this.adminNote
    })
    this.entityService.saveAttributeData(AppSettings.ENTITY_CODE.BOOKING, this.bookingId, this.bookingData).subscribe(res => {
      this.showSuccessMessage('bookings.booking_note_added');
      this.adminNote = '';
    });


  }


  private initializeBookingData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.bookingData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.BOOKING,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: []
    };
  }

  private showErrorMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'error',
      summary: 'Error',
      detail: this.cs.getLabel(labelKey)
    });
  }


  private showSuccessMessage(labelKey) {
    this.messageService.add({
      key: 'tst',
      severity: 'success',
      summary: 'Successful',
      detail: this.cs.getLabel(labelKey),
    });
  }

  onSaveModifications() {
    if (!this.bookingDetails.entityId ) {
      return;
    }
  
    const payload = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      bookingEntityId: this.bookingDetails.entityId,
      passengerVehicleCount: this.additionalPassengerVehicleValue,
      luggageVehicleCount: this.luggageVehicleRequired,
    };
  
    this.entityService.reviewSpecialRequest(payload)
      .subscribe({
        next: (response) => {
          // Show success message
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Special request updated successfully'
          });
   
        },
        error: (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Failed to update special request'
          });
        }
      });
  }

}