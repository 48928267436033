import { JsonPipe, NgClass, CommonModule } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { CheckboxModule } from 'primeng/checkbox';
import { FilterService } from '../../../advance-filter-form/filter.service';

@Component({
  selector: 'app-boolean-value-filter',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, CheckboxModule, CommonModule],
  templateUrl: './boolean-value-filter.component.html',
  styleUrl: './boolean-value-filter.component.scss'
})
export class BooleanValueFilterComponent {
  @Input() attributeValues;
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  inputPlaceholder: string;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.inputPlaceholder = this.cs.getLabel('placeholder_search_by') + ' ' + this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.label', this.attributeLabels, this.field.attributeCode);
  }

  onCheckboxValueChange(event) {
    if (event.checked) {
      this.formName.controls[this.field.attributeCode].setValue([event.checked]);
    } else {
      this.formName.controls[this.field.attributeCode].setValue(null);
    }
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
  }
}
