import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../shared/app.settings';
import { StatusComponent } from '../../../shared/components/status/status.component';
import { Country } from '../../../shared/models/country';
import { Status } from '../../../shared/models/status';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { SpecificationComponent } from '../../../vehicles/components/vehicle-details/specification/specification.component';
import { AttributeData } from '../../../vehicles/models/attribute.models';
import { EntityService } from '../../../shared/services/entity.service';
import { OverviewDetailsComponent } from './overview-details/overview-details.component';
import { PrivilegeSettingsComponent } from './privilege-settings/privilege-settings.component';
import { ConfigService } from '../../../shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'passenger-details',
  standalone: true,
  imports: [
    BreadcrumbModule,
    StatusComponent,
    TabViewModule,
    TranslateModule,
    MenuModule,
    ToastModule,
    OverviewDetailsComponent,
    ButtonModule,
    SpecificationComponent,
    PrivilegeSettingsComponent,
    AccessProviderDirective
  ],
  templateUrl: './passenger-details.component.html',
  styleUrl: './passenger-details.component.scss'
})
export class PassengerDetailsComponent {
  routePath: MenuItem[] = [];
  passengerId: string;
  dataForStatus: Array<Status> = [];
  userId: any = '';
  country: Country;
  activeIndex: any = 0;
  data: AttributeData;
  vehicleAttributeData: any;
  attributeLabels = {};
  vehicleAttributeLength;
  attributeValues = [];
  imgFileId: any;
  showStatus: boolean = false;
  showData: boolean = false;
  passengerDetails: any;
  entityForStatus: any;
  deleteBtnLabel: any;
  isPassengerDeleted: any;

  constructor(private route: ActivatedRoute,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private messageService: MessageService,
    private configService: ConfigService,
    private confirmationService: ConfirmationService,
    private passengerService: EntityService) {

  }

  ngOnInit() {
    this.passengerId = this.route.snapshot.paramMap.get('id');
    this.isPassengerDeleted = this.route.snapshot.paramMap.get('isDeleted');
    this.getAttributeData();
    this.deleteBtnLabel = this.cs.getLabel('lbl_delete');
    this.routePath = [
      {
        label: this.cs.getLabel('passengers.lbl_passengers'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Overview",
        routerLink: '../' + this.passengerId,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  deletePassenger() {
    this.confirmationService.confirm({
      header: this.cs.getLabel('passengers.message.confirm_delete_header'),
      message: this.cs.getLabel('passengers.message.confirm_delete_msg'),
      acceptIcon: null,
      rejectIcon: null,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      acceptLabel: this.cs.getLabel('lbl_delete'),
      rejectLabel: this.cs.getLabel('cancel'),
      accept: ($event) => {
        const requestBody = {
          forTenantCode: this.configService.getForTenantCode(),
          entityCode: AppSettings.ENTITY_CODE.PASSENGER,
          entityIds: [this.passengerId],
          countryCode: this.country[0].countryCode
        };
        this.passengerService.deleteEntityActionStatus(requestBody).subscribe(response => {
          this.router.navigate(['/app/passengers']);
          this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_passenger_deleted_successfully') });
        })
      },
      reject: () => {
      }
    });
  }

  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.passengerService.getAttributeDefinition(AppSettings.ENTITY_CODE.PASSENGER, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {

      res.tabs.forEach(element => {
        element.groups.forEach(group => {
          if (group.code === 'details') {
            group.entityRelationshipConfigId = null;
          }
        });
      });
      this.data = res;
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.vehicleAttributeData = this.cs.getOrganizedAttribute(this.data);
      this.vehicleAttributeLength = this.vehicleAttributeData.tabs.length;
      this.getPassengerDetails();
    })
  }

  getPassengerDetails() {
    this.passengerService.getEntity(this.passengerId, AppSettings.ENTITY_CODE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      this.passengerDetails = result;
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.imgFileId = this.attributeValues[AppSettings.PASSENGER_PROFILE_IMAGE_ATTRIBUTE_CODE];
      this.showStatus = true;
      this.showData = true;
      this.setStatusData();
    })
  }

  setStatusData() {
    const findAttributeValue = (attributeCode: string) =>
      this.passengerDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;

    const getAttributeValueOrEmpty = (attributeCode: string) =>
      findAttributeValue(attributeCode) || this.cs.getLabel('lbl_empty');

    this.entityForStatus = {
      entityName: `${findAttributeValue('first_name')} ${findAttributeValue('last_name')}`
    };

    this.dataForStatus = [
      { label: 'Email', text: getAttributeValueOrEmpty('email') },
      { label: 'Contact Number', text: getAttributeValueOrEmpty('mobile_number') },
      { label: 'Unique ID', text: findAttributeValue('unique_id') }
    ];
  }
}
