import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { LayoutService } from '../../../../app.layout.service';
import { AppIcons } from '../../app.icons';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { AppSettings } from './../../app.settings';
import { SidebarService } from './../../services/sidebar.service';
import { Country } from '../../models/country';
import { MenuAccess } from './mdoal/sidebarModal';
import * as _ from 'lodash';
import { PermissionAccessService } from '../../services/permission-access.service';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { EntityService } from '../../services/entity.service';
import { ConfigService } from '../../services/config.service';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { EventService } from 'app/modules/events/services/event.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [PanelMenuModule,DropdownModule,FormsModule,TranslateModule, ReactiveFormsModule] // Removed unnecessary imports
})
export class SidebarComponent implements OnInit {
  @ViewChild(Dropdown) dropdown: Dropdown;  // Get a reference to the dropdown
  subscription: Subscription;
  @Input() items: MenuItem[] = [];
  @Input() eventResponse;
  selectedEventImage: any;
  @Output() isReadySidebar: EventEmitter<boolean> = new EventEmitter();
  ORGANIZATION = {
    label: this.commonService.getLabel('sidebar_lbl_organization'),
    icon: AppIcons.ORGANIZATION,
    routerLink: ['/app/organization']
  };

  listColumns: any[] = [];
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'asc',
    status: 'Both',
    startDate: 1704067200000,
    endDate: 0
  };
  selectedEvent: any;
  defaultIconUrl: string = '/assets/images/mi-theme/light/booking/outstation.svg';
  eventDropdownForm: FormGroup;

  CONTENT_MANAGEMENT = {
    label: this.commonService.getLabel('sidebar_lbl_content_management'),
    icon: AppIcons.USER_MANAGEMENT,
    routerLink: ['/app/content/about-company'],
    items: [
      {
        label: this.commonService.getLabel('sidebar_lbl_about_company'),
        routerLink: ['/app/content/about-company'],
        icon: AppIcons.ABOUT_US
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_faqs'),
        routerLink: ['/app/content/faqs'],
        icon: AppIcons.FAQ
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_terms_and_conditions'),
        routerLink: ['/app/content/terms-and-conditions'],
        icon: AppIcons.TERMS_CONDITIONS
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_privacy_policy'),
        routerLink: ['/app/content/privacy-policy'],
        icon: AppIcons.PRIVACY_POLICY
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_contact_support'),
        routerLink: ['/app/content/contact-support'],
        icon: AppIcons.CONTACT_SUPPORT
      },
    ]
  }

  menuLinkMap = {
    DASHBOARD: { icon: AppIcons.HOME, routerLink: ['/app/dashboard']},
    BOOKING: { icon: AppIcons.BOOKING },
    EVENTS: { icon: AppIcons.EVENT_MENU, routerLink: ['/app/events'] },
    CURRENT_BOOKING: { icon:AppIcons.CURRENT_BOOKING,routerLink: ['/app/bookings/current-bookings'] },
    BOOKING_HISTORY: { icon:AppIcons.BOOKING_HISTORY,routerLink: ['/app/bookings/booking-history'] },
    DISPATCH: { icon: AppIcons.DISPATCH, routerLink: ['/app/dispatch'] },
    PASSENGERS: { icon: AppIcons.PASSENGER_USER, routerLink: ['/app/passengers'] },
    VEHICLES: { icon: AppIcons.VEHICLE, routerLink: ['/app/vehicles'] },
    DRIVERS: { icon: AppIcons.DRIVER },
    ALL_DRIVERS: { icon:AppIcons.ALL_DRIVERS, routerLink: ['/app/drivers/list'] },
    LEAVE_MANAGEMENT: { icon:AppIcons.LEAVE_MANAGEMENT, routerLink: ['/app/driver-leave-management'] },
    ALL_QUEUES_LIST: { icon: AppIcons.QUEUE_MANAGEMENT, routerLink: ['/app/queue/list'] },
    QUEUE_MANAGEMENT: { icon: AppIcons.QUEUE_MANAGEMENT, routerLink: ['/app/queue/list'] },
    MANAGE_LOCATIONS: { icon: AppIcons.MANAGE_LOCATIONS, routerLink: ['/app/manage-locations/list'] },
    MANAGE_ZONES: { icon: AppIcons.MANAGE_ZONES, routerLink: ['/app/manage-zones'] },
    SHIFT_MANAGEMENT: { icon: AppIcons.SHIFT_MANAGEMENT },
    SHIFT_CALENDAR: { icon:AppIcons.SHIFT_CALENDAR, routerLink: ['/app/shift-management'] },
    MANAGE_SHIFT: { icon: AppIcons.MANAGE_SHIFT, routerLink: ['/app/manage-shift'] },
    MANAGE_GROUPS: { icon: AppIcons.MANAGE_GROUPS, routerLink: ['/app/manage-groups'] },
    SHIFT_TEMPLATES: { icon:AppIcons. SHIFT_TEMPLATE, routerLink: ['/app/shift-template'] },
    COMMUNICATION: { icon: AppIcons.SMS, routerLink: ['/app/communication'] },
    USER_MANAGEMENT: { icon: AppIcons.USER_MANAGEMENT },
    MANAGE_USERS: { icon: AppIcons. MANAGE_ROLES, routerLink: ['/app/user-management/manage-users'] },
    MANAGE_ROLES: { icon: AppIcons.MANAGE_USERS, routerLink: ['/app/user-management/manage-roles'] },
    CONCERNS: { icon: AppIcons.CONCERNS, routerLink: ['/app/concerns'] },
  };

  country: Country;
  menuConfig: any = null; 
  constructor(
    private commonService: CommonBindingDataService,
    private sidebarService: SidebarService,
    public layoutService: LayoutService,
    private permissionAccessService: PermissionAccessService,
    private entityService: EntityService,
    private configService: ConfigService,
    private eventService: EventService,
    private messageService: MessageService,
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.menuConfig = this.permissionAccessService.menuConfig;
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.getPermissionList();
    if(this.eventResponse) {
      this.loadEvents(this.eventResponse);
    }
    this.eventService.eventAdded$.subscribe(res => {
      if (res) {
        this.loadEvents();
      }
    })
  }

  initializeForm() {
    this.eventDropdownForm = this.formBuilder.group({
      eventDropdown: [],
    });

  }

  getPermissionList() {
    this.permissionAccessService.getPermissionList().subscribe((res: any[]) => {
      if(res) {
        const menuConfig = this.permissionAccessService.convertMenuConfig(res);
        this.permissionAccessService.setMenuConfig(menuConfig);
        if(res) {
          this.setupSidebar(this.items);
        }
      }
    });
  }

  private setupSidebar(menus): void {
    // this.sidebarService.getSidebarMenus().subscribe((menus: MenuAccess[]) => {
      const sortedData = this.getSortedMenus(menus);
      this.items = this.buildMenuItems(sortedData);
      this.items.splice(1, 0, this.ORGANIZATION);
      this.items.push(this.CONTENT_MANAGEMENT);
      this.isReadySidebar.emit(true);
    // });
  }

  private getSortedMenus(menus: MenuAccess[]): MenuAccess[] {
    return _.sortBy(menus, [
      menu => parseInt(menu.orderIndex),
      menu => _.map(menu.pages, page => parseInt(page.orderIndex))
    ]).map(menu => ({
      ...menu,
      pages: _.sortBy(menu.pages, page => parseInt(page.orderIndex))
    }));
  }

  private buildMenuItems(menus: MenuAccess[]): MenuItem[] {
    return menus
      .filter(menu => this.menuLinkMap[menu.menuCode])
      .map(menu => ({
        ...menu,
        icon: this.menuLinkMap[menu.menuCode]?.icon,
        routerLink: this.menuLinkMap[menu.menuCode]?.routerLink,
        items: menu.subMenus ? this.buildMenuItems(menu.subMenus) : undefined,
      }));
  }

  loadEvents(eventResponse?) {
    // this.eventService.getEventList(this.entityData).subscribe({
    //   next: (res: any) => {
        this.listColumns = [];
        eventResponse?.data?.forEach((element, index) => {
          if (element?.eventLogoImgId) {
            this.getImage(element?.eventLogoImgId, element);
          } else {
            element.icon = this.defaultIconUrl
          }
          this.listColumns.push(element);
          if (index === eventResponse?.data?.length - 1) {
            let eventDataFromLocalStorage = localStorage.getItem('eventCodeData');
            const eventData = eventDataFromLocalStorage ? JSON.parse(localStorage.getItem('eventCodeData')) : null;
            const eventCode = eventData ? eventData : null;
            if (eventCode) {
              this.selectedEvent = eventData;
              this.eventDropdownForm.controls['eventDropdown'].setValue(eventData);
              this.selectedEventImage = this.listColumns.find(ele => ele.eventShortcode ===this.selectedEvent);
            } else {
              this.selectedEvent = this.listColumns.length > 0 ? this.listColumns[0].eventShortcode : null;
              let selectedEventId = this.listColumns.length > 0 ? this.listColumns[0].eventId : null;
              this.eventDropdownForm.controls['eventDropdown'].setValue(this.listColumns[0].eventShortcode);
              this.selectedEventImage = this.listColumns[0]
              this.eventService.setSelectedEventData(this.selectedEvent);
              this.eventService.setSelectedEventId(selectedEventId);
            }
          }
        // });
      // },
      // error: (error) => {
      //   this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.commonService.getLabel(error.errors.general[0].message) });
      // }
    })
  }

  onEventChangeChange(event) {
    this.eventService.setSelectedEventData(event.value);
    this.selectedEvent = event.value;
    this.selectedEventImage = this.listColumns.find(ele => ele.eventShortcode ===this.selectedEvent);
    let selectedEventId = this.listColumns.find(ele => ele.eventShortcode ===this.selectedEvent)?.eventId;
    this.eventService.setSelectedEventId(selectedEventId);
    this.router.navigate(['app/dashboard']);
  }

  onDropdownMouseLeave() {
    if (this.dropdown && this.dropdown.overlayVisible) {
      this.dropdown.overlayVisible = false; 
    }
  }

  getImage(fileId, event) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'event');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        event.icon = base64data;
      }
    })
  }
}
