import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { SidebarModule } from 'primeng/sidebar';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { BookingService } from 'app/modules/bookings/services/booking.service';

@Component({
  selector: 'app-single-item-select',
  templateUrl: './single-item-select.component.html',
  styleUrl: './single-item-select.component.scss',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, DialogModule, SidebarModule, InputTextModule, RadioButtonModule, ButtonModule],
})
export class SingleItemSelectComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: any;
  @Input() isVisibleAssignVehicle;
  @Input() moduleName: string;
  country: Country;
  language: Language;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
    private bookingService: BookingService) {
    this.formName = this.formGroupDirective.control;

  }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
  }

  openDialog() {
    this.bookingService.setVisibleAssignVehicle(true);
  }

}
