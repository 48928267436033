@if(hideService.getVisibilityState(cardId) | async){
<div class="card height-467px shadow-card">
  <div class="flex justify-content-between align-items-center mb-4">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.journey_status
    </h5>
    <!-- <app-hide-button [cardId]="cardId"></app-hide-button> -->
  </div>
  @for(item of journeyStatusData; track item){
  <div class="text-center mb-6">
    <div class="flex justify-content-center mb-3">
      <div>
        <div [ngClass]="item.color + ' font-bold text-5xl'">{{ item.count }}</div>
      </div>
    </div>
    <span class="font-medium">{{ item.label }}</span>
  </div>
  }
</div>
}
