<div class="flex flex-row justify-content-between card-container" (click)="onCardClick(bookingListItem)">
  <div class="flex flex-column">
    <div class="flex flex-row">

      <div class="color-bar mr-2 mt-1" [ngClass]="{ 'new-request': bookingStatusColor === BOOKING_STATUS_CODES.ADMIN_CONFIRMED,
      'driver-assigned': bookingStatusColor === BOOKING_STATUS_CODES.DRIVER_ASSIGNED,
      'completed': bookingStatusColor === BOOKING_STATUS_CODES.COMPLETED }">
    </div>


      <div class="flex flex-column">
        <div class="flex flex-row align-items-center mb-1">
          <div class="font-14 font-bold default-text-black-color mr-2">{{bookingListItem.pickup_time | date: 'hh:mm'}}, {{bookingListItem.pickup_date | date: 'dd MMM yyyy'}}</div>
          <div  [pTooltip]="bookingListItem.booking_code"  tooltipPosition="bottom" fitContent="true" [class]="forDetail ? 'ellipse-for-header' : 'ellipse'" class="font-12 font-semibold default-print-label-color mr-2">{{bookingListItem.booking_code}}</div>
        </div>
       
        <div class="flex flex-row align-items-center">
          @if (bookingListItem?.transportation_type !== TRANSPORTATION_MODE.VEHICLE_TRANSFER) {
            <div class="font-12 font-semibold default-text-black-color mr-2">
              {{(bookingListItem?.number_of_passenger ? bookingListItem?.number_of_passenger : null)}} Pass
            </div>
            <div class="font-12 font-semibold default-print-label-color type-of-pass mr-2">{{bookingListItem?.owner_pass_type}}</div>
          }
          @if (bookingListItem?.owner_watchlist) {
            <div><i class="mi-lg mi-watchlist inline-block vertical-align-text-bottom cursor-pointer wh-16"></i></div>
          }
        </div>
      </div>
    </div>
    <div class="flex flex-row align-items-center mt-2 mb-2">
        <div class="mr-2 flight-container">
          @if (bookingListItem?.transportation_type === TRANSPORTATION_TYPE.AIRPORT) {
            <i class="{{miIcons.BOOKING_AIRPORT_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-17"></i>
          } @else {
            @switch (bookingListItem?.transportation_type) {
              @case (TRANSPORTATION_MODE.CAR) {
              <i class="{{miIcons.BOOKING_CAR_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-17 "></i>
              }
    
              @case (TRANSPORTATION_MODE.SHUTTLE) {
              <i class="{{miIcons.BOOKING_SHUTTLE_OLD}} inline-block vertical-align-text-bottom cursor-pointer wh-17 "></i>
              }
    
              @case (TRANSPORTATION_MODE.BUS) {
              <i class="{{miIcons.BOOKING_BUS}} inline-block vertical-align-text-bottom cursor-pointer wh-17 "></i>
              }

              @case (TRANSPORTATION_MODE.VEHICLE_TRANSFER) {
              <i class="{{miIcons.VEHICLE_TRANSFER}} inline-block vertical-align-text-bottom cursor-pointer wh-20 "></i>
              }
            }
          }
        </div>
        @if (bookingListItem.child_seat > 0) {
          <div class="mr-2">
            <span class="font-14 font-bold text-dark-green">{{bookingListItem.child_seat}}</span>
            <span class="font-12 font-semibold text-dark-green">C</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.wheelchair > 0) {
          <div class="mr-2">
            <span class="font-14 font-bold text-dark-green">{{bookingListItem.wheelchair}}</span>
            <span class="font-12 font-semibold text-dark-green">W</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.luggage > 0) {
          <div class="mr-2">
            <span class="font-14 font-bold text-dark-green">{{bookingListItem.luggage}}</span>
            <span class="font-12 font-semibold text-dark-green ml-1">Bags</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.additional_passenger_vehicle) {
          <div>
            <i class="mi-lg mi-stepper_color mi-additional_vehicle inline-block vertical-align-text-bottom cursor-pointer wh-20"></i>
            <span class="font-18 font-semibold default-text-black-color ml-1">{{bookingListItem.additional_passenger_vehicle}}</span>
            <span class="default-separator-color ml-2 font-15">|</span>
          </div>
        }
        @if (bookingListItem.booking_note) {
          <div>
            <span #mydiv class="relative white-space-nowrap pl-1">
              <i class="mi-lg bg-orange mi-instructions mt-2 inline-block cursor-pointer wh-20" [appendTo]="mydiv"
                [pTooltip]="bookingListItem.booking_note" tooltipPosition="right" tooltipStyleClass="tooltip-container"
              ></i>
            </span>
          </div>
        }
      </div>
  </div>
  @if(forDetail){<div class="flex flex-column justify-content-between align-items-center font-semibold">
    <div class="ml-4" (click)="onCloseDetailView()"><i class="mi-lg mi-off_close inline-block cursor-pointer wh-24"></i></div>
    <button class="edit-btn bg-white mr-2 mb-1 cursor-pointer"><span class="font-12 font-medium mr-1 default-text-blue-color" (click)="onEditClick()">Edit</span><span><i class="mi-lg edit_2 inline-block vertical-align-text-bottom mi-stepper_color wh-15"></i></span></button>
  </div>}
  
</div>
