<div appAccessProvider [accessCode]="{ menuCode: 'ACCOUNT_SETTINGS', pageCode: 'USER_PROFILE', subPageCode: 'EDIT' }">
    <div class="main-settings">
        <p-tabView>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
                    <span translate>settings.lbl_user_profile</span>
                </ng-template>
                <div class="flex justify-content-end flex-wrap btn-with-links">
                    <button pButton class="mr-2 bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                    <p-button class="flex align-items-center justify-content-center" [label]="addBtnLabel" (click)="onAddUserProfile()"></p-button>
                </div>
                <div class="card background-not">
                    <div class="font-semibold pb-2">
                        <label class="navigation-item-label font-20" translate>settings.lbl_user_profile</label>
                    </div>
                    <form [formGroup]="profileForm">
                        <div class="grid">
                            <div class="form-field col-6 md:col-10 lg:col-6 field">
                                <label class="flex" translate>lbl_f_name
                                    <span class="required">*</span>
                                </label>
                                <div class="form-control">
                                    <input #nameInput formControlName="firstName" class="p-component p-element w-full" type="text" pInputText appAutoFocus appOnlyLetters appTrimSpaces/>
                                </div>
                                @if (profileForm.controls.firstName.errors && (profileForm.controls.firstName.dirty || profileForm.controls.firstName.touched)) {
                                    <div class="form-errors mt-1 required-field">
                                        <div [hidden]="!profileForm.controls.firstName.errors.required" translate>
                                            settings.lbl_first_name_req
                                        </div>
                                    </div>
                                }
                            </div>
                            <div class="form-field col-6 md:col-10 lg:col-6 field">
                                <label class="flex" translate>lbl_l_name</label>
                                <div class="form-control">
                                    <input formControlName="lastName" class="p-component p-element w-full" type="text" pInputText appOnlyLetters appTrimSpaces/>
                                </div>
                            </div>
                            <div class="form-field col-6 md:col-10 lg:col-6 field">
                                <label class="flex" translate>lbl_email</label>
                                <div class="form-control">
                                    <input formControlName="email" class="p-component p-element w-full" type="email" pInputText appTrimSpaces/>
                                </div>
                                @if (profileForm.controls.email.errors && (profileForm.controls.email.dirty || profileForm.controls.email.touched)) {
                                    <div class="form-errors mt-1 required-field" [hidden]="!profileForm.controls.email.errors.pattern" translate>
                                        settings.email_pattern
                                    </div>
                                }
                            </div>
                            <div class="form-field col-6 md:col-10 lg:col-6 field">
                                <label class="flex" translate>lbl_contact_number</label>
                                <div class="form-control-container">
                                    <p-dropdown [options]="countries" [filter]="true" filterBy="value" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone : true}" optionLabel="code" [showClear]="false">
                                        <ng-template pTemplate="selectedItem">
                                            @if(selectedCountry) {
                                                <div class="flex align-items-center gap-2">
                                                    <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + selectedCountry?.code?.toLowerCase()" class="width-18"/>
                                                    <div>{{ selectedCountry.value }}</div>
                                                </div>
                                            }
                                        </ng-template>
                                        <ng-template let-country pTemplate="item">
                                            <div class="flex align-items-center gap-2">
                                                <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-' + country.code.toLowerCase()" class="width-18"/>
                                                <div>{{ country.value }}</div>
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    <input pInputText type="text" class="input-mobile" pKeyFilter="int" formControlName="contactNumber" (input)="onInputChange()" appTrimSpaces appOnlyDigits/>
                                </div>
                                @if (showMobileValidationError) {
                                    <div class="required" translate>lbl_mobile_number_max_length</div>
                                }
                            </div>
                            <div class="form-field col-6 md:col-10 lg:col-6 field">
                                @if (showImg) {
                                    <img class="flex img-preview" [src]="img" id="preview">
                                }

                                <p-fileUpload
                                    class="cursor-pointer"
                                    mode="advanced"
                                    [chooseLabel]="chooseLabel"
                                    (onSelect)="onUpload($event)"
                                    customUpload="true"
                                    [accept]="accept"
                                    [maxFileSize]="maxFileSize"
                                    [multiple]="isMultipleFilesUpload"
                                    [showCancelButton]="false"
                                    [showUploadButton]="false"
                                    [chooseIcon]="chooseIcon">

                                    <ng-template pTemplate="content" let-files>
                                    <div class="drop-area"> {{ dropAreaMsg }}</div>
                                    </ng-template>
                                </p-fileUpload>
                                @if (uploadedFiles.length > 0) {
                                    <div class="col-12 file-list-div">
                                      <div class="card list flex">
                                        <div class="col-3 md:col-4 lg:col-4">
                                          <label translate>lbl_uploaded_file</label>
                                        </div>
                                        <div class="col-9 md:col-8 lg:col-8">
                                          <div class="inline-flex">
                                            <div class="flex justify-content-between">
                                              <div class="flex justify-content-between align-items-center ml-2">
                                                <label>{{ uploadedFiles[0].file.name }} - {{ uploadedFiles[0].file.size }} bytes</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </form>
                </div>
                <div class="flex justify-content-end mr-17">
                    <button pButton class="mr-2 mb-2 bg-color-gray default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                    <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'label_save' | translate" (click)="onAddUserProfile()"></p-button>
                </div> 
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
