@if (isActivityDataAvailable) {
  @if (showActivity) {
    @for(activity of activityList ; track activity){
      <div class="flex flex-column p-3 pl-4 pt-0">
        <div class="flex flex-row py-12px">
          <div class="w-6 text-green-color">
              <span><i [class]="activity.values.bookingIcon" class="mi-lg font-12 bg-green inline-block vertical-align-text-bottom cursor-pointer wh-18 mr-1"></i></span>
              {{activity.values.booking_status_label}}
          </div>
          <div class="w-6 flex flex-column">
              <div class="font-14 font-bold default-text-black-color">{{activity.values.time_and_date | date : 'hh:mm a'}}<span class="default-text-black-color font-13 font-normal">, {{activity.values.time_and_date | date : 'dd MMM yyyy'}}</span></div>
              <div class="default-text-black-color font-13">By {{activity.values.author}} <span class="default-text-light-gray font-13">({{activity.values.authorRole}})</span></div>
              <div class="flex flex-row pt-2">
                 <div><i class="mi-lg mi-location_outline bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-16 mr-1"></i></div>
                 <div class="default-print-label-color font-13">{{activity.values.booking.booking_pickup_location_address}}</div>
              </div>
          </div>
        </div>
      </div>
      }
      
  }
} @else {
  <div class="w-full pt-0 pr-2 pb-0 pl-3">
    <app-no-result-found></app-no-result-found>    
  </div> 
}