@if(hideService.getVisibilityState(cardId) | async){
<div class="card p-0 shadow-card height-225px">
  <div class="flex justify-content-between align-items-center header-div">
    <h5 class="font-16 mb-0 default-text-black-color font-bold height-17px">
    </h5>
    <!-- <app-hide-button [cardId]="cardId"></app-hide-button> -->
  </div>
  <p-table [value]="totalVehiclesData">
    <ng-template pTemplate="header">
      <tr>
        <th class="font-16 font-bold default-text-black-color" translate>
          dashboard.vehicles
        </th>
        <th class="font-14 font-semibold" translate>dashboard.available</th>
        <th class="font-14 font-semibold" translate>dashboard.occupied</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product>
      <tr>
        <td [ngClass]="product.labelClass">{{ product.label }}</td>
        <td [ngClass]="product.availableClass">{{ product.available }}</td>
        <td [ngClass]="product.occupiedClass">{{ product.occupied }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
}
