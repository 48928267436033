import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { MiErrorComponent } from '../mi-error/mi-error.component';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../mi-fields/mi-tooltip/mi-tooltip.component';
import { BookingTimeRestrictionComponent } from '../../../passengers/components/booking-time-restriction/booking-time-restriction.component';
import { StayHomeAddressComponent } from '../../../passengers/components/stay-home-address/stay-home-address.component';
import { AppSettings } from '../../app.settings';
import { DriverDrivingRecordComponent } from '../../../driver/components/driver-driving-record/driver-driving-record.component';
import { DriverDocumentComponent } from '../../../driver/components/driver-document/driver-document.component';
import { VehicleDocumentComponent } from '../../../vehicles/components/vehicle-document/vehicle-document.component';
import { TimeSettingsComponent } from 'app/modules/organization/components/time-settings/time-settings.component';
import { TranslateModule } from '@ngx-translate/core';
import { CheckboxMultiselectComponent } from '../mi-fields/checkbox-multiselect/checkbox-multiselect.component';
import { CommonModule } from '@angular/common';
import { PrivilegeSettingsAssignLocationComponent } from 'app/modules/settings/components/settings/passenger/settings-privilege-settings/privilege-settings-assign-location/privilege-settings-assign-location.component';


@Component({
  selector: 'app-mi-form',
  templateUrl: './mi-form.component.html',
  styleUrls: ['./mi-form.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MiFieldsComponent, MiErrorComponent, ButtonModule, MiTooltipComponent, StayHomeAddressComponent, BookingTimeRestrictionComponent, DriverDrivingRecordComponent, DriverDocumentComponent, VehicleDocumentComponent, TimeSettingsComponent, TranslateModule, CheckboxMultiselectComponent, CommonModule, PrivilegeSettingsAssignLocationComponent, ]
})
export class MiFormComponent implements OnInit {
  @Input() attributeLabels: [];
  @Input() isHorizontalForm: boolean = false;
  @Input() moduleName: string;
  @Input() groups: any;
  @Input() submitBtnText: string;
  @Input() previousBtnText: string;
  @Input() saveAndAddBtnText: string;
  @Input() attributeValues: [];
  @Input() activeIndex;
  @Input() showImg: boolean = false;
  @Input() docType: any;
  @Input() bookingTimeRestrictionIds: any = [];
  @Input() vehicleDocumentEntityIdList;
  @Input() driverDocumentEntityIdList;
  @Input() driverDrivingRecordIds: any = [];
  @Input() addressEntityIdList: any = [];
  @Input() edit: boolean = false;
  @Input() isMultipleFilesUpload: boolean = true;
  @Input() multiSelectDisplay: any = 'comma';
  @Input() dateFormat: any = AppSettings.DATE_FORMAT_DD_MM_YY;
  @Input() saveBtnId: string;
  @Input() previousBtnId: string;
  @Input() defaultLocationList: any = [];
  @Input() displaySubmitButtons: boolean = true;
  @Input() selectedLoginOptionIds;
  @Input() uploadDocumentValue: [];
  @Input() isShowBookingMap: boolean;
  @Input() hideCancelAndSubmitBtn: boolean = false;
  pKeyFilterForEmergencyContactName: RegExp = AppSettings.NAME_PATTERN;
  pKeyFilterForBarcode = AppSettings.KEY_FILTER_FOR_BARCODE;
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Output() onSaveAndAdd: EventEmitter<any> = new EventEmitter();
  @Output() onClickCancel: EventEmitter<any> = new EventEmitter();
  @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() formGroupEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemoveVehicleDocumentEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemoveDriverDocumentEntity: EventEmitter<any> = new EventEmitter<any>();
  @Output() driverDocumentFieldsLength: EventEmitter<any> = new EventEmitter<any>();
  @Output() vehicleDocumentFieldsLength: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemoveDriverDrivingRecordEntity: EventEmitter<any> = new EventEmitter<any>();
  miFormGroup: FormGroup;
  formGroupFields = [];
  public fields = [];
  GROUP_CODE = AppSettings.GROUP_CODE;
  entityCodes = AppSettings.ENTITY_CODE;
  entityTypes = AppSettings.ENTITY_TYPE;
  fieldsToDisplayHRLine = AppSettings.FIELDS;
  currentSelectedCode: string;
  excludeAttributeCodes = ['people_count', 'drivers', 'role_code', 'assigned_drivers', 'removed_drivers', 'added_drivers', 'driver_entity_id', 'next_shift_location_address', 'next_shift_location_type'];
  differentLabelSet = ['barcode', 'generate_code_for_booking', 'special_login'];
  multiSelectCheckBoxSet = ['require_at_least_one', 'select_data_to_include']
  excludedGroupCodes = [AppSettings.GROUP_CODE.STAY_HOME_ADDRESS,
  AppSettings.GROUP_CODE.BOOKING_TIME_RESTRICTION,
  AppSettings.GROUP_CODE.DRIVER_DRIVING_RECORD,
  AppSettings.GROUP_CODE.DRIVER_DOCUMENT,
  AppSettings.GROUP_CODE.ADDRESS,];
  saveBtnDisabled: boolean = false;
  uploadDocumentValueIds;

  constructor(public cs: CommonBindingDataService,
    private el: ElementRef,
    private fb: FormBuilder) {
  }

  ngOnInit() {
    this.groups = this.sortFormFields();
    this.buildForm();
    this.onChangeForBroadcast();
    this.onChangeBroadcastCondition(this.miFormGroup?.controls['message_type']?.value);
    if (this.uploadDocumentValue) {
      this.uploadDocumentValueIds = this.uploadDocumentValue;
    }
  }

  onChangeForBroadcast() {
    if (this.moduleName === AppSettings.ENTITY_CODE.BROADCAST_MESSAGE) {
      this.miFormGroup.controls['message_type'].valueChanges.subscribe(value => {
        this.onChangeBroadcastCondition(value);
      });
    }
  }

  onChangeBroadcastCondition(value) {
    if (value === AppSettings.BROADCAST_GROUP.DELIVERY_METHODS.PUSH_NOTIFICATION) {
      this.excludeAttributeCodes.push('message_title');
      this.excludeAttributeCodes = this.excludeAttributeCodes.filter(ele => ele !== 'queue_notification');
      this.miFormGroup.controls['message_title'].disable();
      this.miFormGroup.controls['queue_notification'].enable();
    } else if (value === AppSettings.BROADCAST_GROUP.DELIVERY_METHODS.EMAIL) {
      this.excludeAttributeCodes.push('queue_notification');
      this.excludeAttributeCodes = this.excludeAttributeCodes.filter(ele => ele !== 'message_title');
      this.miFormGroup.controls['queue_notification'].disable();
      this.miFormGroup.controls['message_title'].enable();
    } else if (value === AppSettings.BROADCAST_GROUP.DELIVERY_METHODS.SMS) {
      this.excludeAttributeCodes.push('message_title');
      this.excludeAttributeCodes.push('queue_notification');
      this.miFormGroup.controls['queue_notification'].disable();
      this.miFormGroup.controls['message_title'].disable();
    }
  }

  onPreviousClick() {
    this.previousClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }

  getValues() {
  }

  get value() {
    return this.miFormGroup.value;
  }

  sortFormFields() {
    return this.groups.map(group => {
      group.fields.sort((a, b) => a.attributeIndex - b.attributeIndex);
      return group;
    });
  }

  formGroupEmitEvent(event) {
    this.formGroupEmit.emit(event);
  }

  private buildForm() {
    this.buildGroups();
  }

  private buildGroups() {
    const miFormControl = this.getFormControl();
    this.miFormGroup = new FormGroup(miFormControl);
    this.formGroupEmit.emit(this.miFormGroup);
  }

  resetForm() {
    this.miFormGroup.reset();
  }

  onRemoveVehicleDocument(event) {
    this.onRemoveVehicleDocumentEntity.emit(event);
  }

  onRemoveDriverDocument(event) {
    this.onRemoveDriverDocumentEntity.emit(event);
  }

  driverDocumentFieldsChanges(event) {
    this.driverDocumentFieldsLength.emit(event)
  }

  vehicleDocumentFieldsChanges(event) {
    this.vehicleDocumentFieldsLength.emit(event)
  }

  onRemoveDriverDrivingRecord(event) {
    this.onRemoveDriverDrivingRecordEntity.emit(event);
  }

  private getFormControl() {
    for (const group of this.groups) {
      if ((group.code !== AppSettings.GROUP_CODE.STAY_HOME_ADDRESS || this.moduleName !== this.entityCodes.PASSENGER) && !this.excludedGroupCodes.includes(group.code)
      ) {
        for (const field of group.fields) {
          this.currentSelectedCode = field.inputCode;
          const validators = this.addValidator(field.validation);
          field.fieldName = field.attributeCode;
          const value = this.attributeValues ? this.attributeValues[field?.attributeCode] : '';
          field.attributeCode = field.attributeCode === 'length' ? field.attributeCode + '_mi' : field.attributeCode;
          this.formGroupFields[field.attributeCode] = new FormControl(value, validators);
          if (field.inputCode.includes('date')) {
            this.setMinMaxDateForDateField(group, field);
          }
          if (field.inputCode.includes('inputInt')) {
            this.setMinMaxForInputIntField(group, field);
          }
        }
      }
    }
    return this.formGroupFields;
  }

  private setMinMaxDateForDateField(group, field) {
    if (field.inputCode === AppSettings.DATE_OF_BIRTH_INPUT_CODE) {
      field.validation.max = new Date();
    }
    if (group.code === AppSettings.TRANSPORTATION_RESTRICTION_GROUP || group.code === AppSettings.RELATED_SETTINGS
    ) {
      let date = new Date();
      date.setHours(0)
      date.setMinutes(0);
      date.setSeconds(1);
      field.validation.min = date;
    }
  }

  private setMinMaxForInputIntField(group, field) {
    if (field.attributeCode === AppSettings.SERVICE_AREA_RADIUS) {
      field.validation.max = 99;
      field.validation.min = 1;
    }
  }

  private addValidator(rules) {
    const stringValues = ['inputString', 'text', 'textArea', 'inputMobile'];
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      const isString = stringValues.includes(this.currentSelectedCode);
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "min" && rules.min && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.minLength(rules[rule] ? rules[rule] : 10));
        }
      }

      if (rule === "max" && rules.max && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.maxLength(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));

    return validators;
  }


  submitData(event?) {
    if (this.miFormGroup.valid) {
      this.saveBtnDisabled = true;
      this.saveData.next(this.miFormGroup.value);
      setTimeout(() => {
        this.saveBtnDisabled = false;
      }, 500);
      this.onNextClick();
    } else {
      this.miFormGroup.markAllAsTouched();
      if (this.moduleName === AppSettings.ENTITY_TYPE.GENERAL_SETTINGS) {
        this.setFocusOnErrorField();
      } else {
        const firstElementWithError = document.querySelector('.ng-invalid');
        this.scrollTo(firstElementWithError);
      }
    }
  }

  setFocusOnErrorField() {
    Object.keys(this.miFormGroup.controls).forEach(key => {
      if (this.miFormGroup.controls[key].hasError('required')) {
        const num = key.split('_');     
        const ele = document.getElementById(`${num[0]}_select_button`);
        if (ele) {
          ele.click();
          this.scrollTo(ele);
        } else {
          const firstElementWithError = document.querySelector('.ng-invalid');
          this.scrollTo(firstElementWithError);
        }
      }
    });
  }


  saveAndAddData(event) {
    if (this.miFormGroup.valid) {
      this.onSaveAndAdd.next(this.miFormGroup.value);
      this.miFormGroup.reset();
    } else {
      this.miFormGroup.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');
      this.scrollTo(firstElementWithError);
    }
  }

  onClickCancelFun() {
    this.onClickCancel.emit();
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
