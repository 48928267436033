<div class="flex flex-column">
  <p-tabMenu [model]="items" [activeItem]="items[0]" (activeItemChange)="tabMenuActiveItemChange($event)" class="pr-2">
    <ng-template pTemplate="item" let-item>
          <div class="p-menuitem-link flex flex-row align-items-center cursor-pointer">
              <span class="font-bold pr-2 {{item.label.toLowerCase()}}-number"> {{item.number}}</span>
              <span class="uppercase"> {{ item.label }}</span>
          </div>
    </ng-template>
  </p-tabMenu>
  <div class="booking-detail-container">
    @for(item of driverList; track item; let i = $index){
      <div [class]="{'busy-driver': (item.driverCurrentStatus === 'Busy' || item.driverCurrentStatus === 'Not Available')}">
        <app-driver-list-card [isBookingScreen]="isBookingScreen" [bookingStatuses]="bookingStatuses" [driverDetail]="item" (driverCardClicked)="handleDriverCardClicked($event)" [bookingId]="bookingId" [advanceFilterAttributesForBooking]="advanceFilterAttributesForBooking"></app-driver-list-card>
      </div>
    }

    @if (driverList?.length === 0) {
      <div class="w-full pt-0 pr-2 pb-0 pl-3">
        <app-no-result-found></app-no-result-found>    
      </div>         
      }
  </div>
</div>