import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { BookingTabComponent } from 'app/modules/dispatch/components/booking-section/booking-details/booking-tab/booking-tab.component';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { TableModule } from 'primeng/table';
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-booking-activity',
  standalone: true,
  imports: [BookingTabComponent, TableModule, TranslateModule,DatePipe,NgClass],
  templateUrl: './booking-activity.component.html',
  styleUrl: './booking-activity.component.scss'
})
export class BookingActivityComponent {

  tableData: any = [];
  filterAttributeLabels = {};
  accordionTabs: any[] = [];
  bookingId;
  country: Country;
  userIds: any;
  usersData;
  attributeIdForBookingEntityId;
  bookingStatusId;
  BOOKING_STATUS_LABEL = AppSettings.BOOKING.STATUS_BY_ID;
  hh_mm_dd_MMM_yyyy_FORMAT: any = AppSettings.hh_mm_dd_MMM_yyyy_FORMAT;
  dd_MMM_yyyy_FORMAT:any = AppSettings.dd_MMM_yyyy_FORMAT;
  HOUR_MINUTE: any = AppSettings.HOUR_MINUTE;
  miIcons= AppIcons;
  userDetailsById;
  mappedResponse: any[] = [];

  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: '',
    advanceFilters: [],
  };
  bookingActivities=[];
  bookingStatusLabel;

  constructor(private entityService: EntityService,
    private bookingService: BookingService,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(){
    this.bookingId = this.route.snapshot.paramMap.get('id');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.getFilterView();
  }

  public getFilterView() {
    const bookingAdvancedFilterView = AppSettings.ENTITY_CODE.BOOKING_ACTIVITY + '_' + AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW;
      this.bookingService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING_ACTIVITY, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
        if (filterResponse) {
          this.entityService.setMiState(bookingAdvancedFilterView, filterResponse);
          this.setAdvancedFilterView(filterResponse);
        }
        this.attributeIdForBookingEntityId = this.getAttributeId('booking_entity_id', filterResponse);
        if (this.attributeIdForBookingEntityId) {
          this.searchEntity();
        }
      }); 
  }

  getAttributeId(attributeCode: string, attributeData): number | undefined {
    for (const tab of attributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  searchEntity() {
    const uniqueUserIds = new Set();
    const bookingStatus = new Set();
    this.entityData.filters = [{ attributeId: this.attributeIdForBookingEntityId, attributeValue: this.bookingId }];
    this.entityData.countryCode = this.country[0].countryCode;

    this.entityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING_ACTIVITY, this.entityData).subscribe((res: any) => {
      // console.log("result>>>",res)
      this.bookingActivities = [];
      res.data.forEach((activity: any) => {
        const date = activity.values.time_and_date;
        const pickupAddress = activity.relatedData && activity.relatedData.length > 0 ? activity.relatedData[0]?.values?.booking_pickup_location_address : '';
        const statusId = activity.values.booking_status;
        const bookingStatusLabel = AppSettings.BOOKING.STATUS_BY_ID[statusId] || statusId;
        this.bookingStatusLabel = bookingStatusLabel;
        const updatedBy = activity.values.updated_by;

        const iconClass = this.cs.getIconClass(statusId);

        this.bookingActivities.push({
          timeAndDate: date,
          pickupLocation: pickupAddress,
          status: bookingStatusLabel,
          updatedBy: updatedBy,
          iconClass: iconClass
        });

        uniqueUserIds.add(activity.values.updated_by);
        bookingStatus.add(activity.values.booking_status)
      });

      this.bookingStatusId = Array.from(bookingStatus);
      // console.log("Booking Status>>>",this.bookingStatusId);

      this.bookingStatusId = Array.from(bookingStatus).map(statusId => 
        AppSettings.BOOKING.STATUS_BY_ID[statusId as keyof typeof AppSettings.BOOKING.STATUS_BY_ID]  
      );
      
      // console.log("Booking status",this.bookingStatusId)

      this.userIds = Array.from(uniqueUserIds);
      // console.log("userIds>>>",this.userIds);
      this.getUserDetailsById(this.userIds);
      // console.log("Booking Activities:", this.bookingActivities);
      
      
      this.bookingStatusId = Array.from(bookingStatus).map(statusId => 
        AppSettings.BOOKING.STATUS_BY_ID[statusId as keyof typeof AppSettings.BOOKING.STATUS_BY_ID]  
      );

      this.userIds = Array.from(uniqueUserIds);
      this.getUserDetailsById(this.userIds);
    });
  }

  getUserDetailsById(userIds: string[]) {
    this.entityService.getUserDetailsById(userIds).subscribe(res => {
      this.usersData = res;
      // console.log("Users Data>>>", this.usersData);

      this.userDetailsById = this.usersData.map((user: any) => {
        const fullName = `${user.firstName} ${user.lastName}`;
        
        const roleName = user.rolelist && user.rolelist.length > 0
          ? user.rolelist[0]?.roleName || '--'
          : '--';

        return {
          userId: user.userId,
          fullName: fullName,
          roleName: roleName
        };
      });

      // console.log("Mapped User Details:", this.userDetailsById);

      // Now, map the user details to the booking activities
      this.bookingActivities.forEach(activity => {
        const userDetail = this.userDetailsById.find(user => user.userId === activity.updatedBy);
        if (userDetail) {
          activity.updatedBy = `By ${userDetail.fullName} (${userDetail.roleName})`;
        }
      });

      // console.log("Updated Booking Activities:", this.bookingActivities);
  });
}

  setAdvancedFilterView(filterResponse) {
    this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
  }

  findStatusObj(selectedStatus) {
    return this.bookingStatusLabel;
  }

  getIcon(selectedStatus) {
    const booking = this.findStatusObj(selectedStatus);
    return booking?.toUpperCase();
  }

}
