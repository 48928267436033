import { Component, Input } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from '../../../app.settings';
import { Language } from '../../../models/language';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-mi-input-weight',
  templateUrl: './mi-input-weight.component.html',
  styleUrls: ['./mi-input-weight.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, InputNumberModule]
})

export class MiInputWeightComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  unit: string;
  language: Language;
  languageCode: string;

  constructor(private formGroupDirective: FormGroupDirective,
    public controlContainer: ControlContainer, private metaDataService: MetaDataService,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.languageCode = this.language[0].langCode;

  }

  ngOnInit(): void {
    if (this.language && this.languageCode) {
      if (this.field) {
        const unit = this.metaDataService.systemOfMeasurement;
        const tempUnit = unit === 'Imperial' ? JSON.parse(this.field?.imperialUnit) : JSON.parse(this.field?.metricUnit);
        this.languageCode = this.language[0].langCode;
        this.unit = tempUnit[this.languageCode];
      }
    }
  }
}
