import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DriverSidebarComponent } from 'app/modules/dispatch/components/driver-sidebar/driver-sidebar.component';
import { AppIcons } from 'app/modules/shared/app.icons';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ManualPassengerVehicleDetailsComponent } from '../manual-passenger-vehicle-details/manual-passenger-vehicle-details.component';
import { BookingService } from '../../services/booking.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { AppSettings } from 'app/modules/shared/app.settings';

@Component({
  selector: 'app-manual-dispatch',
  standalone: true,
  imports: [TranslateModule, ButtonModule,DriverSidebarComponent,InputSwitchModule,FormsModule, ReactiveFormsModule,ManualPassengerVehicleDetailsComponent],  
  templateUrl: './manual-dispatch.component.html',
  styleUrl: './manual-dispatch.component.scss'
})
export class ManualDispatchComponent {
  @Input() assignConfiguration  = {additionalVehicleCount: 0, luggageVehicleCount: 0, isBookNow: false};
  @Input() miFormGroup: any;
  @Input() luggageBookingIds: [];
  @Input() passengerBookingIds: [];
  @Input() mainBookingId: string;
  @Output() additionalRequired: EventEmitter<any> = new EventEmitter();
  @Output() switchChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  rightDialogPosition: string = "center";
  miIcons = AppIcons;
  position: string;
  rightDriversSectionVisible: boolean = false;
  bookingId:string;
  driverDataVisible: boolean = false; 
  currentDriverDetail: any;
  driverData;
  selectedPassengerDriverData: any;
  selectedAdditionalDriverData: any[] = [];
  selectedLuggageDriverData: any[] = [];
  currentType: string;
  isAssignedPassenger: boolean = false;
  isAssignedAdditional: boolean[] = [];
  isAssignedLuggage: boolean[] = [];
  formValueChanges: boolean = true;
  selectedAdditionalIndex: any;
  passengerVehicle: any[] = [];

  assignDriver: any[] = [];
  country: Country;
  language: Language;
  constructor(private cd: ChangeDetectorRef, 
    private bookingService: BookingService,
    private cdRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private cs: CommonBindingDataService,
    private configService: ConfigService,
    private messageService: MessageService) { }

  ngOnInit() {
    console.log('additional:',this.passengerBookingIds);
    console.log('luggage:',this.luggageBookingIds);
    this.bookingService.driverDetail$.subscribe(driverDetail => {
      console.log(driverDetail)
      this.driverData = driverDetail;
      this.cd.detectChanges();

      if (this.currentType === 'passenger') {
        this.assignDriverData(this.currentType, 0);
      }

      for (let i = 0; i < this.assignConfiguration.additionalVehicleCount; i++) {
        if (this.selectedAdditionalIndex === i) {
          this.assignDriverData(this.currentType, i);
        }
      }
      
      for (let i = 0; i < this.assignConfiguration.luggageVehicleCount; i++) {
        if (this.selectedAdditionalIndex === i) {
          this.assignDriverData(this.currentType, i);
        }
      }
      this.cd.detectChanges();
    });
  }


  showRightDriversDialog(position: string, vehicleType: string, index: number) {
    if (vehicleType === 'luggage') {
      this.bookingId = this.luggageBookingIds[index];
    } else if (vehicleType === 'additional'){
      this.bookingId = this.passengerBookingIds[index];
    } else if (vehicleType === 'passenger'){
      this.bookingId = this.mainBookingId;
    }
    this.selectedAdditionalIndex = index;
    this.currentType = vehicleType
    this.rightDialogPosition = position;
    this.position = position;
    this.rightDriversSectionVisible = false;
    this.cd.detectChanges();
    this.rightDriversSectionVisible = true;
  }

  assignDriverData(vehicleType: string, index: number) {
    switch (vehicleType) {
      case 'passenger':
        if (!this.isAssignedPassenger) {
          this.selectedPassengerDriverData = this.driverData;
          this.driverData.type = 'passenger';
          this.assignDriver.push({...this.driverData, index: 0});
          this.isAssignedPassenger = true;
          this.rightDriversSectionVisible = false;
        }
        break;
        
      case 'additional':
        if (!this.isAssignedAdditional[index]) {
          this.selectedAdditionalDriverData[index] = this.driverData;
          this.driverData.type = 'additional';
          this.assignDriver.push({...this.driverData, index});
          this.isAssignedAdditional[index] = true;
          this.rightDriversSectionVisible = false;
        }
        break;
        
      case 'luggage':
        if (!this.isAssignedLuggage[index]) {
          this.selectedLuggageDriverData[index] = this.driverData;
          this.driverData.type = 'luggage';
          this.assignDriver.push({...this.driverData, index});
          this.isAssignedLuggage[index] = true;
          this.rightDriversSectionVisible = false;
        }
        break;

      default:
        break;
    }

    this.additionalRequired.emit(this.assignDriver);
    this.cd.detectChanges();
  }

  private removeDriver(bookingId: string) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    
    const data = {
      forTenantCode: this.configService.getForTenantId(),
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      bookingEntityId: bookingId,
      type: "DRIVER"
    };
    
    this.bookingService.releaseDriverFromBooking(data).subscribe(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('bookings.driverRemovedSuccessMsg') });
    }, error => {
      console.error('Error removing driver:', error);
    });
  }  

  cancelAssignment(vehicleType: string, index: number) {
    let bookingId: string;
  
    switch (vehicleType) {
      case 'passenger':
        bookingId = this.mainBookingId;
        break;
  
      case 'additional':
        bookingId = this.passengerBookingIds[index];
        break;
  
      case 'luggage':
        bookingId = this.luggageBookingIds[index];
        break;
      default:
        break;
    }
  
    this.confirmationService.confirm({
      header: this.cs.getLabel('bookings.remove_driver_msg_header'),
      message: this.cs.getLabel('bookings.remove_driver_msg'),
      icon: null,
      rejectButtonStyleClass: "bg-white default-text-light-black-color cursor-pointer border-button p-button",
      acceptButtonStyleClass: "p-button-danger",
      acceptLabel: 'Remove',
      rejectLabel: 'Cancel',
      accept: () => {
        switch (vehicleType) {
          case 'passenger':
            this.isAssignedPassenger = false;
            this.selectedPassengerDriverData = null;
            this.assignDriver = this.assignDriver.filter(driver => driver.type !== 'passenger');
            break;
  
          case 'additional':
            this.isAssignedAdditional[index] = false;
            this.selectedAdditionalDriverData[index] = null;
            this.assignDriver = this.assignDriver.filter(driver => {
              return !(driver.type === 'additional' && driver.index === index);
            });
            break;
  
          case 'luggage':
            this.isAssignedLuggage[index] = false;
            this.selectedLuggageDriverData[index] = null;
            this.assignDriver = this.assignDriver.filter(driver => {
              return !(driver.type === 'luggage' && driver.index === index);
            });
            break;
          default:
            break;
        }
  
        this.removeDriver(bookingId);
        this.additionalRequired.emit(this.assignDriver);
        this.cd.detectChanges();
      },
      reject: () => { },
    });
  }   

  showDriverData() {
    this.driverDataVisible = true;
    this.cd.detectChanges();
  }

  hideDriverData() {
    this.driverDataVisible = false;
    this.cd.detectChanges();
  }

}
