@for (driverDetails of driverDetailsById; track driverDetails) {
    <div class="flex flex-column p-3 pl-4 pt-0">
        <div class="flex flex-row align-items-center border-bottom-grey py-12px">
            <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.name</div>
            <div class="w-6">
                <div class="font-14 font-medium" translate>{{ driverDetails.first_name }} {{ driverDetails.last_name }}</div>
            </div>
        </div>
        <div class="flex flex-row border-bottom-grey py-12px">
            <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.driver_tab.shift_time</div>
            <div class="w-6">
                <div class="font-14 font-bold">{{ driverDetails.start_time | date: HOUR_MINUTE }} - {{ driverDetails.end_time | date: HOUR_MINUTE }} </div>
            </div>
        </div>
        <div class="flex flex-row border-bottom-grey py-12px">
            <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.driver_tab.checkin_status</div>
            <div class="w-6">
                <div class="font-14 font-medium">--</div>
            </div>
        </div>
        <div class="flex flex-row border-bottom-grey py-12px">
            <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.driver_tab.call_sign</div>
            <div class="w-6">
                <div class="font-14 font-medium">{{ driverDetails.call_sign_code ? driverDetails.call_sign_code : '-' }}</div>
            </div>
        </div>
        <div class="flex flex-row border-bottom-grey py-12px">
            <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.driver_tab.group</div>
            <div class="w-6">
                <div class="font-14 font-medium" > {{ driverGroupName ? driverGroupName : '-' }}</div>
            </div>
        </div>
        <div class="flex flex-row border-bottom-grey py-12px">
            <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.driver_tab.rating</div>
            <div class="w-6">
                <span><i class="mi-lg icon-star-filled bg-orange inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i></span>
                <span class="font-14 font-medium default-text-black-color" translate>- Stars</span>
            </div>
        </div>
        <div class="flex flex-row border-bottom-grey py-12px">
            <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.driver_tab.gender</div>
            <div class="w-6">
                <div class="font-14 font-medium">{{ driverDetails.gender }}</div>
            </div>
        </div>
    </div>
}

