<div class="card">
    <div class="flex justify-content-between align-items-center">
        <div class="flex align-items-center">
            <i class="{{miIcons.PASSENGER_VEHICLE_MANUAL}} inline-block wh-22 relative mr-2"></i>
            <label class="text-base font-bold ml-1 mt-0" translate>bookings.lbl_passenger_vehicle</label>
        </div>
        @if (!isAssignedPassenger) {
            <button pButton class="font-bold px-3 py-2 surface-0 manual-assign" (click)="showRightDriversDialog('right', 'passenger')" translate>bookings.lbl_assign_now</button>
        } @else {
            <i class="{{miIcons.OFF_CLOSE}} inline-block wh-18 cursor-pointer" (click)="cancelAssignment('passenger', 0)"></i>
        }
    </div>
    @if (selectedPassengerDriverData) {
        <div class="mt-2">
            <app-manual-passenger-vehicle-details [driverDetail]="selectedPassengerDriverData" [isBookingScreen]="false"></app-manual-passenger-vehicle-details>
        </div>
    }
</div>
@for(number of [].constructor(assignConfiguration.additionalVehicleCount); track number; let i = $index) {
    <div class="card">
        <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center">
                <i class="{{miIcons.BOOKING_ADDITIONAL_PASSENGER_VEHICLE}} inline-block wh-22 relative mr-2"></i>
                <label class="text-base font-bold ml-1 mt-0" translate>bookings.lbl_additional_passenger_vehicle</label>
            </div>
            @if (!isAssignedAdditional[i]) {
                <button pButton class="font-bold px-3 py-2 surface-0 manual-assign" (click)="showRightDriversDialog('right', 'additional', i)" translate>bookings.lbl_assign_now</button>
            } @else {
                <i class="{{miIcons.OFF_CLOSE}} inline-block wh-18 cursor-pointer" (click)="cancelAssignment('additional', i)"></i>
            }
        </div>
        @if (selectedAdditionalDriverData[i]) {
            <div class="mt-2">
                <app-manual-passenger-vehicle-details [driverDetail]="selectedAdditionalDriverData[i]" [isBookingScreen]="false"></app-manual-passenger-vehicle-details>
            </div>
        }
    </div>
}
@for(number of [].constructor(assignConfiguration.luggageVehicleCount); track number; let i = $index){
    <div class="card">
        <div class="flex justify-content-between align-items-center">
            <div class="flex align-items-center">
                <i class="{{miIcons.BOOKING_LUGGAGE_VEHICLE_REQUIRED}} inline-block wh-22 relative mr-2"></i>
                <label class="text-base font-bold ml-1 mt-0" >{{ 'bookings.lbl_luggage_vehicle' |translate: {luggageCount: (i + 1)} }}</label>
            </div>
            @if (!isAssignedLuggage[i]) {
                <button pButton class="font-bold px-3 py-2 surface-0 manual-assign"  (click)="showRightDriversDialog('right', 'luggage', i)" translate>bookings.lbl_assign_now</button>
            } @else {
                <i class="{{miIcons.OFF_CLOSE}} inline-block wh-18 cursor-pointer" (click)="cancelAssignment('luggage', i)"></i>
            }
        </div>
        @if (selectedLuggageDriverData[i]) {
            <div class="mt-2">
                <app-manual-passenger-vehicle-details [driverDetail]="selectedLuggageDriverData[i]" [isBookingScreen]="false"></app-manual-passenger-vehicle-details>
            </div>
        }
    </div>
}

<app-driver-sidebar [isBookingScreen]="true"  [(visible)]="rightDriversSectionVisible"  [rightDialogPosition]="rightDialogPosition" [position]="position" [bookingId]="bookingId"></app-driver-sidebar>