<app-dashboard-navbar [includeRearrange]="true" [includeManageWidgets]="true"></app-dashboard-navbar>

<div class="grid m-3 dashboard-content" appAccessProvider [accessCode]="{ menuCode: 'DASHBOARD', pageCode: 'LIVE_DASHBOARD', subPageCode: 'BOOKING' }">
  <!-- booking status -->
  @if(isBookingStatusVisible()) {
    @if(bookingStatusData?.length > 0) {
      <div class="col-12 lg:col-6 xl:col-3">
        <app-booking-status [bookingStatusData]="bookingStatusData"></app-booking-status>
      </div>
    }
  }

  <!-- journey status -->
  @if(isJourneyStatusVisible()) {
    @if(journeyStatusData?.length > 0) {
      <div class="col-12 lg:col-6 xl:col-3">
        <app-journey-status [journeyStatusData]="journeyStatusData"></app-journey-status>
      </div>
    }
  }

  <!-- live status -->
  @if(isLiveStatusVisible()) {
    @if(liveStatusData?.length > 0) {
      <div class="col-12 lg:col-6 xl:col-3">
        <app-live-status class="flex-grow-1" [liveStatusData]="liveStatusData"></app-live-status>
      </div>
    }
  }
      
  <!-- upcoming journey -->
  @if(isUpcomingJourneyVisible()) {
    <div class="col-12 lg:col-6 xl:col-3">
      <app-upcoming-journey [upcomingJourneyData]="upcomingJourneyData"></app-upcoming-journey>
    </div>
  }

  <div class="flex flex-column col-12 lg:col-6 xl:col-3">
    <!-- total cancelled journey-->
    @if(isCancelledJourneyVisible()) {
      <app-cancelled-journey [cancelledJourneyData]="cancelledJourneyData"></app-cancelled-journey>
    }

    <!-- passenger by types -->
    @if(isPassengerByTypesVisible()) {
      <app-passenger-by-types [playerData]="playerData"></app-passenger-by-types>
    }
  </div>

  <div class="col-12 lg:col-6 xl:col-6 pb-0">
    <!-- drivers tab -->
    @if(isDriversVisible()) {
      <div class="mb-3">
        <app-drivers [driverStatusData]="driverStatusData"></app-drivers>
      </div>
    }

    <!-- vehicles -->
    @if(isVehiclesVisible()) {
      <div class="mb-3">
        <app-vehicles-card [totalVehiclesData]="totalVehiclesData"></app-vehicles-card>
      </div>
    }

    <!-- parking queues -->
    @if(isParkingQueuesVisible()) {
      <div class="mb-3">
        <app-parking-queues [parkingQueueData]="parkingQueueData"></app-parking-queues>
      </div>
    }
  </div>

  <!-- live journey by types -->
  @if(isLiveJourneyTypesVisible()) {
    @if(transportRequestData?.length > 0) {
      <div class="col-3">
        <app-live-journey-types [transportRequestData]="transportRequestData"></app-live-journey-types>
      </div>
    }
  }

</div>

      <!-- emergency -->
      <!-- <div class="col-12 lg:col-6 xl:col-6">
        <app-emergency
          [emergencyStatusData]="emergencyStatusData"
        ></app-emergency>
      </div> -->
