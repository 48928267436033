<div class="schedule-detail">
    <div class="wrapper sticky top-0">
        <div class="flex flex-row justify-content-between p-2 pl-4">
            <div class="flex align-items-center"><i class="mi-lg arrow_left prev-next cursor-pointer" (click)="previousClicked()"></i></div>
            <div class="flex flex-grow-1">
                <ul class="flex flex-grow-1 justify-content-around list-none p-0 m-0">
                    @for (day of weekDaysList; track day; let i = $index) {

                        <li class="day-in-week dispay-inline mr-3" (click)="fetchScheduleForSelectedDay(day)">
                            <div class="day inline-block" [class.highlight]="day.date === selectedDate">
                                <div class="flex align-items-center justify-content-center flex-wrap">
                                    <div class="day-name uppercase text-xs"> {{ day.dayName}} </div>
                                </div>
                                <div class="day-number text-center text-base font-semibold ml2"> {{ day.day}} </div>
                            </div>
                        </li>
                    }
                </ul>
            </div>
            <div class="flex align-items-center"><i class="mi-lg arrow_right prev-next cursor-pointer" (click)="nextClicked()"></i></div>
        </div>
        <div class="p-1 pl-4 pr-2 flex flex-row justify-content-end" style="background-color: #F5F5F5;">
            <!-- <span class="text-sm font-semibold text-red-600">Off shift</span> -->
            <div>Journey: 1/4</div>
        </div>
    </div>
    <div class="shift-detail-container">
        <div class="shift-detail p-3 pl-4 pt-1">
            <ul class="list-none p-0 m-0">

                @for (time of timeSlots; track time; let i = $index) {
                    <li>
                        @if (selectedDate === currentDate && i===0) {
                            <div class="hr" ></div>
                        }
                        <div class="shift-rows w-full">


                            <div class="time-line relative">
                                @for (schedule of scheduleList; track schedule; let i = $index) {
                                    @if (schedule.startTimeHour === time.split(':')[0]) {
                                        @if (+(time.split(':')[1]) === 0) {
                                            @if (+(schedule.startTimeMinutes) < 30) {
                                                <div class="booking">
                                                    <div class="pl-2 pt-2">
                                                        <label class="font-medium">{{ schedule.bookingCode }}</label> |
                                                        <label class="font-bold">{{ schedule.pickupTime | date: 'hh:mm' }} -  {{ schedule.dropTime ? (schedule.dropTime | date: 'hh:mm') : 'Null' }}</label> |
                                                        <label class="font-medium">{{ schedule.distance }}</label>
                                                    </div>
                                                </div>
                                            }
                                        } @else {
                                            @if (+(schedule.startTimeMinutes) >= 30) {
                                                <div class="booking">
                                                    <div class="pl-2 pt-2">
                                                        <label class="font-medium">{{ schedule.bookingCode }}</label> |
                                                        <label class="font-bold">{{ schedule.pickupTime | date: 'hh:mm' }} -  {{ schedule.dropTime ?  (schedule.dropTime | date: 'hh:mm') : 'Null' }}</label> |
                                                        <label class="font-medium">{{ schedule.distance }}</label>
                                                    </div>
                                                </div>
                                            }
                                        }


                                    }
                                }
                                <span class="time">{{ time }}</span>
                                <div class="vertical-line absolute top-0"></div>
                                <span class="horizontal-line absolute"></span>
                            </div>
                        </div>
                    </li>
                }
            </ul>
        </div>
    </div>
</div>
