import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DriverDetailsComponent } from '../assign-driver/driver-details/driver-details.component';
import { AssignDriverComponent } from '../assign-driver/assign-driver.component';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { SidebarModule } from 'primeng/sidebar';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import * as _ from 'lodash';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-driver-sidebar',
  standalone: true,
  templateUrl: './driver-sidebar.component.html',
  styleUrl: './driver-sidebar.component.scss',
  imports: [
    DriverDetailsComponent,
    AssignDriverComponent,
    DialogHeaderComponent,
    SidebarModule,
    AccessProviderDirective
  ],
})
export class DriverSidebarComponent {
  @ViewChild(AssignDriverComponent) assignDiverComponent: AssignDriverComponent;
  @ViewChild(DialogHeaderComponent) dialogComponent: DialogHeaderComponent;
  @Input() visible: boolean;
  @Input() position: string;
  @Input() rightDialogPosition: string;
  @Input() bookingId: string;
  @Input() isBookingScreen: boolean = false;
  
  showDriverDetails: boolean = false;
  assignDriverHeader: string;
  driverDetailItem: any = {};
  selectedFilterCount = 0;


  constructor(private cs: CommonBindingDataService) {}

  ngOnInit() {
    this.assignDriverHeader = this.cs.getLabel("dispatch.assign_driver");
  }


  onShowDriverDetail(event) {
    this.showDriverDetails = event.hasData;
    this.driverDetailItem = event.eventData;
    this.bookingId = event.bookingId
  }

  onCloseDriverDetailView(event) {
    this.showDriverDetails = event;
  }

  onDriverFilterValueChange(event) {
    this.setFilterValuesInPayload(event);
  }

  setFilterValuesInPayload(result) {
    console.log(result);
    const filter = result;
    if (filter.wheelchair_installed && filter.wheelchair_installed.length > 0) {
      this.assignDiverComponent.requestBodyForDispatchDriver.wheelChair = filter.wheelchair_installed[0];
    } else {
      this.assignDiverComponent.requestBodyForDispatchDriver.wheelChair = false;
    }

    if (filter.childseat_installed && filter.childseat_installed.length > 0) {
      this.assignDiverComponent.requestBodyForDispatchDriver.childSeat = filter.childseat_installed[0];
    } else {
      this.assignDiverComponent.requestBodyForDispatchDriver.childSeat = false;
    }

    if (filter.parking_location && filter.parking_location.length > 0) {
      this.assignDiverComponent.requestBodyForDispatchDriver.parkingIds = filter.parking_location;
    } else {
      this.assignDiverComponent.requestBodyForDispatchDriver.parkingIds = [];
    }

    if (filter.queue_location && filter.queue_location.length > 0) {
      this.assignDiverComponent.requestBodyForDispatchDriver.queueIds = filter.queue_location;
    } else {
      this.assignDiverComponent.requestBodyForDispatchDriver.queueIds = [];
    }

    if (filter.vehicle_body_type && filter.vehicle_body_type.length > 0) {
      this.assignDiverComponent.requestBodyForDispatchDriver.vehicleBodyType = filter.vehicle_body_type;
    } else {
      delete this.assignDiverComponent.requestBodyForDispatchDriver.vehicleBodyType;
    }

    if (filter.driver_group_id && filter.driver_group_id.length > 0) {
      this.assignDiverComponent.requestBodyForDispatchDriver.driverGroupId = filter.driver_group_id;
    } else {
      delete this.assignDiverComponent.requestBodyForDispatchDriver.driverGroupId;
    }
    this.assignDiverComponent.requestBodyForDispatchDriver.searchStr = '';
    this.assignDiverComponent.searchEntity();
  }

}
