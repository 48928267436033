import { JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { AppIcons } from '../../../../shared/app.icons';
import { ConfigService } from '../../../../shared/services/config.service';
import { TooltipModule } from 'primeng/tooltip';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';

@Component({
  selector: 'app-added-passenger-input',
  standalone: true,
  imports: [DropdownModule, FormsModule, TranslateModule, NgClass,TooltipModule,JsonPipe, MiImageContainerComponent],
  templateUrl: './added-passenger-input.component.html',
  styleUrl: './added-passenger-input.component.scss'
})
export class AddedPassengerInputComponent {
  @Input() addedPassengerList;
  @Input() selectAddedPassenger;
  @Output() onAddedPassenger: EventEmitter<any> = new EventEmitter();
  @Output() onCloseRemove: EventEmitter<any> = new EventEmitter();

  @Input() isPassengerDetails = false;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  
  miIcons = AppIcons;

  constructor(private configService: ConfigService,
    private bookingService: BookingService,
  ) {

  }

  ngOnInit() {
    this.bookingService.selectedPassenger$.subscribe(passengers => {

      if (this.addedPassengerList && this.addedPassengerList.length > 0) {
        this.selectAddedPassenger = this.addedPassengerList[0];
      }
      this.onAddedPassenger.emit(this.selectAddedPassenger);
    });
  }


  onSelectAddedPassenger(event) {
    this.selectAddedPassenger = this.addedPassengerList.find(ele =>ele.id === event.value);
    this.onAddedPassenger.emit(event.value);
  }

  onRemove(event) {
    this.onCloseRemove.emit(event);
  }

}
