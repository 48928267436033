<p-overlayPanel #overlayPanel [style]="{ width: '288px'}">
    <div>
        <div class="font-16 font-semibold default-text-black-color height-22">{{offDutyReason}}</div>
        @if(offDutyReason === 'Log Off') {
            <hr>
            <div>
                <span class="font-14 font-semibold default-text-black-color" translate>lbl_next_shift_location</span>
            </div>
            <div>
                <p>
                    {{ nextShiftLocation?.next_shift_location_type }}
                    ({{ nextShiftLocation?.next_shift_address }}
                    {{ nextShiftLocation?.next_shift_location_address }}
                    {{ nextShiftLocation?.next_shift_nearby_landmark }}
                    {{ nextShiftLocation?.next_shift_city }}
                    {{ nextShiftLocation?.next_shift_state }} ,
                    {{ nextShiftLocation?.next_shift_pincode }})
                </p>
              </div>
        }
    </div>
</p-overlayPanel>
