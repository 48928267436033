<div class="w-full formgrid grid pt-2" cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
    @for (stop of stops; track stop; let s = $index) {
        <div class="grid pt-0 transition box visible" [ngClass]="{'fade-in': stops.length > 0}" style="opacity: 1;">
            <div class="col-12 p-0 md:col-12 pt-0 pb-2 mb-2">
                <div class="flex align-items-center justify-content-between" [cdkDragHandle]="dragHandle">
                    <div class="flex-grow-1 p-0 flex align-items-center justify-content-left ellipsis stops-text relative mr-3">
                        <div class="flex align-items-center relative">
                            @if(s === 0) {
                                <i class="{{miIcons.BOOKING_PICKUP_POINT_ICON}} inline-block wh-22 mr-2"></i>
                            } @else if ((stops.length - 1) === s) { 
                                <i class="{{miIcons.BOOKING_DROP_ICON}} inline-block wh-22 mr-2"></i>
                            } @else {
                                <i class="{{miIcons.BOOKING_STOP_POINT}} inline-block mr-2 text-xs">{{ getLetter(s - 1) }}</i>
                            }
                        </div>
                        <div class="flex-grow-1 align-items-center relative">
                            @if (s === 0) {
                                <label translate class="lbl-geo-pickup stop-locations">bookings.pickup</label>
                            } @else if (s === (stops.length - 1)) {
                                <label translate class="lbl-geo-pickup stop-locations"> bookings.drop_off</label>
                            } @else {
                                <label translate class="lbl-geo-pickup stop-locations">bookings.add_stop</label>
                            }
                            <app-mi-fields [field]="stop" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-mi-fields>
                        </div>
                        @if(stops.length > 1) {
                            <div class="flex align-items-center location-drag-icon absolute">
                                <div class="flex icon-wrapper">
                                    <i class="{{miIcons.BOOKING_SET_LOCATION_ON_MAP}} mi-lg wh-22 inline-block cursor-pointer t-animation" (click)="showDialog(stop, s)"
                                     [pTooltip]="'bookings.Select_location_on_map' | translate" tooltipPosition="top" tooltipStyleClass="tooltip-container"></i>
                                </div>
                                <div #dragHandle class="flex" [ngClass]="showHamburger ? 'ml-3 p-1' : 'ml-0'" cdkDrag>
                                    <i class="{{miIcons.HAMBURGER_1}} add-drag-icon mi-lg wh-18 t-animation cursor-pointer" [ngClass]="showHamburger ? 't-animation inline-block':'hide' "></i>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="w-[100px] p-0 flex justify-content-end align-items-center">
                       
                        @if(s === 0) {
                            <div class="add-stop-icon flex align-items-center border-round p-2 border-1 cursor-pointer" [ngClass]="{'add-drag-icon': showStopLocation}" (click)="addStopPoint($event)">
                                <i class="{{miIcons.BOOKING_EDIT_PLUS_CIRCLE}} wh-34 inline-block bg-primary" [pTooltip]="'bookings.add_stops' | translate" tooltipPosition="top" tooltipStyleClass="tooltip-container"></i>
                            </div>
                        }
                        @if(stops.length === 2 && s === 1) { 
                            <div class="add-stop-icon flex align-items-center border-round p-2 border-1 cursor-pointer" [class.disabled]="stops.length > 2" (click)="swipeAddress($event, s)">
                                <i class="{{miIcons.BOOKING_SWIPE_LOCATION}} wh-34 inline-block bg-primary" [pTooltip]="'bookings.swap_locations' | translate" tooltipPosition="top" tooltipStyleClass="tooltip-container"></i>
                            </div>
                        }
                        @if(stops.length > 2 && s > 0) {
                            <div class="add-stop-icon flex align-items-center border-round p-2 border-1 cursor-pointer" (click)="removeStop(s, stop)">
                                <i class="{{miIcons.CLOSE_BIG}} wh-34 inline-block bg-primary"></i>
                            </div>
                        }
                    </div>
                </div>

                <div class="col-6 py-1">
                    <app-mi-error [field]="stop" [attributeLabels]="attributeLabels" [moduleName]="moduleName"></app-mi-error>
                </div>
            </div>
        </div>
    }
</div>
<div class="stop-location-dialog">
    <p-dialog header="Geo Location" (onShow)="initMap()" (onHide)="close()" focusOnShow="false" [(visible)]="showMap" [style]="{width: '50vw'}">
        @if (showBookingGoogleSearchBox) {
            <span id="bookingGoogleSearch" class="p-input-icon-left ">
                <i class="pi search-icon relative left-30 cursor-pointer pi-search"></i>
                <input id="autocompleteSearch" [(ngModel)]="searchText" type="text" 
                pInputText class="search-field"  [placeholder]="'lbl_search' | translate"
                />
            </span>
        }
        <div class="map-div mb-3 mt-3" id="map">
        </div>
    
        <div class="col-12 save-div mb-3 pr-0">
            <button pButton class="cancel mr-20 text-color height-44 border-button" (click)="close()" [label]="cancelBtnText"></button>
            <p-button class="inline-flex height-44" (click)="saveGeoLocationBooking()" [label]="saveBtnText"></p-button>
        </div>
    </p-dialog>
</div>
