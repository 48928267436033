import { JsonPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormGroupDirective } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { CheckboxModule } from 'primeng/checkbox';
import * as _ from 'lodash';

@Component({
  selector: 'app-checkbox-multiselect',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CheckboxModule, TranslateModule, JsonPipe],
  templateUrl: './checkbox-multiselect.component.html',
  styleUrl: './checkbox-multiselect.component.scss'
})
export class CheckboxMultiselectComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;

  checkboxValues: string[] = [];

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    const value = this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].value;
    if (value && value.length > 0) {
      this.checkboxValues.push(...value);
    }
  }

  onChange(event) {
    this.checkboxValues =  _.uniq(event.checked);
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.checkboxValues);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }
}
