import { NgClass } from "@angular/common";
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { DriverListCardComponent } from "../assign-driver/driver-list-card/driver-list-card.component";
import { FilterBoxComponent } from "../../../shared/components/filter-box/filter-box.component";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { EntityService } from "../../../shared/services/entity.service";
import { AppSettings } from "../../../shared/app.settings";
import * as _ from 'lodash';
import { ConfigService } from "app/modules/shared/services/config.service";
import { BookingService } from "app/modules/bookings/services/booking.service";
import { EventService } from "app/modules/events/services/event.service";
import { forkJoin } from "rxjs";
import { BadgeModule } from 'primeng/badge';
import { Country } from "app/modules/shared/models/country";
import { BookingSectionComponent } from "../booking-section/booking-section.component";

@Component({
  selector: "app-dialog-header",
  templateUrl: "./dialog-header.component.html",
  styleUrls: ["./dialog-header.component.scss"],
  standalone: true,
  imports: [
    NgClass,
    FormsModule,
    TranslateModule,
    InputTextModule,
    ButtonModule,
    DriverListCardComponent,
    FilterBoxComponent,
    BadgeModule
  ],
})
export class DialogHeaderComponent implements OnInit {
  @ViewChild(BookingSectionComponent) bs: BookingSectionComponent;
  @Output() closeBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() filterValueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onValueChange = new EventEmitter<any>();
  @Input() headerLabel: string;
  @Input() forDetail: string;
  @Input() driverDetail: string;
  @Input() hideSearchFilters: boolean = false;
  @Input() position: string;
  @Input() moduleName;
  @Input() view;
  @Input() isHeaderForBooking: boolean = false;

  selectedFilterCount: number;
  searchValue: string = "";
  show: boolean = false;
  lastSyncTime: any;
  lastSync: Date = new Date();
  filterList;
  filterAttributeLabels = {};
  attributeLabels = {};
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];
  vehicleBodyTypes: any[] = [];
  transportationTypeList: any[] = [];
  passengerTypeList: any[] = [];
  locationFilters: any;
  parkingAndQueueList: any[] = [];
  parkingLocationList: any[] = [];
  queueLocationList: any[] = [];
  categoryAttributeIdInLocationFilter: any;
  country: Country;
  constructor(private cs: CommonBindingDataService, private entityService: EntityService,
    private configService: ConfigService, private bookingService: BookingService,
    private cd: ChangeDetectorRef, private eventService: EventService
  ) { }

  ngOnInit() {
    this.getFilterViewForLocation();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.position = this.isHeaderForBooking ? 'left' : 'right';
    console.log(this.isHeaderForBooking)
    console.log(this.position);
    const transportationTypes = this.entityService.getTransportationType();
    const passemngerTypes = this.entityService.getPassengerTypes();

    if (transportationTypes.length === 0 && passemngerTypes.length === 0) {
      this.getFilterPresetValues();
    }

    this.lastSyncTime = this.lastSync.toLocaleTimeString();
    if (this.moduleName === 'driver' && this.view === 'DRIVER_FILTER_ON_DISPATCH_VIEW') {
      // this.getFilterView(this.moduleName, this.view);
      this.getVehicleBodyTypes();
    }
    if (this.moduleName === 'booking' && this.view === 'BOOKING_FILTER_ON_DISPATCH_VIEW') {
      this.getFilterView(this.moduleName, this.view);
    }
  }

  getFilterViewForLocation() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
       this.locationFilters = filterResponse;
       const fields = this.locationFilters?.tabs[0]?.groups[0]?.fields;
       const categoryField = fields ? fields.find(ele => ele.attributeCode === AppSettings.LOCATION_FILTER_ATTRIBUTES.LOCATION_CATEGORY) : [];
       this.categoryAttributeIdInLocationFilter = categoryField ? categoryField.attributeId : null;
      }
    })
  }

  onSearch(event) {
    const value = event.target.value;
    this.cs.setSearchData(value);
  }

  clearSearch() {
    this.searchValue = "";
    this.cs.setSearchData('');
  }

  onCloseDriverDetailView(event) {
    this.closeBtnClicked.emit(false);
  }

  showFilterDialog() {
    this.show = true;
    this.cd.detectChanges();
  }

  refreshTime() {
    this.lastSync = new Date();
    this.lastSyncTime = this.lastSync.toLocaleTimeString()
  }

  getFilterView(moduleName, view?) {
    this.entityService.getAttributeDefinition(moduleName, view).subscribe((filterResponse) => {
      if (filterResponse) {
        this.setView(filterResponse);
      }
    });
  }

  getFilterPresetValues() {
    const eventId = this.eventService.getSelectedEventId();
    const requestBody = {
      limit: 20,
      offset: 0,
      searchStr: '',
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc',
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: 'passenger_type'
    };
  
    forkJoin({
      transportationType: this.eventService.getTransportationTypes(eventId),
      passengerType: this.entityService.searchAttributeSettings(AppSettings.ENTITY_CODE.PASSENGER, requestBody)
    }).subscribe((result: any) => {
      this.transportationTypeList = result.transportationType.map(ele => {
        return {
          labelKey: ele.transportationType,
          labelValue: ele.transportationType
        }
      });

      this.passengerTypeList = result.passengerType.data;
      this.entityService.setTransportationType(this.transportationTypeList);
      this.entityService.setPassengerTypes(this.passengerTypeList);
    });
  }


  setView(filterResponse) {
    this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.filterList = this.cs.getColumns(filterResponse);
    this.filterList = _.sortBy(this.filterList, "attributeIndex");
    console.log(this.filterList);
    this.setTransportationTypesAndPassengerTypesInPresetValues();
  }

  setTransportationTypesAndPassengerTypesInPresetValues() {
    this.filterList.forEach(filter => {
      if (filter.attributeCode === 'transportation_type') {
        filter.presetValues = [];
        filter.presetValues.push(...this.entityService.getTransportationType());
      }
      if (filter.attributeCode === 'owner_pass_type') {
        filter.presetValues = [];
        filter.presetValues.push(...this.entityService.getPassengerTypes());
      }
    });
  }


  onFilterValueChange(filterFlag, event) {
    filterFlag ? (this.advanceFilterValue = event) : (this.quickFilterValue = event);

    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue }
    this.selectedFilterCount = 0;

    for (const [key, value] of Object.entries(event)) {
      const attributeValue = value;
      if (attributeValue) {
        this.selectedFilterCount++;
      }

    }
    this.bookingService.dispatchDriverFilter.next({filter: combineFilter, filterCount: this.selectedFilterCount});
    this.onValueChange.emit(combineFilter);
  }

  getDataForFilter() {
    const requestBodyForParkingLocation = {
      limit: 2000,
      offset: 0,
      searchStr: '',
      filters: [{
        attributeId: this.categoryAttributeIdInLocationFilter,
        attributeValue: [AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.PARKING, AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.QUEUE]
      }],
      forTenantCode: this.configService.getForTenantCode(),
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
      countryCode: this.country[0].countryCode
    }

    forkJoin({
      parkingAndQueueLocationList:  this.entityService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, requestBodyForParkingLocation)
    }).subscribe((result: any) => {
      this.parkingAndQueueList = result.parkingAndQueueLocationList.data;
      this.filterParkingAndQueueLocation();
    })  
  }

  filterParkingAndQueueLocation() {
    this.parkingAndQueueList.forEach((element, index) => {
        if (element.values.location_category === AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.PARKING) {
          this.parkingLocationList.push({
            labelKey: element.values.location_display_name_for_booker,
            labelValue: element.id
          })
        } else {
          this.queueLocationList.push({
           labelKey: element.values.location_display_name_for_booker,
            labelValue: element.id
          })
        }
        if (index === this.parkingAndQueueList.length - 1) {
          this.filterList = this.setFilterListForDriverDispatchView();
        }
    });
  }

  getVehicleBodyTypes() {
    const requestBody = {
      limit: 600,
      offset: 0,
      searchStr: '',
      defaultSortColumn: 'updatedAt',
      defaultSortType: 'desc',
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: 'body_type',
    }
    this.entityService.searchAttributeSettings(AppSettings.ENTITY_CODE.VEHICLE, requestBody).subscribe({
      next: (result: any) => {
        this.vehicleBodyTypes = result.data;
        const labels = this.getFilterLabelsForDriverDispatchView();
        this.filterAttributeLabels =  this.cs.getAttributeLabels(labels);
        this.filterList = this.setFilterListForDriverDispatchView();
        this.getDataForFilter();
      },
      error: (error) => {}
    });
  }

  setFilterListForDriverDispatchView() {
    return [
      {
        attributeCode: 'driver_group_id',
        attributeIndex: 0,
        attributeValue: null,
        uiColumns: 6,
        inputCode: 'inputAutocomplete'
      },
      {
        attributeCode: 'vehicle_body_type',
        attributeIndex: 2,
        attributeValue: null,
        presetValues: this.vehicleBodyTypes,
        uiColumns: 6,
        inputCode: 'select',
      },
      {
        attributeCode: 'wheelchair_installed',
        attributeIndex: 0,
        attributeValue: null,
        presetValues: null,
        uiColumns: 6,
        inputCode: 'boolean',
      },
      {
        attributeCode: 'childseat_installed',
        attributeIndex: 0,
        attributeValue: null,
        presetValues: null,
        inputCode: 'boolean',
      },
      {
        attributeCode: 'queue_location',
        attributeIndex: 2,
        attributeValue: null,
        presetValues: this.queueLocationList,
        uiColumns: 6,
        inputCode: 'select',
      },
      {
        attributeCode: 'parking_location',
        attributeIndex: 2,
        attributeValue: null,
        presetValues: this.parkingLocationList,
        uiColumns: 6,
        inputCode: 'select',
      }
    ];
  }

  getFilterLabelsForDriverDispatchView() {
    return{
        attributeLabels: [
          {
            labelKey: 'driver.fields.driver_status.label',
            labelValue: 'Current Status'
          },
          {
            labelKey: 'driver.fields.driver_group_id.label',
            labelValue: 'Group Name'
          },
          {
            labelKey: 'driver.fields.vehicle_body_type.label',
            labelValue: 'Vehicle Body Type'
          },
          {
            labelKey: 'driver.fields.wheelchair_installed.label',
            labelValue: 'Wheelchair Installed'
          },
          {
            labelKey: 'driver.fields.childseat_installed.label',
            labelValue: 'Child Seat Installed'
          },
          {
            labelKey: 'driver.fields.queue_location.label',
            labelValue: 'Queue Location'
          },
          {
            labelKey: 'driver.fields.parking_location.label',
            labelValue: 'Parking Location'
          }
        ]
    }
  }
}
