<div class="card p-fluid">
    
        <div class="flex justify-content-between flex-wrap">
            <div class="flex justify-content-center mb-3">
                <label class="card-heading uppercase" translate>bookings.lbl_driver_details</label>
            </div>
        </div>
        <div class="driver-border">
            @for (field of driverDetails?.fields; track field; let i = $index) {
                @if (i !== 0) {
                    <hr class="hr-line">
                }
            <div class="grid">
                <div class="col-6">
                    <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                </div>
                <div class="col-6">
                    <label class="detail-value font-semibold">{{ attributeValues?.[field.attributeCode] ? attributeValues?.[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                </div>
            </div>
        }
    </div>
</div>