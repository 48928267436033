import { JsonPipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiFormComponent } from 'app/modules/shared/components/mi-form/mi-form.component';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import type { entityResponse } from 'app/modules/vehicles/models/attribute.models';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-settings-privilege-settings',
  standalone: true,
  imports: [ToastModule, InputTextareaModule, TabViewModule, TranslateModule, DropdownModule, FormsModule, JsonPipe, ButtonModule, MenuModule, TableModule, ButtonModule, ChipsModule, CheckboxModule, MiFormComponent, DropdownModule],
  templateUrl: './settings-privilege-settings.component.html',
  styleUrl: './settings-privilege-settings.component.scss'
})
export class SettingsPrivilegeSettingsComponent implements OnInit {
  miIcons = AppIcons;
  country: Country;
  language: Language;
  passengerAttributes: any;
  attributeLabels: any;
  moduleName = AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF;
  saveLabel = this.cs.getLabel('label_save');
  previousBtnLabel = this.cs.getLabel('cancel');
  attributeValues: any;
  privilegeId;
  data;
  removedPassengerTypeField;
  passengerType;
  passengerTypeId;
  privilegeEntityData;
  passengerTypeDropdown;
  placeholder = this.cs.getLabel('lbl_please_select');
  defaultGroup;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  entityPassengerData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: '',
    tableViewCode: AppSettings.VIEW_CODE.ADD_EDIT_VIEW,
  }

  constructor(private messageService: MessageService, private entityService: EntityService,
    public cs: CommonBindingDataService, private configService: ConfigService, private cd: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.setLabels();
    this.loadPrivilegeData();
    this.cs.passengerType$.subscribe(type=> {
      this.passengerType = type;
      if(this.passengerType) {
        this.searchEntity(this.passengerType, this.passengerTypeId);
      }
    });
  }

  setLabels() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadPrivilegeData() {
    if (this.privilegeId) {
      this.entityService.getEntity(this.privilegeId, AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res) => {
        if (res) {
          const data = res;
          this.passengerAttributes = [];
          this.attributeValues = this.cs.getOrgAttributeValues(data) as any;
          this.cd.detectChanges();
          this.getAttributes();
          this.parseAttributeValues();
        }
      });
    } else {
      this.getAttributes();
    }
  }

  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE, 
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });
  }

  private getAttributes() {
    const entityType = AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF;
    this.entityService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.setView(res);
      }
    });
  }

  setView(res) {
    this.data = res;
    this.passengerAttributes = [];
    this.passengerTypeId = this.getAttributeId('passenger_type', this.data);
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.passengerAttributes = this.cs.getOrganizedAttribute(this.data);
  }

  getAttributeId(attributeCode: string, privilegeAttribute): number | undefined {
    for (const tab of privilegeAttribute.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  searchEntity(passengerType, passengerTypeId) {
    this.entityPassengerData.countryCode = this.country[0].countryCode;
    this.entityPassengerData.filters = [{attributeId: passengerTypeId, attributeValue: passengerType}];
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, this.entityPassengerData).subscribe((res: ListResponse) => {
      if (res) {
        this.privilegeId = res.data[0].id;
        this.loadPrivilegeData();
      }
    });
  }

  onSavePrivilegeSettings(event) {
    const privilegeData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.data.entityCode,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    const privilegeFormData = event;
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE, 
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE];
    for (const [key, value] of Object.entries(privilegeFormData)) {
      let attributeValue = <any>value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      if (attributeValue) {
        privilegeData?.data?.push({
          attributeCode: key,
          attributeValue,
        });
      }
    }

    this.setPrivilegeEntityData(this.data.entityCode);
    if (!this.privilegeId) {
      this.entityService.createEntities(this.privilegeEntityData.entityCode, this.privilegeEntityData).subscribe((res: entityResponse) => {
        this.privilegeId = res.entityId;
        this.savePrivilegeData(this.privilegeEntityData.entityCode, privilegeData, true);
      });
    } else {
      this.savePrivilegeData(this.privilegeEntityData.entityCode, privilegeData, false);
    }
  }

  setPrivilegeEntityData(entityCode) {
    this.privilegeEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  savePrivilegeData(entityCode, privilegeData, isNew: boolean) {
    privilegeData.data = this.cs.mapAttributeIds(privilegeData.data, this.passengerAttributes.tabs);
    this.entityService.saveAttributeData(entityCode, this.privilegeId, privilegeData).subscribe((res: any) => {
      if (isNew) {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.passenger.lbl_added_privilege_settings_success') });
      } else {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.passenger.lbl_updated_privilege_settings_success') });
      }
    });
  }
  
  onPreviousClick() {
    console.log('on click cancel')
  }
}
