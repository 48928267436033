<div class="flex flex-column p-3 pl-4 pt-0">
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.name</div>
        <div class="w-6">
            @if (vehicleDetails?.values?.name_code) {
                <span><img src="../../../../../assets/images/mi-theme/light/basic/Rectangle.png" alt="" class="inline-block vertical-align-middle mr-2"></span>
            }
            <span class="font-14 font-bold default-text-black-color" translate>{{ vehicleDetails?.values?.name_code ? vehicleDetails?.values?.name_code : '-' }}</span>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.group</div>
        <div class="w-6">
            <div class="font-14 font-medium">{{ groupName ? groupName : '-' }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.capacity</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ vehicleDetails?.values?.passenger_capacity ? vehicleDetails?.values?.passenger_capacity : '-' }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.vehicle_type</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ vehicleDetails?.values?.vehicle_type ? vehicleDetails?.values?.vehicle_type : '-'  }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.body_type</div>
        <div class="w-6">
            <div class="font-14 font-bold">{{ vehicleDetails?.values?.body_type ? vehicleDetails?.values?.body_type : '-'  }}</div>
        </div>
    </div>
    <div class="flex flex-row border-bottom-grey py-12px">
        <div class="w-6 default-text-light-black-color font-14 font-normal" translate>dispatch.vehicle_tab.make_model</div>
        <div class="w-6">
            <div class="font-14 font-medium">{{ vehicleDetails?.values?.manufacturer ? vehicleDetails?.values?.manufacturer : '-' }} {{ vehicleDetails?.values?.varient }} {{  vehicleDetails?.values?.year_of_manufacturing }}</div>
        </div>
    </div>
</div>
