@if(hideService.getVisibilityState(cardId) | async){
<div class="card height-225px shadow-card mb-16">
  <div class="flex justify-content-between align-items-center mb-3">
    <h5 class="font-16 mb-0 default-text-black-color font-bold" translate>
      dashboard.assigned_vehicles
    </h5>
    <!-- <app-hide-button [cardId]="cardId"></app-hide-button> -->
  </div>

  <div class="flex flex-row mt-6 mb-6">
    <div class="flex flex-column vehicle-assigned">
      <p class="assignment-number">{{vehicleAssignmentData[0]?.usage}}</p>
      <p class="assignment-label" translate>{{vehicleAssignmentData[0]?.labelKey}}</p>
    </div>
    <div class="flex flex-column vehicle-unassigned">
      <p class="unassignment-number">{{vehicleAssignmentData[1]?.usage}}</p>
      <p class="assignment-label" translate>{{vehicleAssignmentData[1]?.labelKey}}</p>
    </div>
  </div>
</div>
}
