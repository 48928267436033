<div class="card p-fluid">
    
        <div class="flex justify-content-between flex-wrap">
            <div class="flex justify-content-center mb-3">
                <label class="card-heading uppercase" translate>bookings.lbl_vehicle_details</label>
            </div>
            <!-- <div class="flex justify-content-center mb-3">
                <label class="card-action" (click)="redirectToEdit()" translate>vehicle.lbl_edit</label>
                <i class="mi-lg edit_pencil"></i>
            </div> -->
        </div>
        <div class="vehicle-border">
            @for (field of vehicleDetails?.fields; track field; let i = $index) {
                @if (i !== 0 && i !== 8) {
                    <hr class="hr-line">
                }
            <div class="grid">
                @if(field.attributeCode === 'space_1'){
                    <label class="hidden"></label>
                } @else if(field.attributeCode === 'passenger_capacity'){
                    <div class="col-6 capacity-label">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                }
                 @else {
                    <div class="col-6">
                        <label class="detail-heading" translate>{{cs.getLabelValue('vehicle.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                    </div>
                }
                
                @if(field.attributeCode === 'space_1'){
                    <label class="hidden"></label>
                } @else if(field.attributeCode === 'passenger_capacity'){
                    <div class="col-6 capacity-label">
                        <label class="detail-value font-semibold">{{ attributeValues?.[field.attributeCode] ? attributeValues?.[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                    </div>
                }
                @else {
                    <div class="col-6">
                        <label class="detail-value font-semibold">{{ attributeValues?.[field.attributeCode] ? attributeValues?.[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                    </div>
                }
                
            </div>
        }
    </div>
   
</div>