import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { MITableComponent } from '../../../../shared/components/mi-table/mi-table.component';
import { PrintLabelsDialogComponent } from '../../../../shared/components/print-labels-dialog/print-labels-dialog.component';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../../shared/services/entity.service';
import { Router } from '@angular/router';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-overview-details',
  standalone: true,
  imports: [ButtonModule, TranslateModule, DropdownModule, ChartModule, OverlayPanelModule, FormsModule, PrintLabelsDialogComponent, MITableComponent,
    MiImageContainerComponent, AccessProviderDirective
  ],
  templateUrl: './overview-details.component.html',
  styleUrl: './overview-details.component.scss'
})
export class OverviewDetailsComponent implements OnInit, OnChanges {
  miIcons = AppIcons;
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() vehicleAttributeData: any;
  @Input() vehicleEntityId;
  @Input() vehicleRowData;
  @Input() vehicleDetails;
  @Input() driverList;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();

  dropDownItems: any[] = [];
  selectedItem: any = [];
  data: any;
  options: any;
  tab: any;
  details: any;
  center: google.maps.LatLngLiteral = {
    lat: 24,
    lng: 12,
  };
  zoom = 4;
  map: google.maps.Map;
  bulkRowCount = 1;
  barcodeImage;
  visiblePrintLabel: boolean = false;
  rowData;
  isLoading: boolean = false;

  constructor(public vehicleService: EntityService,
    public cs: CommonBindingDataService,
    private messageService: MessageService, private router: Router, private configService: ConfigService
  ) { }

  ngOnChanges() {
    this.rowData = this.vehicleRowData;
  }

  ngOnInit() {
    this.tab = this.vehicleAttributeData.tabs.find(ele => ele.tabCode === 'overview');
    this.details = this.tab.groups.find(ele => ele.code === 'details');
    this.dropDownItems = [
      { name: 'All Time', code: '' },
      { name: '1', code: '1' },
      { name: '2', code: '2' },
      { name: '3', code: '3' },
      { name: '4', code: '4' }
    ];
    this.setChartData();
    this.initMap();
    this.getBarcode();
  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 8,
    });
  }

  setChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [15, 17, 19],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4,
          showLine: false
        },
        {
          label: 'Second Dataset',
          data: [16, 18, 20],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          backgroundColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4,
          showLine: false
        }
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  generateTemplate() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const labelQuantity = 1;
    const labelSize = 'small';
    const data = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.VEHICLE,
      countryCode: country[0].countryCode,
      entityIds: [this.vehicleDetails.entityId],
      labelQuantity: labelQuantity,
      labelSize: labelSize
    };
    this.isLoading = true;
    this.vehicleService.printLabels(data.entityCode, data).subscribe(res => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_download_successfully') });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(res);
      a.download = 'Vehicle-QR-Sticker.pdf';
      a.click();
      this.visiblePrintLabel = false;
      this.isLoading = false;
    },
      err => {
        console.log(err);
      });
  }

  getBarcode() {
    this.vehicleService.downloadBarcode(this.vehicleDetails?.entityId, AppSettings.ENTITY_CODE.VEHICLE).subscribe(res => {
      const reader = new FileReader();
      reader.readAsDataURL(res);
      const that = this;
      reader.onloadend = function() {
        that.barcodeImage = reader.result;
      }
    });
  }

  showPrintLabelDialog() {
    this.visiblePrintLabel = true;
  }

  scrollToTop() {

    const tabviewPanels = document.querySelector('.p-tabview-panels');
    if (tabviewPanels) {
      tabviewPanels.scrollTop = 0;
    }
  }

  redirectToEdit() {
    this.router.navigate(['/app/vehicles/add/' + this.vehicleDetails.entityId]);
  }
}
