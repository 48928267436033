import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  ConfirmationService,
  MenuItem,
  MessageService
} from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { RadioButtonModule } from "primeng/radiobutton";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from "primeng/toast";
import { forkJoin } from "rxjs";
import { AppIcons } from "../../../shared/app.icons";
import { AppSettings } from "../../../shared/app.settings";
import { MITableComponent } from "../../../shared/components/mi-table/mi-table.component";
import { Column } from "../../../shared/components/mi-table/models/table";
import { ModifyDefaultEntityComponent } from "../../../shared/components/modify-default-entity/modify-default-entity.component";
import { ModuleHeaderComponent } from "../../../shared/components/module-header/module-header.component";
import { Country } from "../../../shared/models/country";
import { Language } from "../../../shared/models/language";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { ConfigService } from "../../../shared/services/config.service";
import { EntityService } from "../../../shared/services/entity.service";
import { AssignVehicleComponent } from "../assign-vehicle/assign-vehicle.component";
import { DriverAllStatusDialogComponent } from "../driver-all-status-dialog/driver-all-status-dialog.component";
import { DriverCheckInLocationComponent } from "../driver-check-in-location/driver-check-in-location.component";
import { OffDutyReasonComponent } from "../off-duty-reason/off-duty-reason.component";
import type {
  EntityCount,
  EntityCountsResponse,
  EntityList,
} from "../../../vehicles/models/entity.models";
import type { ListResponse, Values } from "../../../vehicles/models/listResponse.models";
import type { DatumValues, DriverList } from "../../models/driverList.models";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";
import { PermissionAccessService } from "app/modules/shared/services/permission-access.service";
import { SettingsService } from "app/modules/settings/settings.service";

@Component({
  selector: "app-driver-list",
  standalone: true,
  imports: [
    BreadcrumbModule,
    ModuleHeaderComponent,
    TabViewModule,
    TranslateModule,
    MITableComponent,
    AsyncPipe,
    ModifyDefaultEntityComponent,
    AssignVehicleComponent,
    DriverAllStatusDialogComponent,
    DriverCheckInLocationComponent,
    ReactiveFormsModule,
    FormsModule,
    RadioButtonModule,
    OffDutyReasonComponent,
    ToastModule,
    ConfirmDialogModule,
    AccessProviderDirective
  ],
  templateUrl: "./driver-list.component.html",
  styleUrl: "./driver-list.component.scss",
  providers: [RadioButtonModule, ReactiveFormsModule, FormsModule, AsyncPipe]
})
export class DriverListComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  @ViewChild('uploader', { static: true }) uploader: ElementRef<HTMLElement>;
  miIcons = AppIcons;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  cols = [];
  @Input() moduleName: string;
  @Input() field;
  filterList;
  btnLabel: string;
  items: MenuItem[] | undefined;
  isShowMenu: boolean = true;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: DatumValues;
  driverListColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  driverGroupData: any = [];
  checkInLocationData: any = [];
  driverGroupDataTemp: any = [];
  checkInLocationDataTemp: any = [];
  buttons: any[] = [];
  country: Country;
  language: Language;
  entityCount: EntityCountsResponse;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  entityDataForDriverGroup: EntityList = {
    limit: AppSettings.ENTITY_DATA_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  entityDataForCheckInLocation: EntityList = {
    limit: AppSettings.ENTITY_DATA_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  home: MenuItem = {};
  isModal: boolean = false;
  selectedColumns!: Column[];
  deleteDriverMsg: string;
  deleteDriverHeaderMsg: string;
  activeDriverHeaderMsg: string;
  inactiveDriverHeaderMsg: string;
  activeDriverMsg: string;
  inactiveDriverMsg: string;
  showMenu: boolean = false;
  moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  isBulkPrint = true;
  bulkUpdateLabel: string = this.cs.getLabel("driver.update_driver");
  bulkDeleteLabel: string = this.cs.getLabel("driver.delete");
  filterFieldsArray: any[];
  isReady: boolean = false;
  attributeData: any;
  data: any;
  rowItems: MenuItem[] | undefined;
  bulkSelectionsItems: MenuItem[] | undefined;
  currentRow: any;
  showBulkUpdateDialog: boolean = false;
  visible: boolean = false;
  isVisibleModify: boolean = false;
  isVisibleAssignVehicle: boolean = false;
  isVisibleDriverStatus: boolean = false;
  isVisibleDriverCheckInLocation: boolean = false;
  isOffDutyVisible: boolean = false;
  isOnDutyVisible: boolean = false;
  isActive: boolean;
  activeRowItems: MenuItem[] | undefined;
  rowData: any;
  totalRecords: number = 0;
  driverStatusHeader: string;
  offDutyReasons: any[];
  offDutyReasonsTemp: any[];
  vehicleBodyType: any;
  bulkUpdateHeader: string;
  entityNameToDisplay: string;
  offDutyHeader = this.cs.getLabel('driver.lbl_off_duty_reason');
  onDutyHeader = this.cs.getLabel('driver.lbl_on_duty_reason');
  entityNameForBulkUpdate = AppSettings.ENTITY_TYPE.DRIVER;
  reviewHeader: string = this.cs.getLabel("driver.review_header");
  updateBtnLbl: string = this.cs.getLabel("driver.updateBtnLbl");
  itemsPerPage: number;
  locationAttributeData;
  attributeIdForLocation;
  offDutyReasonList;
  changeDriverOnDuty: string;
  entityDataForOffDutyReason: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.OFF_DUTY_REASON
  };

  private maxDataReached = {
    entityDataForDriverGroup: false,
    entityDataForCheckInLocation: false
  }

  constructor(
    private driverService: EntityService,
    private cs: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private configService: ConfigService,
    private permissionAccessService: PermissionAccessService
  ) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.setRowItems();
    this.bulkUpdateItems();
    this.moduleHeaderMenuItems();
    this.setLabels();
    this.getAttributeData();
    this.getData();
    this.getOffDutyReason();
    this.searchEntityForDriverGroup();
    this.searchEntityForCheckInLocation();
    this.searchOffDutyReasons();
  }

  private setBreadcrumb() {
    this.routePath = [{ label: "Drivers", routerLink: "driver/list" }];
  }

  private setRowItems() {
    const isEdit = this.permissionAccessService.hasAccess('DRIVERS', 'ALL_DRIVERS', 'ALL', 'EDIT');
    const isDelete = this.permissionAccessService.hasAccess('DRIVERS', 'ALL_DRIVERS', 'ALL', 'DELETE');
    this.rowItems = [
      ...(isEdit ? [{
        label: "Edit",
        icon: AppIcons.BASIC_EDIT_2 + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.update();
        },
      }] : []),
      {
        label: this.cs.getLabel('driver.lbl_modify'),
        icon: AppIcons.VEHICLE + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.openModifyDialog(this.currentRow);
        },
      },
      {
        label: this.cs.getLabel('driver.lbl_release_default_vehicle'),
        icon: AppIcons.BASIC_UNLINK + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.releaseVehicle(this.currentRow, AppSettings.DRIVER.RELEASE.DEFAULT_VEHICLE)
        },
      },
      {
        label: this.cs.getLabel('driver.lbl_release_active_vehicle'),
        icon: AppIcons.BASIC_UNLINK + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.releaseVehicle(this.currentRow, AppSettings.DRIVER.RELEASE.ACTIVE_VEHICLE)
        },
      },
      {
        label: this.cs.getLabel('driver.message.release_driver_group_header'),
        icon: AppIcons.BASIC_UNLINK + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.onChangeReleaseDriverGroup(this.currentRow)
        },
      },
      ...(isDelete ? [{
        label: this.cs.getLabel('driver.message.delete_driver'),
        icon: AppIcons.BASIC_DELETE + " wh-16",
        iconClass: "bulk-update-icon",
        command: (e) => {
          const event = _.cloneDeep(e);
          this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
        },
      }] : []),
      {
        separator: true,
      },
      {
        label: this.cs.getLabel('driver.lbl_make_driver_inactive'),
        icon: AppIcons.OVERDUE + " wh-16 bg-red-500",
        iconClass: "bulk-update-icon",
        id: 'driver_status',
        command: () => {
          this.changeDriverStatus(this.currentRow);
        },
      },
    ];
    this.activeRowItems = this.rowItems;
  }

  private bulkUpdateItems() {
    const isBulkAction = this.permissionAccessService.hasAccess('DRIVERS', 'ALL_DRIVERS', 'ALL', 'BULK_ACTION');
    this.bulkSelectionsItems = [
      ...(isBulkAction ? [{
        label: this.bulkUpdateLabel,
        icon: AppIcons.EDIT_2 + " wh-122",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: () => {
          this.tableRef.showBulkRowDialog();
        },
      }] : []),
      ...(isBulkAction ? [{
        label: this.bulkDeleteLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: (e) => {
          const event = _.cloneDeep(e);
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      }] : []),
    ];
  }

  private releaseVehicle(selectedRow, type) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      driverEntityId: selectedRow?.id,
      languageCode: language[0].langCode,
      type,
    }
    let header, message;
    header = this.cs.getLabel('driver.message.release_active_vehicle_header');
    message = this.cs.getLabel('driver.message.release_active_vehicle');
    if (type === AppSettings.DRIVER.RELEASE.DEFAULT_VEHICLE) {
      header = this.cs.getLabel('driver.message.release_default_vehicle_header');
      message = this.cs.getLabel('driver.message.release_default_vehicle');
    }
    const entityCode = AppSettings.ENTITY_CODE.VEHICLE;
    this.confirmationService.confirm({
      header: header,
      message: message,
      icon: null,
      rejectButtonStyleClass: "bg-white default-text-light-black-color cursor-pointer border-button p-button",
      acceptButtonStyleClass: "p-button-danger",
      acceptLabel: 'Remove',
      rejectLabel: 'Cancel',
      accept: () => {
        this.driverService.releaseVehicle(entityCode, reqBody).subscribe((res) => {
          this.searchEntity();
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_release_modify_vehicle_success') });
        },
          () => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
          });
      },
      reject: () => { },
    });
  }

  onChangeReleaseDriverGroup(selectedRow) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      driverEntityId: selectedRow?.id,
      languageCode: language[0].langCode,
      type: AppSettings.ENTITY_CODE.DRIVER_GROUP
    }
    let header, message;
    header = this.cs.getLabel('driver.message.release_driver_group_header');
    message = this.cs.getLabel('driver.message.release_driver_group');
    const entityCode = AppSettings.ENTITY_CODE.DRIVER_GROUP;
    this.confirmationService.confirm({
      header: header,
      message: message,
      icon: null,
      rejectButtonStyleClass: "bg-white default-text-light-black-color cursor-pointer border-button p-button",
      acceptButtonStyleClass: "p-button-danger",
      acceptLabel: 'Remove',
      rejectLabel: 'Cancel',
      accept: () => {
        this.driverService.releaseDriverGroup(entityCode, reqBody).subscribe((res) => {
          this.searchEntity();
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('lbl_release_driver_group_success') });
        },
          () => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_invalid_data') });
          });
      },
      reject: () => { },
    });
  }

  private moduleHeaderMenuItems() {
    this.moduleHeaderSecondaryBtnMenuItems = [
      {
        label: this.cs.getLabel("driver.import_driver"),
        icon: AppIcons.UPLOAD + " wh-16 ",
        command: () => {
          const el: HTMLElement = this.uploader.nativeElement;
          el.click();
        }
      },
      {
        label: this.cs.getLabel("driver.download_import_template"),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.driverService
            .downloadImportTemplate(AppSettings.ENTITY_CODE.DRIVER)
            .subscribe(
              (result: any) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(result);
                a.download = "Import Template.xlsx";
                a.click();
              },
              (err) => {
                console.log(err);
              }
            );
        },
      },
      {
        label: this.cs.getLabel("driver.download_import_guide"),
        icon: AppIcons.DOWNLOAD2 + " wh-16",
        command: () => {
          this.driverService
            .downloadImportGuide(AppSettings.ENTITY_CODE.DRIVER)
            .subscribe(
              (result) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(result);
                a.download = "Import Guide.xlsx";
                a.click();
              },
              (err) => {
                console.log(err);
              }
            );
        },
      },
      {
        separator: true,
      },
      {
        label: this.cs.getLabel("driver.export_xlsx_csv"),
        icon: AppIcons.EXPORT + " wh-16",
      },
    ];
  }

  private setLabels() {
    this.btnLabel = this.cs.getLabel("driver.lbl_add_driver");
    this.bulkUpdateHeader = this.cs.getLabel("driver.update_driver");
    this.entityNameToDisplay = this.cs.getLabel('lbl_driver');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.deleteDriverMsg = this.cs.getLabel("driver.message.confirm_delete_msg");
    this.deleteDriverHeaderMsg = this.cs.getLabel("driver.message.confirm_delete_header");
    this.activeDriverMsg = this.cs.getLabel("driver.active_driver_message");
    this.inactiveDriverMsg = this.cs.getLabel("driver.inactive_driver_message");
    this.activeDriverHeaderMsg = this.cs.getLabel("driver.active_driver_header_message");
    this.inactiveDriverHeaderMsg = this.cs.getLabel("driver.inactive_driver_header_message");
  }

  public onChangeImport(event) {
    for (const file of event.target.files) {
      if (file) {
        if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
        } else {
          const formData = new FormData();
          formData.append("file", file);
          formData.append('forTenantCode', this.configService.getForTenantCode());
          formData.append('entityCode', AppSettings.ENTITY_CODE.DRIVER);
          formData.append('viewCode', AppSettings.VIEW_CODE.EXCEL_IMPORT_VIEW);
          this.cs.importEntity(formData, AppSettings.ENTITY_CODE.DRIVER).subscribe(res => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('lbl_file_uploaded_successfully') });
          })
        }
      }
    }
  }

  getAttributeData() {
    this.driverService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.BULK_UPDATE_VIEW).subscribe(res => {
      if (res) {
        this.setDriverBulkUpdateView(res);
      }
    });
  }

  setDriverBulkUpdateView(res) {
    this.attributeData = res;
    this.isLoading = false;
    this.filterFieldsArray = res.tabs[0].groups[0].fields;

    this.filterFieldsArray.forEach(element => {
      switch (element.attributeCode) {
        case 'driver_group_id':
          element.attributeValue = 'Driver Group';
          element.isDisable = false;
          break;
        case 'driver_status':
          element.attributeValue = 'Status';
          element.isDisable = false;
          break;
      }
    });
  }

  getOffDutyReason() {
       this.driverService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.DRIVER_ASSIGN_DUTY_STATUS_VIEW)
      .subscribe((offDutyReason) => {
        this.setOffDutyView(offDutyReason);
      });
  }

  setOffDutyView(offDutyReason) {
    this.offDutyReasons = offDutyReason?.tabs[0]?.groups[0]?.fields[1]?.presetValues;
  }

  setCurrentRowData(event) {
    this.currentRow = event;
    const driverStatus = this.rowItems.find(ele => ele.id === 'driver_status');
    if (driverStatus) {
      driverStatus.label = this.getDriverStatusLabel(event.driver_status);
      driverStatus.icon = this.getDriverStatusIcon(event.driver_status);
    }
    const isDefaultVehicle = this.currentRow?.default_vehicle_id ? true : false;
    const isActiveVehicle = this.currentRow?.active_vehicle_id ? true : false;
    const isDriverGroup = this.currentRow?.driver_group_id ? true : false;
    this.rowItems.find(ele => ele.label === this.cs.getLabel('driver.message.delete_driver')).disabled = this.currentRow.deleted;
    this.rowItems.find(ele => ele.label === this.cs.getLabel('driver.lbl_release_default_vehicle')).disabled = (this.currentRow.deleted || !isDefaultVehicle);
    this.rowItems.find(ele => ele.label === this.cs.getLabel('driver.lbl_release_active_vehicle')).disabled = (this.currentRow.deleted || !isActiveVehicle);
    this.rowItems.find(ele => ele.label === this.cs.getLabel('driver.message.release_driver_group_header')).disabled = (this.currentRow.deleted || !isDriverGroup);
    this.rowItems.find(ele => ele.label === this.cs.getLabel('driver.lbl_modify')).disabled = this.currentRow.deleted;
    this.activeRowItems = this.rowItems.filter(ele => ele.visible === null || ele.visible === false || ele.visible === undefined);
  }

  getDriverStatusLabel(status: string): string {
    const statusKey = status.toLowerCase() === AppSettings.DRIVER_STATUS.INACTIVE.toLowerCase()
      ? 'driver.lbl_make_driver_active'
      : 'driver.lbl_make_driver_inactive';
    return this.cs.getLabel(statusKey);
  }

  getDriverStatusIcon(status: string): string {
    const iconBase = AppIcons.OVERDUE + " wh-16 ";
    return status.toLowerCase() === AppSettings.DRIVER_STATUS.INACTIVE.toLowerCase()
      ? iconBase + 'bg-green-500'
      : iconBase + 'bg-red-500';
  }

  update() {
    this.onUpdateDriver(this.currentRow.id);
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteDriverHeaderMsg,
      message: this.deleteDriverMsg,
      acceptIcon: "",
      rejectIcon: "",
      rejectButtonStyleClass: "bg-white text-color",
      acceptButtonStyleClass: "bg-red-500",
      accept: ($event) => {
        if (deleteType === "row") {
          this.onDriverDelete(this.currentRow.id);
        } else {
          this.onBulkDataDeleteEvent(
            this.currentRow.map((ele) => {
              return ele.id;
            })
          );
        }
      },
      reject: () => { },
    });
  }

  changeDriverStatus(currentRow) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityId = currentRow.id;
    const driverStatus = this.getDriverStatus(currentRow);
    const driverInactive = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      languageCode: this.language[0].langCode,
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
      driverStatus: driverStatus,
      driverEntityIds: [entityId]
    }
    const { header, message } = this.getConfirmationData(currentRow);

    this.confirmationService.confirm({
      header: header,
      message: message,
      acceptIcon: "",
      rejectIcon: "",
      rejectButtonStyleClass: "bg-white text-color",
      acceptButtonStyleClass: "bg-red-500",
      accept: () => {
        this.driverService.updateDriverStatus(driverInactive).subscribe((res: any) => {
          this.cs.onDisplayMessage(this.attributeData, 'driver', AppSettings.MSG_ACTION.UPDATE_STATUS, 'success');
          this.searchEntity();
          this.getEntityCount();
        });
      },
      reject: () => { },
    });
  }

  getDriverStatus(currentRow: any): string {
    return currentRow.driver_status.toLowerCase() === AppSettings.DRIVER_STATUS.INACTIVE.toLowerCase()
      ? AppSettings.DRIVER_STATUS.ACTIVE
      : AppSettings.DRIVER_STATUS.INACTIVE;
  }

  getConfirmationData(currentRow: any): { header: string; message: string } {
    const isInactive = currentRow.driver_status.toLowerCase() === AppSettings.DRIVER_STATUS.INACTIVE.toLowerCase();
    return {
      header: isInactive ? this.activeDriverHeaderMsg : this.inactiveDriverHeaderMsg,
      message: isInactive ? this.activeDriverMsg : this.inactiveDriverMsg
    };
  }

  getTableView() {
    this.driverService
      .getAttributeDefinition(
        AppSettings.ENTITY_CODE.DRIVER,
        AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW
      )
      .subscribe((res) => {
        if (res) {
          this.setDriverTableView(res);
        }
      });
  }

  setDriverTableView(res) {
    this.isLoading = false;
    this.data = res;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.allColumns = this.cs.getColumns(this.data);
    this.searchEntity();

    this.allColumns?.forEach((key, index) => {
      this.allColumns[index] = key;
      this.allColumns[index].field = key.attributeCode;
      this.allColumns[index].header = this.cs.getLabelValue(
        "driver" + ".fields." + key.attributeCode + ".label",
        this.attributeLabels,
        key.attributeCode
      );
    });
    this.filterAvailableColumns = _.clone(this.allColumns);
  }

  getFilterView() {
    this.driverService
      .getAttributeDefinition(
        AppSettings.ENTITY_CODE.DRIVER,
        AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW
      )
      .subscribe((filterResponse) => {
        if (filterResponse) {
          this.setDriverFilterView(filterResponse);
        }
      });
  }

  setDriverFilterView(filterResponse) {
    this.filterAttributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.filterList = this.cs.getColumns(filterResponse);
    this.filterList = this.filterList.filter(item => item.attributeCode !== 'driver_user_id');
    this.filterList = _.sortBy(this.filterList, "attributeIndex");
  }

  getData() {
    return forkJoin({
      tableViewData: this.getTableView(),
      filterViewDate: this.getFilterView(),
      getEntityCount: this.getEntityCount(),
    });
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.countryCode = country[0].countryCode;
    this.driverService
      .searchEntity(AppSettings.ENTITY_CODE.DRIVER, this.entityData)
      .subscribe((res: DriverList) => {
        this.listColumns = res?.data[0]?.values;
        this.totalRecords = res?.count;
        this.tableData = [];

        this.tableData = res.data?.map(data => {

          const relatedDriverGroup = data.relatedData?.filter(related => related.entityCode === AppSettings.ENTITY_CODE.DRIVER_GROUP)
            .map(related => ({
              id: related.id,
              ...related.values
            }));

          const active_vehicle_id = data.values?.active_vehicle_id;

          const activeVehicle = data.relatedData?.filter(related => related.entityCode === AppSettings.ENTITY_CODE.VEHICLE && related.id === active_vehicle_id)
            .map(related => ({
              id: related.id,
              ...related.values
            }));

          const default_vehicle_id = data.values?.default_vehicle_id;

          const defaultVehicle = data.relatedData?.filter(related => related.entityCode === AppSettings.ENTITY_CODE.VEHICLE && related.id === default_vehicle_id)
            .map(related => ({
              id: related.id,
              ...related.values
            }));

          const relatedLocation = data.relatedData?.filter(related => related.entityCode === AppSettings.ENTITY_CODE.LOCATION)
            .map(related => ({
              id: related.id,
              ...related.values
            }));

          return {
            id: data.id,
            ...data.values,
            deleted: data.actionStatus === "DELETED" ? true : false,
            driverGroup: relatedDriverGroup,
            activeVehicle,
            defaultVehicle,
            location: relatedLocation
          };
        }) || [];
        this.tableData.forEach(rowData => {
          if (rowData?.driver_profile_image && (rowData?.driver_profile_image[0])) {
            this.getFile(rowData)
          }
        });
      });
  }

  getFile(rowData) {
    this.driverService.getFile(rowData.driver_profile_image[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'entity');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        rowData.profileImage = base64data;
      }
    })
  }

  searchEntityForDriverGroup(assignDriverData = false) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));

    if (this.entityDataForDriverGroup.offset === 0) {
      this.driverGroupData = [];
    }

    this.entityDataForDriverGroup.countryCode = country[0].countryCode;
    this.driverService
      .searchEntity(AppSettings.ENTITY_CODE.DRIVER_GROUP, this.entityDataForDriverGroup)
      .subscribe((res: ListResponse) => {
        this.driverListColumns = res?.data[0]?.values;
        const driverGroupData = res.data?.map(data => {
          const relatedDrivers = data?.relatedData?.map(relatedData => ({
            driverGroupId: relatedData?.id,
            ...relatedData?.values
          })) || [];
          return { driverGroupId: data.id, ...data.values, driverGroup: relatedDrivers };
        }) || [];

        this.driverGroupData = [...this.driverGroupData, ...driverGroupData];

        if (res?.data.length === 0) {
          this.maxDataReached.entityDataForDriverGroup = true;
        }

        if (assignDriverData && !this.maxDataReached.entityDataForDriverGroup) {
          this.driverGroupDataTemp = this.driverGroupData;
          this.maxDataReached.entityDataForDriverGroup = this.driverGroupDataTemp.length >= res.count;
        }
      });
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.LOCATION;
       this.driverService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.setLocationView(res);
      }
    });
  }

  setLocationView(res) {
    this.data = res;
    this.locationAttributeData = this.cs.getOrganizedAttribute(this.data);
    this.attributeIdForLocation = this.getAttributeId('location_category', this.locationAttributeData);    
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));

    if (this.entityDataForCheckInLocation.offset === 0) {
      this.checkInLocationData = [];
    }

    this.entityDataForCheckInLocation.filters.push({
      attributeId: this.attributeIdForLocation,
      attributeValue: ['Parking']
    });
    this.checkInLocationData = [];
    this.entityDataForCheckInLocation.countryCode = country[0].countryCode;
    this.driverService
      .searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityDataForCheckInLocation)
      .subscribe((res: ListResponse) => {
        this.driverListColumns = res?.data[0]?.values;
        const checkInLocationData = res.data?.map(data => {
          const relatedLocation = data?.relatedData?.map(relatedData => ({
            id: relatedData?.id,
            ...relatedData?.values
          })) || [];
          return { id: data.id, ...data.values, location: relatedLocation };
        }) || [];

        this.checkInLocationData = [...this.checkInLocationData, ...checkInLocationData];

        if (res?.data.length === 0) {
          this.maxDataReached.entityDataForCheckInLocation = true;
        }

        if (!this.maxDataReached.entityDataForCheckInLocation) {
          this.checkInLocationDataTemp = this.checkInLocationData;
          this.maxDataReached.entityDataForCheckInLocation = this.checkInLocationDataTemp.length >= res.count;
        }
      });
  }

  getAttributeId(attributeCode: string, locationAttributeData): number | undefined {
    for (const tab of locationAttributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  searchEntityForCheckInLocation(assignCheckInLocationData = false) {
    this.getAttributes();

  }

  onAddDriver() {
    this.router.navigate(["app/drivers/add"]);
  }

  getLabel(params) {
    return params + "hi";
  }

  onRowClicked(e: any) {
    alert("Row click : " + JSON.stringify(e));
  }

  onActionBtnClicked(e: any) {
    alert("Action btn click : " + JSON.stringify(e));
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.itemsPerPage = event;
    this.entityData.limit = this.itemsPerPage;
    this.searchEntity();
  }

  onConfirmDelete(event) { }

  onDriverDelete(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
      entityIds: [
        event
      ]
    };
    this.driverService.deleteEntityActionStatus(requestBody).subscribe((response) => {
      this.cs.onDisplayMessage(this.attributeData, 'driver', AppSettings.MSG_ACTION.DELETE, 'success');
      this.searchEntity();
      this.getEntityCount();
    });
  }

  onUpdateDriver(driverId) {
    this.router.navigate(["app/drivers/add/" + driverId]);
  }

  onRowSelect(event) {
    if (event.type === 'row') {
      this.router.navigate(["app/drivers/" + event.data.id]);
    }
  }

  openModifyDialog(currentRow) {
    this.isVisibleModify = true;
    this.rowData = currentRow;
  }

  onChangeDefaultVehicle(currentRow) {
    this.isVisibleAssignVehicle = true;
    this.rowData = currentRow;
  }

  openDriverStatusDialog(currentRow) {
    this.isVisibleDriverStatus = true;
    this.rowData = currentRow;
    this.driverStatusHeader = AppSettings.DRIVER.STATUS_HEADER.DRIVER_GROUP;
    this.driverGroupDataTemp = this.driverGroupData;
    this.checkInLocationDataTemp = [];

    setTimeout(() => {
      const cb = () => {
        this.entityDataForDriverGroup.offset = this.entityDataForDriverGroup.offset + (this.entityDataForDriverGroup.offset === 0 ? this.entityDataForDriverGroup.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForDriverGroup) {
          this.searchEntityForDriverGroup(true);
        }
      };
      this.applyInfiniteScroll('#assign-group .p-dialog-content', cb)
    }, 30);
  }

  applyInfiniteScroll(selector, onEndPageCallback) {
    const contentEl = document.querySelector(selector) as HTMLElement;

    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const endOfPage = Math.round(contentEl.scrollTop) === heightDiff || Math.round(contentEl.scrollTop) === (heightDiff + 1);

      if (endOfPage) {
        onEndPageCallback()
      }
    };

    contentEl.addEventListener("scroll", handleInfiniteScroll)
  }

  loadSearchEntity() {
    setTimeout(() => {
      this.isVisibleDriverStatus = false;
      this.isVisibleDriverCheckInLocation = false;
      this.isVisibleAssignVehicle = false;
      this.isVisibleModify = false;
      this.isOffDutyVisible = false;
      this.searchEntity();
    }, 500)
  }

  onChangeSelectCheckInLocation(currentRow) {
    this.isVisibleDriverCheckInLocation = true;
    this.rowData = currentRow;
    this.driverStatusHeader = AppSettings.DRIVER.STATUS_HEADER.CHECKIN_LOCATION;
    this.checkInLocationDataTemp = this.checkInLocationData;
    this.driverGroupDataTemp = [];

    setTimeout(() => {
      const cb = () => {
        this.entityDataForCheckInLocation.offset = this.entityDataForCheckInLocation.offset + (this.entityDataForCheckInLocation.offset === 0 ? this.entityDataForCheckInLocation.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForCheckInLocation) {
          this.searchEntityForCheckInLocation(true);
        }
      };
      this.applyInfiniteScroll('#check-in-location .p-dialog-content', cb);
    }, 30);
  }

  searchOffDutyReasons() {
    this.settingsService.searchAttributeSettings(AppSettings.ENTITY_TYPE.DRIVER, this.entityDataForOffDutyReason).subscribe((res: ListResponse) => {
      this.offDutyReasonList = this.cs.getTableDataForSettings(res);
    })
  }

  onChangeOffDutyReason(currentRow) {
    this.isOffDutyVisible = true;
    this.rowData = currentRow;
  }

  onChangeOnDutyReason(currentRow) {
    this.isVisibleAssignVehicle = true;
    this.changeDriverOnDuty = this.cs.getLabel('lbl_change_driver_on_duty');
    this.rowData = currentRow;
  }

  onFilterValueChange(event) {
    const driverFormData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(driverFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue,
        });
      }
    }
    this.entityData.filters = _.filter(
      _.uniq(this.entityData.filters, function(item, key, a) {
        if (item.attributeValue.length > 0) {
          return item.attributeCode;
        }
      }),
      function(element) {
        if (element.attributeValue.length > 0) {
          return true;
        }
        return false;
      }
    );
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  getEntityCount() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const entityCountPayload = <EntityCount>{
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
    };

    this.driverService
      .getEntityCount(AppSettings.ENTITY_CODE.DRIVER, entityCountPayload)
      .subscribe((res: EntityCountsResponse) => {
        this.entityCount = res;
      });
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = "";
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        break;
      case 1:
        this.entityData.actionStatus = AppSettings.DRIVER_TAB_lIST.ACTIVE;
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        break;

      case 2:
        this.entityData.actionStatus = AppSettings.DRIVER_TAB_lIST.INACTIVE;
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        break;

      case 3:
        this.entityData.actionStatus = AppSettings.DRIVER_TAB_lIST.DELETE;
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_DELETED;
        break;

      default:
        break;
    }
    this.searchEntity();
  }

  onBulkDataUpdateEvent(event) {
    setTimeout(() => {
      this.searchEntity();
    }, 500);
  }

  onBulkDataDeleteEvent(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
      countryCode: country[0].countryCode,
      entityIds: event
    };
    this.driverService.deleteEntityActionStatus(requestBody).subscribe((response) => {
      this.cs.onDisplayMessage(this.attributeData, 'driver', AppSettings.MSG_ACTION.BULK_DELETE, 'success');
      this.searchEntity();
      this.getEntityCount();
    });
  }

}
