import { JsonPipe, NgClass } from '@angular/common';
import { Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import type { Country } from 'app/modules/shared/models/country';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import * as _ from 'lodash';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../../services/common-binding-data.service';
import { FilterService } from '../../../advance-filter-form/filter.service';

@Component({
  selector: 'app-mi-entity-auto-complete',
  standalone: true,
  imports: [AutoCompleteModule, FormsModule, ReactiveFormsModule, TranslateModule, InputTextModule, JsonPipe, CheckboxModule, DividerModule, NgClass, ButtonModule],
  templateUrl: './mi-entity-auto-complete.component.html',
  styleUrl: './mi-entity-auto-complete.component.scss'
})
export class MiEntityAutoCompleteComponent {

  checked = [];
  @Input() attributeValues;
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  formName: FormGroup;
  searchValue: string = '';
  selectedControl: string[] = [];
  inputPlaceholder: string;
  filteredValues: any[] = [];
  suggestions: any[] | undefined;
  selectedItems: any = [];
  visibleItems: any = [];
  items: any[] | undefined;
  country: Country;
  ids = [];
  dist
  entityName = computed(() => this.filterService.currentEntityName);
  distinctAPI = ["features", "variant", "state", "city"];
  searchQuery: any;
  apiModuleName;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private configService: ConfigService,
    private filterService: FilterService) {
    this.formName = this.formGroupDirective.control;
  }

  search(event: AutoCompleteCompleteEvent) {
    this.searchQuery = event.query;
    const entityActions = ['active_vehicle_id', 'default_vehicle_id', 'driver', 'booking_for']

    let viewCode = this.field.attributeCode.toUpperCase() + '_' + 'AUTOCOMPLETE_VIEW'
    if (entityActions.includes(this.field.attributeCode)) {
      viewCode = 'AUTOCOMPLETE_VIEW';
    }

    const assignedAttributeCodes = ['active_vehicle_id'];
    const activeAttributeCodes = ['driver'];
    let actionStatus = '';
    if (assignedAttributeCodes.includes(this.field.attributeCode)) {
      actionStatus = 'ASSIGNED'
    }
    if (activeAttributeCodes.includes(this.field.attributeCode)) {
      actionStatus = 'Active'
    }

    this.fetchFromAPI(viewCode, event.query, actionStatus);
  }

  onSearchClear() {
    this.searchQuery = '';
    this.selectedItems = [];
  }

  onSelectItem(event: any) {
    const items = this.selectedItems.filter(item => typeof item === 'string');
    items.push(event.value);
    this.selectedItems = _.uniq(items);
    this.visibleItems.push(...this.selectedItems);
    if (this.distinctAPI.includes(this.field.attributeCode)) {
      this.ids.push(this.selectedItems[0].value)
    } else {
      this.ids.push(this.selectedItems[0].id)

    }
    this.formName.controls[this.field.attributeCode].setValue(this.ids);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    this.selectedItems = [];
    this.searchQuery = '';

    // const prevData = this.filterService.getFilterState(this.entityName());
    // console.log( prevData?.apiData, this.visibleItems)
    // this.filterService.setFilterState(this.entityName(), {
    //   apiData: prevData?.apiData || {},
    //   userData: [{ [this.field.attributeCode]: this.visibleItems }],
    //   selectedFilterCount: prevData?.selectedFilterCount || 0 
    // });
  }


  fetchFromAPI(viewCode: string, search: string, actionStatus: string) {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      viewCode: viewCode,
      countryCode: this.country[0].countryCode,
      forTenantCode: this.configService.getForTenantCode(),
      searchText: search,
      actionStatus: actionStatus,
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
    }
    const attributeCodes = ['active_vehicle_id', 'default_vehicle_id']

    if (this.field.attributeCode === 'checkin_location_id') {
      this.apiModuleName = AppSettings.ENTITY_CODE.LOCATION
    }
    if (this.field.attributeCode === 'driver_group_id') {
      this.apiModuleName = AppSettings.ENTITY_CODE.DRIVER_GROUP
    }
    if (attributeCodes.includes(this.field.attributeCode)) {
      this.apiModuleName = AppSettings.ENTITY_CODE.VEHICLE
    }
    if (this.field.attributeCode === 'driver') {
      this.apiModuleName = AppSettings.ENTITY_CODE.DRIVER
    }

    if (this.field.attributeCode === 'booking_for') {
      this.apiModuleName = AppSettings.ENTITY_CODE.PASSENGER
    }



    if (this.distinctAPI.includes(this.field.attributeCode)) {
      const payload = {
        forTenantCode: this.configService.getForTenantCode(),
        attributeCode: this.field.attributeCode,
        filterStr: search
      }

      this.entityService.distinctValues(AppSettings.ENTITY_CODE[this.apiModuleName.toUpperCase()], payload).subscribe(res => {
        this.items = res;
      });
    } else {
      this.entityService.autoComplete(AppSettings.ENTITY_CODE[this.apiModuleName.toUpperCase()], requestBody).subscribe(res => {
        this.items = this.cs.getTableData(res) || [];
      });

    }


  }

  removeSelected(index: number): void {
    this.visibleItems.splice(index, 1);
    this.ids.splice(index, 1);
  }

}
