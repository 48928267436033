import { Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { CommonModule, DatePipe } from '@angular/common';
import { MiMobileValueComponent } from 'app/modules/shared/ui-sharable/mi-mobile-value/mi-mobile-value.component';
import { DaysOfWeekComponent } from 'app/modules/shared/components/mi-fields/days-of-week/days-of-week.component';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';
import { TooltipModule } from 'primeng/tooltip';
import { TruncatePipe } from 'app/modules/shared/pipes/truncate.pipe';
import { GroupColorComponent } from 'app/modules/manage-groups/components/group-color/group-color.component';
import { CommaSpacePipe } from 'app/modules/shared/pipes/comma-space.pipe';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-driver-overview-details',
  standalone: true,
  imports: [ButtonModule, TranslateModule, DropdownModule, ChartModule, OverlayPanelModule, FormsModule, MiMobileValueComponent, DaysOfWeekComponent, MiImageContainerComponent, CommonModule, TooltipModule, TruncatePipe, GroupColorComponent, CommaSpacePipe, AccessProviderDirective],
  templateUrl: './driver-overview-details.component.html',
  styleUrl: './driver-overview-details.component.scss',
  providers: [DatePipe]
})
export class DriverOverviewDetailsComponent {
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() driverAttributeData: any;
  @Input() driverDetails: any;
  @Input() driverGroupList;

  dropDownItems: any[] = [];
  selectedItem: any = [];
  data: any;
  options: any;
  tab: any;
  details: any;
  otherDetails: any;
  addressInformation: any;
  backgroundCheckInfo: any;
  availabilityPreference: any;
  drivingRecords: any;
  driverDrivingRecords;
  formattedDriverDrivingRecords: any[];
  map: google.maps.Map;
  driverId: string;
  driverProfileImage: any;
  dateOfBirth: any;
  startTime: any;
  endTime: any;
  daysOfWeek: number[] = [];
  dayNames: string[] = [];
  countryCodeForMobileNumber: any;
  mobileNumber: any;
  emergencyContact: any;
  countryCodeForEmergencyContact: any;
  countryCodeWithName = AppSettings.COUNTRY_CODE_WITH_COUNTRY_NAME;
  HOUR_MINUTE_FORMAT: any = AppSettings.HOUR_MINUTE_FORMAT;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public cs: CommonBindingDataService,
    private driverService: EntityService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit() {
    this.driverId = this.route.snapshot.paramMap.get('id');
    this.tab = this.driverAttributeData.tabs.find(ele => ele.tabCode === 'overview');
    this.details = this.tab.groups.find(ele => ele.code === 'details');
    this.otherDetails = this.tab.groups.find(ele => ele.code === 'other_details');
    this.addressInformation = this.tab.groups.find(ele => ele.code === 'address_information');
    this.backgroundCheckInfo = this.tab.groups.find(ele => ele.code === 'background_check');
    console.log(this.backgroundCheckInfo);
    this.availabilityPreference = this.tab.groups.find(ele => ele.code === 'availabilty_preference');
    this.drivingRecords = this.driverDetails.relatedData.filter(record => record.entityCode === AppSettings.ENTITY_CODE.DRIVER_DRIVING_RECORD);
    this.driverDrivingRecords = this.drivingRecords.map(record => ({
      Record: record.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'record').attributeValue,
      Date: record.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'date').attributeValue,
      Note: record.attributeCodeValueDtoList.find(attr => attr.attributeCode === 'note').attributeValue,
    }));
    this.formattedDriverDrivingRecords = this.formatDates(this.driverDrivingRecords);
    if (this.attributeValues[AppSettings.DRIVER_PROFILE_IMAGE_ATTRIBUTE_CODE]) {
      this.getDriverProfileImage();
    }
    const address = this.attributeValues[AppSettings.DRIVER_ATTRIBUTES.DRIVER_GEOLOCATION];
    this.cs.getLatLngFromAddress(address, AppSettings.GOOGLE_API_KEY).subscribe((result: any) => {
      this.setLocationOnMap(result.results, address);
    })
    this.setDateOfBirth();
    this.setStartTime();
    this.setEndTime();
    this.setDaysOfWeek();
    setTimeout(() => {
      this.getFlagsForMobileNumber();
      this.getFlagsForEmergencyNumber();
    }, 200);
    this.dropDownItems = [
      { name: 'All Time', code: '' },
      { name: '1', code: '1' },
      { name: '2', code: '2' },
      { name: '3', code: '3' },
      { name: '4', code: '4' }
    ];
    this.setChartData();
    this.initMap();
  }

  async setLocationOnMap(result, address) {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: result[0]?.geometry?.location,
      zoom: 14,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: AppSettings.MAP_ID,
    });
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: result[0]?.geometry?.location,
    });
    this.map.panTo(result[0]?.geometry?.location);
  }

  formatDates(records: any[]): any[] {
    return records.map(record => ({
      ...record,
      Date: this.datePipe.transform(new Date(record.Date), AppSettings.DATE_OF_BIRTH_FORMAT)
    }));
  }

  setDateOfBirth() {
    if (this.attributeValues[AppSettings.DRIVER_DATE_OF_BIRTH_ATTRIBUTE_CODE]) {
      const date = new Date(this.attributeValues[AppSettings.DRIVER_DATE_OF_BIRTH_ATTRIBUTE_CODE]);
      this.dateOfBirth = this.datePipe.transform(date, AppSettings.DATE_OF_BIRTH_FORMAT);
    } else {
      this.dateOfBirth = this.cs.getLabel('lbl_empty')
    }
  }

  setStartTime() {
    if (this.attributeValues[AppSettings.DATE_ATTRIBUTE_IDS.START_TIME]) {
      const date = new Date(this.attributeValues[AppSettings.DATE_ATTRIBUTE_IDS.START_TIME]);
      this.startTime = this.datePipe.transform(date, AppSettings.HOUR_MINUTE);
    }
  }

  setEndTime() {
    if (this.attributeValues[AppSettings.DATE_ATTRIBUTE_IDS.END_TIME]) {
      const date = new Date(this.attributeValues[AppSettings.DATE_ATTRIBUTE_IDS.END_TIME]);
      this.endTime = this.datePipe.transform(date, AppSettings.HOUR_MINUTE);
    }
  }

  setDaysOfWeek() {
    const dayMapping: { [key: number]: string } = {
      1: 'MON',
      2: 'TUE',
      3: 'WED',
      4: 'THU',
      5: 'FRI',
      6: 'SAT',
      7: 'SUN'
    };
    if (this.attributeValues[AppSettings.DAYS_OF_WEEK_ATTRIBUTE_ID]) {
      this.daysOfWeek = this.attributeValues[AppSettings.DAYS_OF_WEEK_ATTRIBUTE_ID];
      this.dayNames = this.daysOfWeek.map(day => dayMapping[day]);
    } else {
      this.dayNames = this.cs.getLabel('lbl_empty')
    }
  }

  getFlagsForMobileNumber() {
    const findFlag = (flagCode: string) =>
    this.countryCodeWithName.find(ele => ele.countryCode === flagCode)?.code;
    const mobileNumber = this.findAttributeValue(AppSettings.MOBILE_NUMBER_ATTRIBUTE_CODE);
    if (mobileNumber?.includes('-')) {
      const parts = mobileNumber.split('-');
      const flag1 = findFlag(parts[0]);
      const flag2 = findFlag(parts[1]);
      this.countryCodeForMobileNumber = flag1 || null;
      this.mobileNumber = flag2 || parts[1] || null; 
    } else {
      this.mobileNumber = mobileNumber;
    }
  }

  getFlagsForEmergencyNumber() {
    const findFlag = (flagCode: string) =>
      this.countryCodeWithName.find(ele => ele.countryCode === flagCode)?.code;
      const emergencyContact = this.findAttributeValue(AppSettings.EMERGENCY_CONTACT_ATTRIBUTE_CODE);
      if (emergencyContact?.includes('-')) {
        const parts = emergencyContact.split('-');
        const flag1 = findFlag(parts[0]);
        const flag2 = findFlag(parts[1]);
        this.countryCodeForEmergencyContact = flag1 || null;
        this.emergencyContact = flag2 || parts[1] || null; 
      } else {
        this.emergencyContact = emergencyContact;
      }
  }

  findAttributeValue(attributeCode: string) {
    return this.driverDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;
  }

  getDriverProfileImage() {
    this.driverService.getFile(this.attributeValues[AppSettings.DRIVER_PROFILE_IMAGE_ATTRIBUTE_CODE], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'driver');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.driverProfileImage = base64data;
      }
    })
  }

  async initMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.50932924799519, lng: 74.32022387996037 },
      zoom: 8,
    });
  }

  setChartData() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    this.data = {
      labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
      datasets: [
        {
          label: 'First Dataset',
          data: [15, 17, 19],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--blue-500'),
          backgroundColor: documentStyle.getPropertyValue('--blue-500'),
          tension: 0.4,
          showLine: false
        },
        {
          label: 'Second Dataset',
          data: [16, 18, 20],
          fill: false,
          borderColor: documentStyle.getPropertyValue('--pink-500'),
          backgroundColor: documentStyle.getPropertyValue('--pink-500'),
          tension: 0.4,
          showLine: false
        }
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            color: textColorSecondary
          },
          grid: {
            color: surfaceBorder,
            drawBorder: false
          }
        }
      }
    };
  }

  edit() {
    this.router.navigate(['app/drivers/add/' + this.driverId,]);
  }

  scrollToTop() {
    const tabviewPanels = document.querySelector('.p-tabview-panels');
    if (tabviewPanels) {
      tabviewPanels.scrollTop = 0;
    }
  }
}
