import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { CheckboxModule } from 'primeng/checkbox';
import { TranslateModule } from '@ngx-translate/core';
import { JsonPipe } from '@angular/common';
import { EntityService } from 'app/modules/shared/services/entity.service';

@Component({
  selector: 'app-mi-checkbox',
  templateUrl: './mi-checkbox.component.html',
  styleUrls: ['./mi-checkbox.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CheckboxModule, TranslateModule, JsonPipe]
})
export class MiCheckboxComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  selectedCategories: any;

  constructor(private formGroupDirective: FormGroupDirective,
    private entityService: EntityService,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.setSeededNumberFieldHiddenOnBasisOfSeededPlayerFieldValue()
  }

  setSeededNumberFieldHiddenOnBasisOfSeededPlayerFieldValue() {
    if (this.field.attributeCode === 'seeded_player') {
      const element = document.getElementById('seed_number');
      element.style.display = this.formName.controls['seeded_player'].value ? 'block' : 'none';
    }
  }

  onCheckboxChange() {
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.selectedCategories);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }

  onCheckBoxValueChange(event) {
    console.log(event);
    if (event.checked && this.field.attributeCode === 'default_address') {
      this.entityService.defaultAddressSelection.next(this.field);
    }

    if (!event.checked && this.field.attributeCode === 'seeded_player') {
      this.formName.controls['seed_number'].setValue(null);
      const element = document.getElementById('seed_number');
      element.style.display = 'none';
    } else {
      const element = document.getElementById('seed_number');
      element.style.display = 'block';
    }
  }
}
