import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MenuItem } from "primeng/api";
import { AppIcons } from "../../../shared/app.icons";
import { FormsModule } from "@angular/forms";
import { DropdownModule } from "primeng/dropdown";
import { MenubarModule } from "primeng/menubar";
import { TabMenuModule } from "primeng/tabmenu";
import { ActivatedRoute, Router } from "@angular/router";
import { OverlayPanel, OverlayPanelModule } from "primeng/overlaypanel";
import { CheckboxModule } from "primeng/checkbox";
import { InputSwitchModule } from "primeng/inputswitch";
import { FullScreenService } from "../../services/full-screen-service";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";
import { NgClass } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ManageWidgetsComponent } from "../manage-widgets/manage-widgets.component";

interface LiveDashboardMenu {
  label: string;
  routerLink: string;
  icon?: string;
}

interface DashboardCards {
  label: string;
  value: string;
  activeIndex: number;
  actualIndex: number;
  height: string;
  uiColumns: number;
}

@Component({
  selector: "app-dashboard-navbar",
  templateUrl: "./dashboard-navbar.component.html",
  styleUrls: ["./dashboard-navbar.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    FormsModule,
    DropdownModule,
    MenubarModule,
    TabMenuModule,
    OverlayPanelModule,
    CheckboxModule,
    InputSwitchModule,
    NgClass,
    TranslateModule,
    ManageWidgetsComponent
  ],
})
export class DashboardNavbarComponent implements OnInit {
  @ViewChild('manageWidgets') manageWidgetsComponent: ManageWidgetsComponent;
  routePath: MenuItem[];
  liveDashboardMenu: LiveDashboardMenu[] = [];
  selectedMenuItem: LiveDashboardMenu;
  liveDashboardCards: DashboardCards[] = [];
  dashboardCards: DashboardCards[] = [];
  manageWidgetCards: DashboardCards[] = [];
  menuItemsStart: MenuItem[] = [];
  menuItemsEnd: MenuItem[] = [];
  reorganizeAutomatically;
  latestMeterReadings;
  vehicleAssignments;
  vehicleLocations;
  vehicleStatus;
  @Input() includeRearrange: boolean = false;
  @Input() includeManageWidgets: boolean = false;
  @ViewChild("overlayPanel") overlayPanel: OverlayPanel;
  isFullScreen: boolean = false;
  overlayPanelToggle;
  showManageWidgets: boolean = false;
  currentUrl;

  constructor(
    private router: Router,
    private fullScreenService: FullScreenService,
    private cs: CommonBindingDataService,
  ) { }

  ngOnInit() {
    this.updateFullScreen();
    this.setMenuItems();
  }

  updateFullScreen() {
    this.fullScreenService.isFullScreen$.subscribe((isFullScreen) => {
      this.isFullScreen = isFullScreen;
      this.updateMenuItemsEnd();
    });
  }

  setMenuItems() {
    const dashboardBasePath = "/app/dashboard";
    this.routePath = [
      {
        label: this.cs.getLabel('sidebar_lbl_dashboard'),
        routerLink: "dashboard",
      }
    ];

    this.liveDashboardMenu = [
      {
        label: this.cs.getLabel('dashboard.live_dashboard'),
        routerLink: dashboardBasePath,
        icon: './assets/images/mi-theme/light/basic/green-pulse.gif'
      },
      {
        label: this.cs.getLabel('sidebar_lbl_dashboard'),
        routerLink: `${dashboardBasePath}/vehicle`,
      },
      {
        label: this.cs.getLabel('sidebar_lbl_communication'),
        routerLink: `${dashboardBasePath}/communication`,
      },
    ];

    this.currentUrl = this.router.url;
    if (this.currentUrl === dashboardBasePath) {
      this.selectedMenuItem = this.liveDashboardMenu[0];
      this.setLiveDashboardCard();
    }
    else {
      this.selectedMenuItem = this.liveDashboardMenu[1];
      this.setDashboardCard();
    }

    this.menuItemsStart = [
      {
        label: this.cs.getLabel('dashboard.map'),
        routerLink: `${dashboardBasePath}/map`,
        icon: AppIcons.BOOKING_DROP + " wh-18",
      },
      {
        label: this.cs.getLabel('dashboard.heat_map'),
        routerLink: `${dashboardBasePath}/heat-map`,
        icon: AppIcons.DASHBOARD_HEAT_MAP + " wh-16",
      },
      {
        label: this.cs.getLabel('dashboard.activity_feed'),
        routerLink: `${dashboardBasePath}/activity-feed`,
        icon: AppIcons.DASHBOARD_ACTIVITY_FEED + " wh-16",
      },
    ];
  }

  setLiveDashboardCard() {
    this.manageWidgetCards = [];
    this.liveDashboardCards = [
      { label: this.cs.getLabel('dashboard.booking_status'), value: this.cs.getLabel('dashboard_card_ids.booking_status'), activeIndex: 0, actualIndex: 0, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.journey_status'), value: this.cs.getLabel('dashboard_card_ids.journey_status'), activeIndex: 1, actualIndex: 1, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.live_status'), value: this.cs.getLabel('dashboard_card_ids.live_status'), activeIndex: 2, actualIndex: 2, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.upcoming_journey'), value: this.cs.getLabel('dashboard_card_ids.upcoming_journey'), activeIndex: 3, actualIndex: 3, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.cancelled_journey'), value: this.cs.getLabel('dashboard_card_ids.cancelled_journey'), activeIndex: 4, actualIndex: 4, height: '225px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.drivers'), value: this.cs.getLabel('dashboard_card_ids.drivers'), activeIndex: 5, actualIndex: 5, height: '225px', uiColumns: 6 },
      { label: this.cs.getLabel('dashboard.live_journeys_by_type'), value: this.cs.getLabel('dashboard_card_ids.live_journey_by_types'), activeIndex: 6, actualIndex: 6, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.passenger_by_types'), value: this.cs.getLabel('dashboard_card_ids.passenger_by_types'), activeIndex: 7, actualIndex: 7, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.vehicles'), value: this.cs.getLabel('dashboard_card_ids.vehicles'), activeIndex: 8, actualIndex: 8, height: '225px', uiColumns: 6 },
      { label: this.cs.getLabel('dashboard.parking_and_queues'), value: this.cs.getLabel('dashboard_card_ids.parking_queues'), activeIndex: 9, actualIndex: 9, height: '225px', uiColumns: 6 },
    ];
    this.manageWidgetCards = this.liveDashboardCards;
  }

  setDashboardCard() {
    this.manageWidgetCards = [];
    this.dashboardCards = [
      { label: this.cs.getLabel('dashboard.vehicle_status'), value: this.cs.getLabel('dashboard_card_ids.vehicle_status'), activeIndex: 0, actualIndex: 0, height: '225px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.booking_status'), value: this.cs.getLabel('dashboard_card_ids.booking_status'), activeIndex: 1, actualIndex: 1, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.journey_status'), value: this.cs.getLabel('dashboard_card_ids.journey_status'), activeIndex: 2, actualIndex: 2, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.passenger_by_types'), value: this.cs.getLabel('dashboard_card_ids.passenger_by_types'), activeIndex: 3, actualIndex: 3, height: '467px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.assigned_vehicles'), value: this.cs.getLabel('dashboard_card_ids.assigned_vehicles'), activeIndex: 4, actualIndex: 4, height: '225px', uiColumns: 3 },
      { label: this.cs.getLabel('dashboard.cancelled_journey'), value: this.cs.getLabel('dashboard_card_ids.cancelled_journey'), activeIndex: 5, actualIndex: 5, height: '225px', uiColumns: 3 },
    ];
    this.manageWidgetCards = this.dashboardCards;
  }

  updateMenuItemsEnd() {
    this.menuItemsEnd = [
      {
        icon: !this.isFullScreen
          ? `${AppIcons.DASHBOARD_FULL_SCREEN} wh-28 mr-0`
          : `${AppIcons.DASHBOARD_SMALL_SCREEN} wh-28 mr-0`,
        styleClass: "bordered-menu-item-full-screen",
        command: () => this.onFullScreenButtonClick(),
      },
    ];

    // This will come in next phase
    // const conditions = [
    //   {
    //     condition: this.includeManageWidgets,
    //     menuItem: {
    //       label: "Manage Widgets",
    //       icon: AppIcons.DASHBOARD_MANAGE_COLUMN + " wh-20",
    //       styleClass: "bordered-menu-item",
    //       command: (event) => this.onManageWidgetsClick(event),
    //     },
    //   },
    //   {
    //     condition: this.includeRearrange,
    //     menuItem: {
    //       label: "Rearrange",
    //       icon: AppIcons.GRID_O + " wh-20",
    //       styleClass: "bordered-menu-item",
    //     },
    //   },
    // ];

    // conditions.forEach(({ condition, menuItem }) => {
    //   if (condition) {
    //     this.menuItemsEnd.unshift(menuItem);
    //   }
    // });
  }

  onFullScreenButtonClick() {
    this.fullScreenService.toggleFullScreen();
  }

  onManageWidgetsClick(event: any) {
    this.overlayPanelToggle = event.originalEvent;
    this.showManageWidgets = true;
    this.manageWidgetsComponent?.openOverlayPanel(event);
  }

  onMenuItemChange(menuItem: LiveDashboardMenu) {
    if (menuItem.routerLink) {
      this.router.navigate([menuItem.routerLink]);
    }
  }
}
