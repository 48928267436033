<div class="booking-assign-vehicle-sidebar">
  <p-sidebar [(visible)]="visibleAssignVehicle" [position]="'right'" (onHide)="onHide()" [modal]="true">
    <ng-template pTemplate="header" class="p-3">
      <div class="text-lg font-bold" translate>bookings.lbl_select_vehicle_transfer</div>
    </ng-template>
    <ng-template pTemplate="content" class="p-3 pt-0">
      <div class="flex mb-2">
        <span class="p-input-icon-left">
          <i class="pi search-icon relative left-20 cursor-pointer"
            [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
            (click)="clearSearch()"></i>
          <input class="width-200 search-filter" type="text" pInputText (input)="onSearch($event)"
            [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue" />
        </span>
        @if(filterList.length > 0){
          <app-quick-filter-form [fields]="filterList" [moduleName]="moduleName" class="relative mr-2"
            [attributeLabels]="attributeLabels" (onValueChange)="onFilterValueChange(false, $event)">
          </app-quick-filter-form>
        }
      </div>
      
      <div class="vehicle-list-container">
        @if(loading){
          <div class="loader">
            <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
          </div>
        }
        @for(data of vehicleList; track data; let i = $index) {
          <div class="flex justify-content-between align-items-center w-full list-row">
            <div class="flex align-items-center">
              <p-radioButton name="data" [inputId]="'vehicle' + i" [value]="data?.id" class="flex ml-2" [(ngModel)]="selectedModify"></p-radioButton>
              <div class="flex flex-column ml-3" [attr.htmlFor]="'vehicle' + i">
                <div class="vehicle-name mb-2">
                  {{data?.name_code}} - {{data?.reg_number}}
                </div>
                <div class="vehicle-detail">
                  {{data?.vehicle_type}} | {{data?.manufacturer}} {{data?.fuel_type}} {{data?.year_of_manufacturing}} | {{data?.body_type}} | {{data?.passenger_capacity}} Seats
                </div>
              </div>
            </div>
            <div class="flex justify-content-end button-container">
              <button pButton class="bg-white default-text-black-color border-1 border-black-alpha-20 px-10px py-5px" (click)="assignVehicleData(data)" [label]="'bookings.lbl_select' | translate"></button>
            </div>
          </div>
        }

        @if (vehicleList?.length === 0) {
          <div class="w-full pt-0 pr-2 pb-0 pl-3">
            <app-no-result-found></app-no-result-found>    
          </div>         
        }
      </div>
    </ng-template>
  </p-sidebar>
  @if (visibleAssignVehicle) {
    <div class="backdrop" (click)="onHide()"></div>
  }
</div>