import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import type { Language } from 'app/modules/shared/models/language';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-input-int',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputNumberModule],
  templateUrl: './input-int.component.html',
  styleUrl: './input-int.component.scss'
})
export class InputIntComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  placeholder: any = null;
  unit: string = '';
  language: Language;
  languageCode: string;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService, private metaDataService: MetaDataService) {
    this.formName = this.formGroupDirective.control;
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.languageCode = this.language[0].langCode;
  }

  ngOnInit() {
    if (this.language && this.languageCode) {
      if (this.field) {
        const unit = this.metaDataService.systemOfMeasurement;
        const tempUnit = unit === 'Imperial' ? JSON.parse(this.field?.imperialUnit) : JSON.parse(this.field?.metricUnit);
        this.languageCode = this.language[0].langCode;
        if (tempUnit) {
          this.unit = tempUnit?.[this.languageCode];
        }
      }
    }
  }
}