<div class="main-settings">
    <p-tabView [scrollable]="true">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="{{miIcons.DASHBOARD_ACTIVITY_FEED}} inline-block wh-14 mr-2 relative"></i>
                <span translate>settings.lbl_notification_settings</span>
            </ng-template>
            <div class="flex justify-content-end flex-wrap btn-with-links">
                <button pButton class="bg-white default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="flex align-items-center justify-content-center" [label]="'lbl_save' | translate" (click)="onSaveNotification()"></p-button>
            </div>
            <div class="flex flex-wrap justify-content-center bg-white height-65px">
                <div class="flex-1 flex justify-content-start mt-3 ml-4px">
                    <span class="p-input-icon-left">
                        <i class="pi search-icon relative left-20 cursor-pointer"
                            [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
                            (click)="searchValue ? clearSearch() : null"></i>
                        <input class="width-200 search-filter" type="text" pInputText  (input)="onSearch($event)"
                            [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue"/>
                    </span>
                </div>
            </div>
                @for(module of modules; track module){
                    <div class="card background-not">
                        <div class="module-container">
                            <div class="grid mb-3 mt-1">
                                <div class="col-11 md:col-11 lg:col-11 flex align-items-center">
                                    <span [class]="module.moduleIcons + ' inline-block mr-2 relative'"></span>
                                    <span class="font-semibold font-18 default-text-black-color uppercase">{{ module.moduleName }}</span>
                                    @if(module.description) {
                                        <p class="font-14 default-print-label-color">{{ module.description }}</p>
                                    }
                                </div>
                                <div class="flex justify-content-center col-1 md:col-1 lg:col-1">
                                    <p-inputSwitch [(ngModel)]="module.enabledModule" (onChange)="onConfigUpdate($event, module.notificationModuleConfigId)"></p-inputSwitch>
                                </div>
                            </div>
                            <div class="grid bottom-border">
                                <div class="col-9 md:col-9 lg:col-9 font-semibold font-14 default-text-black-color" translate>settings.notification.lbl_notification_event</div>
                                <div class="col-3 md:col-3 lg:col-3">
                                    <div class="flex justify-content-center">
                                        <span class="checkbox-email col-4 md:col-4 lg:col-4 font-semibold font-14 default-text-black-color" translate>settings.notification.lbl_email_address</span>&nbsp;
                                        <span class="checkbox-email col-4 md:col-4 lg:col-4 font-semibold font-14 default-text-black-color" translate>settings.notification.lbl_push</span>&nbsp;
                                        <span class="checkbox-email col-4 md:col-4 lg:col-4 font-semibold font-14 default-text-black-color" translate>settings.notification.lbl_sms</span>&nbsp;
                                    </div>
                                </div>
                            </div>

                            @for(notification of module.notificationMatrix; track notification){
                                <div class="grid bottom-border">
                                    <div class="col-9 md:col-9 lg:col-9">
                                        <span class="font-semibold font-14">{{ notification.title }}</span>
                                        @if(notification?.placeHolder) {
                                            <p class="font-13 default-text-light-gray">{{ notification?.placeHolder }}</p>
                                        }
                                    </div>
                                    <div class="col-3 md:col-3 lg:col-3">
                                        <div class="grid">
                                            <label class="checkbox-email col-4 md:col-4 lg:col-4">
                                                <p-checkbox [checked]="notification.enabledEmail && module.enabledModule" [(ngModel)]="notification.enabledEmail" [binary]="true" [disabled]="!module.enabledModule"></p-checkbox>
                                            </label>
                                            <label class="checkbox-email col-4 md:col-4 lg:col-4">
                                                <p-checkbox [checked]="notification.enabledPush && module.enabledModule" [(ngModel)]="notification.enabledPush" [binary]="true" [disabled]="!module.enabledModule"></p-checkbox>
                                            </label>
                                            <label class="checkbox-email col-4 md:col-4 lg:col-4">
                                                <p-checkbox [checked]="notification.enabledSms && module.enabledModule" [(ngModel)]="notification.enabledSms" [binary]="true" [disabled]="!module.enabledModule"></p-checkbox>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            <div class="flex justify-content-end mr-17">
                <button pButton class="mr-2 mb-2 border-none bg-color-gray default-text-light-black-color border-button" [label]="'cancel' | translate" (click)="onCancel()"></button>
                <p-button class="mb-2 flex align-items-center justify-content-center" [label]="'lbl_save' | translate" (click)="onSaveNotification()"></p-button>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>
