import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TabMenuModule } from "primeng/tabmenu";
import { MenuItem } from "primeng/api";
import { DriverListCardComponent } from "./driver-list-card/driver-list-card.component";
import { Country } from "../../../shared/models/country";
import { Language } from "../../../shared/models/language";
import { EntityService } from "../../../shared/services/entity.service";
import { ConfigService } from "../../../shared/services/config.service";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { AppSettings } from "../../../shared/app.settings";
import * as _ from 'lodash';
import { NoResultFoundComponent } from "app/modules/shared/components/no-result-found/no-result-found.component";
import { BookingService } from "app/modules/bookings/services/booking.service";
import { DispatchMapComponent } from "../dispatch-map/dispatch-map.component";

@Component({
  selector: "app-assign-driver",
  templateUrl: "./assign-driver.component.html",
  styleUrls: ["./assign-driver.component.scss"],
  standalone: true,
  imports: [TabMenuModule, DriverListCardComponent, NoResultFoundComponent],
})

export class AssignDriverComponent implements OnInit {
  @ViewChild(DispatchMapComponent) dispatchMapComponent: DispatchMapComponent;
  
  @Output() showDriversDetails: EventEmitter<any> = new EventEmitter<any>();
  @Input() isBookingScreen: boolean = false;
  items: MenuItem[];
  driverList: any = [];
  country: Country;
  language: Language;
  entityData = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: ''
  };
  requestBodyForDispatchDriver: any = {
    forTenantCode: this.configService.getForTenantCode(),
    bookingId: '',
    wheelChair: false,
    childSeat: false,
    searchStr: '',
    // dutyStatus: '',
    // driverGroupId: '',
    // vehicleBodyType: '',
    // driverCurrentStatus: 'Available'
  };
  prevSearch;
  @Input() bookingId: string;
  advanceFilterAttributesForBooking: any;
  showDriverList: boolean = false;
  activeTabLabel: any;
  bookingStatuses: any = [];
  private loading = false;
  private maxDataReached = {
    entityDataForManageGroupDriver: false,
  };

  constructor(private entityService: EntityService,
    private configService: ConfigService,
    private bookingService: BookingService,
    private cs: CommonBindingDataService,) { }

  ngOnInit() {
    this.getAttributesForBooking();
    this.getBookingStatuses();
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.initializeTabs();
    this.requestBodyForDispatchDriver.forTenantCode = this.configService.getForTenantCode();
    this.requestBodyForDispatchDriver.bookingId = this.bookingId;
    this.requestBodyForDispatchDriver.wheelChair = false;
    this.requestBodyForDispatchDriver.childSeat = false;
    this.searchEntity();
    this.getDispatchDriverCount();
    
    setTimeout(() => {
      const cb = () => {
        if (!this.loading && !this.maxDataReached.entityDataForManageGroupDriver) {
          this.entityData.offset = this.entityData.offset + AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT;
          this.searchEntity();
        }
      };
      this.applyInfiniteScroll('.booking-detail-container', cb);
    }, 30);
  }

  getDispatchDriverCount() {
    this.bookingService.getDispatchDriverCount().subscribe({
      next: (result: any) => {
        this.items.forEach(element => {
          switch(element.label) {
            case AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.AVAILABLE : element.number = result?.Available ? result?.Available : 0; break;
            case AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.TOTAL : element.number = result?.All ? result?.All : 0; break;
            case AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.BUSY : element.number = result?.Busy ? result?.Busy : 0; break;
            case AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.IDLE : element.number = result?.Idle ? result?.Idle : 0; break;
            default: element.number = 0;
          }
        });
      }
    })
  }

  getBookingStatuses() {
    this.bookingService.getBookingStatuses().subscribe({
      next: (result) => {
        this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
        this.bookingStatuses = result;
        this.bookingStatuses.forEach(booking => {
          booking.bookingStatusDisplayStr = JSON.parse(booking.bookingStatusDisplayStr);
          booking.bookingStatusDisplayStr = booking.bookingStatusDisplayStr.filter(status => status.langId === this.language[0].languageId
            ,);
        });
      },
      error: (error) => {}
    });
  }

  applyInfiniteScroll(selector, onEndPageCallback) {
    const contentEl = document.querySelector(selector) as HTMLElement;
    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const scrollThreshold = heightDiff * 0.7;
      if (contentEl.scrollTop >= scrollThreshold && !this.loading) {
        onEndPageCallback();
      }
    };

    const checkNeedMoreDataImmediately = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      if (heightDiff <= 0 && !this.loading) {
        onEndPageCallback();
      }
    };
    contentEl.addEventListener("scroll", handleInfiniteScroll);
    setTimeout(() => checkNeedMoreDataImmediately(), 100);
  }

  initializeTabs() {
    this.items = [
      { label: AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.TOTAL, number: '' },
      { label: AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.AVAILABLE, number: 0 },
      { label: AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.BUSY, number: 0 },
      { label: AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.IDLE, number: 0 },
    ];
  this.activeTabLabel = AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.TOTAL;
  }

  ngDoCheck() {
    this.requestBodyForDispatchDriver.searchStr = this.cs.getSearchData();
    // this.requestBodyForDispatchDriver.tableViewCode = AppSettings.VIEW_CODE.DRIVER_NEXT_BOOKING_ON_DISPATCH_VIEW;
    if (this.requestBodyForDispatchDriver.searchStr !== this.prevSearch && (this.requestBodyForDispatchDriver.searchStr.length >= 3 || this.requestBodyForDispatchDriver.searchStr.length === 0)) {
      this.prevSearch = this.requestBodyForDispatchDriver.searchStr;
      this.searchEntity();
    }
  }

  handleDriverCardClicked(event) {
    const hasData = event !== null && event !== undefined;
    const eventData = hasData ? event : null;
    const emissionData = { hasData: hasData, eventData: event, bookingId: this.bookingId };
    this.showDriversDetails.emit(emissionData);
  }

  searchEntity() {
    if (this.loading) return;
    this.loading = true;
    this.bookingService.getDispatchDriverList(this.requestBodyForDispatchDriver).subscribe({
      next: (result: any) => {
        this.driverList = result;
        this.items.find(ele => ele.label === this.activeTabLabel).number = result.length;
        this.loading = false;
        this.driverList.forEach(driver => {
          if (driver.relatedData && driver.relatedData.length > 0) {
            const driverInfo = driver.relatedData.find(ele => ele.entityCode === AppSettings.ENTITY_CODE.DRIVER);
            if (driverInfo?.values?.driver_profile_image && driverInfo?.values?.driver_profile_image.length > 0) {
              this.getFile(driver, driverInfo?.values?.driver_profile_image[0])
            }
          }
          
        });
      },
      error: (error: any) => {
        this.loading = false;
      }
    });
  }

  getFile(driver, imageId) {
    this.entityService.getFile(imageId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], 'entity');
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        driver.profileImage = base64data;
      }
    })
  }

  getAttributesForBooking() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe({
      next: (result) => {
        this.advanceFilterAttributesForBooking = result;
        this.showDriverList = true;
      },
      error: (error) => {}
    });
  }

  tabMenuActiveItemChange(event) {
    this.requestBodyForDispatchDriver = {
      forTenantCode: this.configService.getForTenantCode(),
      bookingId: this.bookingId,
      wheelChair: false,
      childSeat: false,
      searchStr: '',
      driverCurrentStatus: event.label
    }
    this.activeTabLabel = event.label;
    if (event.label === AppSettings.DISPATCH_DRIVER_LIST_TAB_NAMES.TOTAL) {
      delete this.requestBodyForDispatchDriver.driverCurrentStatus
    }
    this.searchEntity();
  }
}
