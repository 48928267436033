<div class="flex flex-column">
  <p-tabMenu [model]="items" [(activeItem)]="activeItem" (activeItemChange)="onActiveItemChange($event)">
    <ng-template pTemplate="item" let-item>
          <div class="p-menuitem-link flex flex-row align-items-center cursor-pointer">
              <span class="font-bold font-13 number-padding {{item.label.toLowerCase()}}-number"> {{item.number}}</span>
              <span class="tab-label font-13"> {{ item.label }}</span>
          </div>
    </ng-template>
  </p-tabMenu>
  <div id="bookingContainer" class="booking-detail-container">
    @for(item of newBookingStatusList; track item; let i = $index){
      <app-booking-list-card [bookingStatuses]="bookingStatuses" [bookingListItem]="item" (bookingCardClicked)="handleBookingCardClicked($event)"></app-booking-list-card>
    }
    @if (newBookingStatusList?.length === 0) {
      <div class="w-full pt-0 pr-2 pb-0 pl-3">
        <app-no-result-found></app-no-result-found>    
      </div>         
  }
  </div>
</div>
