import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppSettings } from 'app/modules/shared/app.settings';
import { MiFieldsComponent } from 'app/modules/shared/components/mi-fields/mi-fields.component';
import { Country } from 'app/modules/shared/models/country';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';

@Component({
  selector: 'app-privilege-settings-assign-location',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MiFieldsComponent, TranslateModule, MultiSelectModule, CheckboxModule, CommonModule],
  templateUrl: './privilege-settings-assign-location.component.html',
  styleUrl: './privilege-settings-assign-location.component.scss'
})
export class PrivilegeSettingsAssignLocationComponent {
  @Input() group: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() attributeValues;
  @Input() miFormGroup: FormGroup;
  field;
  country: Country;
  locationAttributeData;
  attributeIdForLocation;
  data;
  pickupPresetValues;
  dropPresetValues;
  pickLocationValues;
  dropLocationValues;
  isPickupStayLocation: boolean = false;
  isPickupStayLocationChecked: boolean = false;
  pickupCheck: string[] = [];
  isDropStayLocation: boolean = false;
  isDropStayLocationChecked: boolean = false;
  dropCheck: string[] = [];
  selectedPickupStayLocation: any;
  selectedDropStayLocation: any;
  placeholder = this.cs.getLabel('lbl_please_select');
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'desc',
    forTenantCode: this.configService.getForTenantCode(),
    attributeCode: AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.LOCATION_CATEGORY
  };
  entityLocationData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
  };

  constructor(
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private formGroupDirective: FormGroupDirective,
    private settingsService: SettingsService,
    private changeDetectorRef: ChangeDetectorRef,
    private configService: ConfigService) {
      this.miFormGroup = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    if (this.group && this.group.fields && this.group.fields.length > 0) {
      this.group.fields.forEach(field => {
        this.field = field;
          if (field.attributeCode === 'predefined_location_category_for_pickup') {
              this.getLocationCategoryPickup();
          } else if (field.attributeCode === 'predefined_location_category_for_drop') {
              this.getLocationCategoryDrop();
          }
      });
    } else {
        console.error('Group or fields are not defined.');
    }
  }

  changedPickupMultiValue() {
    if (this.group && this.group.fields && this.group.fields.length > 0) {
      this.group.fields.forEach(field => {
        const pickupCheck = this.miFormGroup.controls['predefined_location_category_for_pickup'].value;
        const pickupCheckValue = pickupCheck || '';
        this.updatePickupCheck();
        if (pickupCheckValue?.includes('Stay Location')) {
          this.isPickupStayLocation = true;
        } else {
          this.isPickupStayLocation = false;
        }
      });
    } else {
        console.error('Group or fields are not defined.');
    }
  }

  updatePickupCheck() {
    const pickupCheck = this.miFormGroup.controls['predefined_location_category_for_pickup'].value;
    if (this.isPickupStayLocationChecked) {
      this.pickupCheck = pickupCheck.filter(item => item !== 'Stay Location');
    } else {
      this.pickupCheck = pickupCheck;
    }
    this.getAttributes(this.pickupCheck, '');
  }

  onPickupCheckboxChange(event: any) {
    this.isPickupStayLocationChecked = event.checked;
    this.updatePickupCheck();
  }

  changedDropMultiValue() {
    if (this.group && this.group.fields && this.group.fields.length > 0) {
      this.group.fields.forEach(field => {
        if(field.attributeCode === 'predefined_location_category_for_drop') {
          const dropCheck = this.miFormGroup.controls['predefined_location_category_for_drop'].value;
          const dropCheckValue = dropCheck || '';
          this.updateDropCheck();
          if (dropCheckValue?.includes('Stay Location')) {
            this.isDropStayLocation = true;
          } else {
            this.isDropStayLocation = false;
          }
        }
      });
    } else {
        console.error('Group or fields are not defined.');
    }
  }

  updateDropCheck() {
    const dropCheck = this.miFormGroup.controls['predefined_location_category_for_drop'].value;
    if (this.isDropStayLocationChecked) {
      this.dropCheck = dropCheck.filter(item => item !== 'Stay Location');
    } else {
      this.dropCheck = dropCheck;
    }
    this.getAttributes('', this.dropCheck);
  }

  onDropCheckboxChange(event: any) {
    this.isDropStayLocationChecked = event.checked;
    this.updateDropCheck();
  }

  getLocationCategoryPickup() {
    this.settingsService.searchAttributeSettings(AppSettings.ENTITY_TYPE.LOCATION, this.entityData).subscribe((res: ListResponse) => {
      if (res.data && Array.isArray(res.data)) {
        this.pickupPresetValues = res.data;
      }
    })
  }

  getLocationCategoryDrop() {
    this.settingsService.searchAttributeSettings(AppSettings.ENTITY_TYPE.LOCATION, this.entityData).subscribe((res: ListResponse) => {
      if (res.data && Array.isArray(res.data)) {
        this.dropPresetValues = res.data;
      }
    })
  }

  getAttributes(pickupCheck, dropCheck) {
    const entityType = AppSettings.ENTITY_TYPE.LOCATION;
    this.entityService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.setLocationView(res, pickupCheck, dropCheck);
      }
    });
  }

  setLocationView(res, pickupCheck, dropCheck) {
    this.data = res;
    this.locationAttributeData = this.cs.getOrganizedAttribute(this.data);
    this.attributeIdForLocation = this.getAttributeId('location_category', this.locationAttributeData);
    if(pickupCheck) {
      this.getPickupLocation(pickupCheck, this.attributeIdForLocation);
    }
    if(dropCheck) {
      this.getDropLocation(dropCheck, this.attributeIdForLocation);
    }
  }

  getAttributeId(attributeCode: string, locationAttributeData): number | undefined {
    for (const tab of locationAttributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getPickupLocation(pickupCheck, attributeIdForLocation) {
    this.entityLocationData.countryCode = this.country[0].countryCode;
    this.entityLocationData.filters = [{ attributeId: attributeIdForLocation, attributeValue: pickupCheck }];
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityLocationData).subscribe((res: any) => {
      if (res.data && Array.isArray(res.data)) {
        const locations = res.data.map(location => ({
          labelValue: location.values.location_display_name_for_booker,
          labelKey: location.values.location_display_name_for_booker,
        }));
        this.pickLocationValues = locations;
        this.changeDetectorRef.detectChanges();
      }
    })
  }

  getDropLocation(dropCheck, attributeIdForLocation) {
    this.entityLocationData.countryCode = this.country[0].countryCode;
    this.entityLocationData.filters = [{ attributeId: attributeIdForLocation, attributeValue: dropCheck }];
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityLocationData).subscribe((res: any) => {
      if (res.data && Array.isArray(res.data)) {
        const locations = res.data.map(location => ({
          labelValue: location.values.location_display_name_for_booker,
          labelKey: location.values.location_display_name_for_booker,
        }));
        this.dropLocationValues = locations;
        this.changeDetectorRef.detectChanges();
      }
    })
  }
}
