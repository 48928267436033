import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { AppSettings } from 'app/modules/shared/app.settings';
import * as _ from 'lodash';

@Component({
  selector: 'app-vehicle-tab',
  templateUrl: './vehicle-tab.component.html',
  styleUrl: './vehicle-tab.component.scss',
  standalone: true,
  imports: [NgClass, TranslateModule],
})
export class VehicleTabComponent {
  @Input() driverDetailItem: any;
  nameCode: any;
  groupName: any;
  passengerCapacity: any;
  vehicleType: any;
  bodyType: any;
  manufacturer: any;
  variant: any;
  yearOfManufacturing: any;
  vehicleDetails: any;
  constructor() { }

  ngOnInit(): void {
    console.log(this.driverDetailItem);
    if (this.driverDetailItem.driver.relatedData && this.driverDetailItem.driver.relatedData.length > 0) {
      this.vehicleDetails = this.driverDetailItem.driver.relatedData.find(ele => ele.entityCode === AppSettings.ENTITY_CODE.VEHICLE);
    }
  }
}
