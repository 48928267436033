import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ConfigService } from 'app/modules/shared/services/config.service';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';

@Component({
  selector: 'app-driver-document-entity',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, ToastModule],
  templateUrl: './driver-document-entity.component.html',
  styleUrl: './driver-document-entity.component.scss'
})
export class DriverDocumentEntityComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  @Output() previousClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() nextClick: EventEmitter<void> = new EventEmitter<void>();
  @Input() dataToChild;
  @Input() tab;
  @Input() activeIndex;
  @Input() attributeLabels = {};
  @Input() moduleName: string;
  @Input() submitBtnText: string;
  @Input() previousBtnText: string;
  @Input() attributeValues = [];
  @Input() attributeData: any;
  @Input() driverId;
  @Input() saveBtnId: string;
  routePath: MenuItem[] = [];
  data: AttributeData;
  driverAttributeData: any;
  country: Country;
  language: Language;
  cols: any = [];
  driverAttributeLength: number;
  driverDocumentId: string;
  driverDocument: any[] = [];
  driverDocumentFields: number = 6;
  start: number = 0;
  end: number = 3;
  prefixForOneToManyRelatedFields: any = '_';
  driverDocumentEntityIdList: any[] = [];
  attributeIdForDriverDocument;
  edit: boolean = false;
  driverData: any;
  driverDetails: any;
  isReady: boolean = false;
  driverDocumentPayload: any;
  entityId: string;
  imgFileId;
  driverDocumentToShow;
  documentTypeValue;
  uploadDocumentValue;
  relationshipDataForDriverDocument: any[] = [];
  removedDriverDocumentId;
  removedIndex;
  attributeIdForDriverEntityId: any;

  constructor(private driverService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    public appSettings: AppSettings,
    private configService: ConfigService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.setLabels();
    this.setDriverId();
    this.setCountryAndLanguage();
    this.loadDriverData();
    this.setRoutePath();
  }

  setLabels() {
    this.submitBtnText = this.cs.getLabel('lbl_save_driver');
    this.previousBtnText = this.cs.getLabel('lbl_previous');
  }

  setDriverId() {
    this.driverId = this.route.snapshot.paramMap.get('id');
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadDriverData() {
    if (this.driverId) {
      this.driverService
        .getEntity(this.driverId, AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res) => {
          if (res) {
            const data = res;
            this.driverDetails = res;
            this.attributeValues = this.cs.getOrgAttributeValues(data);
            this.parseAttributeValues();
            this.getDriverDocumentData(this.attributeValues);
          }
          this.getAttributes();
        });
    } else {
      this.getAttributes();
    }
  }

  getDriverDocumentData(attributeValues) {
    console.log(attributeValues);
    console.log(this.driverDetails);
    const attributeValueArray = attributeValues[AppSettings.GROUP_CODE.DOCUMENT_SECTION];
    for (const key in attributeValueArray) {
      if (Object.prototype.hasOwnProperty.call(attributeValueArray, key)) {
        const value = attributeValueArray[key];
        this.driverDocumentEntityIdList.push(value)
      }
    }
    const uniqueDriverDocumentEntityIdList = Array.from(new Set(this.driverDocumentEntityIdList));
    if (uniqueDriverDocumentEntityIdList.length > 0) {
      this.edit = true;
      let index = 1000;
      for (let i = 0; i < uniqueDriverDocumentEntityIdList.length; i++) {
        this.driverService.getEntity(uniqueDriverDocumentEntityIdList[i], AppSettings.ENTITY_CODE.DRIVER_DOCUMENT, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res: any) => {
          this.setAttributeValuesForDriverDocumentEditMode(res, index);
          index = index + 1;
        });
      }
    }
  }

  getAttributes() {
    this.attributeLabels = this.cs.getAttributeLabels(this.dataToChild);
    this.driverAttributeData = this.cs.getOrganizedAttribute(this.dataToChild);
    this.driverAttributeLength = this.driverAttributeData.tabs.length;
    this.attributeIdForDriverDocument = this.getAttributeIdForGroupCode('driver_document');
    this.isReady = true;
    this.attributeIdForDriverEntityId = this.getAttributeId('driver_entity_id');
  }

  getAttributeId(attributeCode: string): number | undefined {
    const driverDocument = _.find(this.driverAttributeData.relatedAttributes, { entityCode: 'driver_document' });
    for (const tab of driverDocument.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.driverAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('lbl_add_new_driver'),
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.driverId ? this.cs.getLabel('lbl_edit_driver') : this.cs.getLabel('driver.add'),
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  private parseAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_ISSUED_DATE,
      AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_EXPIRY_DATE
    ];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const driverDocumentIdArray = this.driverDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.GROUP_CODE.DOCUMENT_SECTION);
    this.driverDocumentEntityIdList = (driverDocumentIdArray && driverDocumentIdArray.attributeValue.length > 0) ? driverDocumentIdArray.attributeValue : [];
  }

  setAttributeValuesForDriverDocumentEditMode(data, index) {
    this.documentTypeValue = this.getAttributeValueByCode(data.attributeCodeValueDtoList, 'document_type');
    this.uploadDocumentValue = this.getAttributeValueByCode(data.attributeCodeValueDtoList, 'upload_document');
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.imgFileId = this.attributeValues[AppSettings.GROUP_CODE.DOCUMENT_SECTION];
      const uniqueImgFileId = Array.from(new Set(this.imgFileId));
      this.attributeValues[index + '_' + labelsObj.attributeCode] =
        (labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_ISSUED_DATE ||
          labelsObj.attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_EXPIRY_DATE) ? new Date(labelsObj.attributeValue) : labelsObj.attributeValue;
    }
  }

  getAttributeValueByCode(attributes: any[], code: string): any {
    const attribute = attributes.find(attr => attr.attributeCode === code);
    return attribute ? attribute : null;
  }

  onRemoveDriverDocumentEntity(event) {
    this.removedIndex = event[1];
    this.removedDriverDocumentId = typeof event[0] === 'string' ? event[0] : String(event[0]);
    this.driverDocumentEntityIdList = this.driverDocumentEntityIdList.filter(id => id !== this.removedDriverDocumentId);
    this.saveDriverDocumentData();
  }

  saveDocumentsData(event) {
    const driverFormData = event;
    this.driverData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };
    const removedDriverFormData = this.cs.removeDocumentByIndex(driverFormData, this.removedIndex);
    for (const [key, value] of Object.entries(removedDriverFormData)) {
      const attributeValue = value;
      if (key.includes(this.prefixForOneToManyRelatedFields)) {
        this.setAttributeValuesForDriverDocument(key, attributeValue);
      } else {
        this.setAttributeValuesForOtherDetails(key, attributeValue);
      }
    }
    this.saveDriverDocumentData();
  }

  private setAttributeValuesForDriverDocument(key, attributeValue) {
    const attributeCode = key.substring(key.indexOf('_') + 1);
    if (attributeValue) {
      const isExpiryDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_EXPIRY_DATE;
      const isIssuedDate = attributeCode === AppSettings.DATE_ATTRIBUTE_IDS.DRIVER_DOCUMENT_ISSUED_DATE;
      const value = isExpiryDate || isIssuedDate ? new Date(attributeValue).getTime() : attributeValue;
      this.driverDocument.push({ attributeCode, attributeValue: value });
    }
  }

  private setAttributeValuesForOtherDetails(key, attributeValue) {
    if (attributeValue) {
      this.driverData?.data?.push({
        'attributeCode': key,
        attributeValue
      });
    }
  }

  saveDriverDocumentData() {
    const { countryCode } = this.country[0] || {};
    const numberOfDriverDocument = this.driverDocument.length / this.driverDocumentFields;
    const uniqueDriverDocumentEntityIdList = Array.from(new Set(this.driverDocumentEntityIdList));
    this.start = 0;
    this.end = this.driverDocumentFields;
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.DRIVER_DOCUMENT
    }

    for (let i = 0; i < numberOfDriverDocument; i++) {
      const driverDocumentPayload = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: AppSettings.ENTITY_CODE.DRIVER_DOCUMENT,
        countryCode: countryCode,
        languageCode: this.language[0].langCode,
        data: this.driverDocument.slice(this.start, this.end),
      };
      driverDocumentPayload.data.push({
        attributeId: this.attributeIdForDriverEntityId,
        attributeValue: this.driverId
      });
      if (uniqueDriverDocumentEntityIdList.length > 0) {
        this.saveAttributeDataForDriverDocumentEditMode(driverDocumentPayload, uniqueDriverDocumentEntityIdList[i], i, numberOfDriverDocument, entitiesData);
      } else {
        this.createEntityForDriverDocument(entitiesData, driverDocumentPayload, i, numberOfDriverDocument);
      }
      this.start = this.end;
      this.end = this.end + this.driverDocumentFields;
    }

    if (numberOfDriverDocument === 0 && this.driverDocument.length === 0) {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
      this.router.navigate(['app/drivers/list']);
    }
  }

  createEntityForDriverDocument(entitiesData, driverDocument, index, numberOfDriverDocument) {
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe({
      next: (res: entityResponse) => {
        const entityId = res.entityId;
        this.entityId = res.entityId;
        this.driverDocumentEntityIdList.push(entityId);
        driverDocument.data = this.cs.mapAttributeIdsForRelatedData(driverDocument.data, this.attributeData.tabs);
        this.driverService.saveAttributeData(AppSettings.ENTITY_CODE.DRIVER_DOCUMENT, entityId, driverDocument).subscribe((res: any) => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel(res.message) });
        });
        const uniqueDriverDocumentEntityIdList = Array.from(new Set(this.driverDocumentEntityIdList));
        if (index === (numberOfDriverDocument - 1)) {
          this.driverData.data.push({
            attributeId: this.attributeIdForDriverDocument,
            attributeValue: uniqueDriverDocumentEntityIdList
          })
          this.saveDriverData(this.driverData);
        }
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel(error) });
      }
    });
  }

  saveAttributeDataForDriverDocumentEditMode(driverDocument, entityId, index, numberDriverDocumentData, entitiesData) {
    driverDocument.data = this.cs.mapAttributeIdsForRelatedData(driverDocument.data, this.attributeData.tabs);
    if (entityId) {
      this.driverService.saveAttributeData(driverDocument.entityCode, entityId, driverDocument).subscribe((res: any) => {
        this.relationshipDataForDriverDocument.push(
          {
            entityRelationshipConfigId: this.driverAttributeData.tabs[2].groups.find(ele => ele.code === 'driver_document').relation.entityRelationshipConfigId,
            otherEntityId: entityId
          }
        );
        if (index === (numberDriverDocumentData - 1)) {
          const uniqueDriverDocumentEntityIdList = Array.from(new Set(this.driverDocumentEntityIdList));
          this.driverData.data.push({
            attributeId: this.attributeIdForDriverDocument,
            attributeValue: uniqueDriverDocumentEntityIdList
          });
          this.saveDriverDocumentDataToDb();
        }
      });
    } else {
      this.createEntityForDriverDocument(entitiesData, driverDocument, index, numberDriverDocumentData);
    }
  }

  saveDriverDocumentDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.data.entityCode
    }
    this.driverData.relationshipData = this.relationshipDataForDriverDocument;
    if (this.edit) {
      this.saveAttributeData(entitiesData.entityCode)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode) {
    this.driverService.saveAttributeData(entityCode, this.driverId, this.driverData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
      this.router.navigate(['app/drivers/list']);
    });
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.driverService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.driverId = res.entityId;
      this.driverService.saveAttributeData(entitiesData.entityCode, this.driverId, this.driverData).subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('driver.message.add_driver') });
        this.router.navigate(['app/drivers/list']);
      });
    });
  }

  saveDriverData(driverData) {
    const { entityRelationshipConfigId, otherEntityId } = this.driverAttributeData?.tabs[2]?.groups.find(ele => ele.code === 'driver_document')?.relation || {};
    this.driverData.relationshipData = [];
    for (let index = 0; index < this.driverDocumentEntityIdList.length; index++) {
      this.driverData.relationshipData.push({ entityRelationshipConfigId, otherEntityId: this.driverDocumentEntityIdList[index] });
    }
    this.driverService.saveAttributeData(AppSettings.ENTITY_CODE.DRIVER, this.driverId, driverData).subscribe(() => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Successful', detail: this.cs.getLabel('driver.message.add_driver'), });
      this.router.navigate(['app/drivers/list']);
    });
  }

  onPreviousClick(event) {
    this.previousClick.emit();
  }

  onNextClick() {
    this.nextClick.emit();
  }

  getLastIndex(): number {
    return this.driverAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/drivers/list']);
  }
}
