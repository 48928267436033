<form [formGroup]="formName">
    @if(field?.presetValues && field.presetValues.length > 0) {
        <div class="preset-buttons">
            @for (preset of field.presetValues; track $index) {
                <span class="preset-button cursor-pointer uppercase" (click)="selectPresetValue(preset.labelValue)"
                    [ngClass]="{'selected': preset.labelValue === selectedPreset}">
                    @if(preset.labelValue === selectedPreset) {
                        <span class="{{miIcons.BASIC_CIRCLE_CHECK}} wh-20 inline-block top-5 relative"></span>
                    }
                    {{ preset.labelValue }}
                </span>
            }
        </div>
    }
    <input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" />
</form>