<div>
    <label class="w-full mt-0 uppercase text-lg font-semibold" translate>
        passengers.lbl_assign_location
    </label>
    <br />
</div>
  
@for (field of group.fields; track field.attributeCode; let j = $index) {
        <div class="col-12 flex">
            <div class="col-5 md:col-5 lg:col-5 horizontal relative right-15px">
                @if(field.attributeCode === 'predefined_location_category_for_pickup_check' || field.attributeCode === 'predefined_location_category_for_drop_check'){ 
                    <label class="hidden"></label>
                }
                @else {
                    <label class="flex font-semibold">
                        {{cs.getLabelValue(moduleName + '.fields.' + field.attributeCode + '.label', attributeLabels, field.attributeCode)}}
                    </label>
                }
            </div>
            <div class="col-5 md:col-5 lg:col-5 horizontal relative right-8">
                <form [formGroup]="miFormGroup">
                    <div class="flex align-items-start w-full">
                        <div class="flex align-items-start justify-content-start w-full">
                            @if(field.attributeCode === 'predefined_location_category_for_pickup') {
                                <p-multiSelect #miSelect
                                    class="w-full mb-2"
                                    [options]="pickupPresetValues"
                                    [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                                    [placeholder]="placeholder"
                                    optionValue="labelValue"
                                    optionLabel="labelValue"
                                    (onChange)="changedPickupMultiValue()">
                                </p-multiSelect>
                                <br>
                            }
                            @if(field.attributeCode === 'predefined_location_category_for_pickup_check') {
                                @if(isPickupStayLocation) {
                                    <div class="mb-3 checkbox-container">
                                        <p-checkbox
                                            [(ngModel)]="isPickupStayLocationChecked"
                                            [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                                            name="group"
                                            [binary]="true"
                                            (onChange)="onPickupCheckboxChange($event)"
                                            [label]="'lbl_show_own_stay_locations_only' | translate">
                                        </p-checkbox>
                                    </div>
                                }
                            }
                            @if(field.attributeCode === 'predefined_location_category_for_drop') {
                                <p-multiSelect #miSelect
                                    class="w-full mb-2"
                                    [options]="dropPresetValues"
                                    [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                                    [placeholder]="placeholder"
                                    optionValue="labelValue"
                                    optionLabel="labelValue"
                                    (onChange)="changedDropMultiValue()">
                                </p-multiSelect>
                                <br>
                            }
                            @if(field.attributeCode === 'predefined_location_category_for_drop_check') {
                                @if(isDropStayLocation) {
                                    <div class="mb-3 checkbox-container">
                                        <p-checkbox
                                            [(ngModel)]="isDropStayLocationChecked"
                                            [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                                            name="group"
                                            [binary]="true"
                                            (onChange)="onDropCheckboxChange($event)"
                                            [label]="'lbl_show_own_stay_locations_only' | translate">
                                        </p-checkbox>
                                    </div>
                                }
                            }
                            @if(field.attributeCode === 'location_for_pickup') {
                                <p-multiSelect #miSelect
                                    class="w-full"
                                    [options]="pickLocationValues"
                                    [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                                    [placeholder]="placeholder"
                                    optionValue="labelValue"
                                    optionLabel="labelValue">
                                </p-multiSelect>
                            }
                            @if(field.attributeCode === 'location_for_drop') {
                                <p-multiSelect #miSelect
                                    class="w-full"
                                    [options]="dropLocationValues"
                                    [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode"
                                    [placeholder]="placeholder"
                                    optionValue="labelValue"
                                    optionLabel="labelValue">
                                </p-multiSelect>
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
}
