<div class="grid pt-2 ml-2">
    <div class="flex flex-row align-items-center responsive-screen ml-1">
        @if(imageBase64) {
            <app-mi-image-container [miImage]="imageBase64" miHeight="53" miWidth="53" class="overview-image"></app-mi-image-container>
        }
        @else {
            <img class="status-img" [src]="defaultLoaderImg">
        }
    </div>
    <div class="col-10 lg:col-10 md:col-10 pl-2">
        <div class="initial-details mb-1">
            <label class="record-name">{{ entityForStatus?.entityName ? entityForStatus?.entityName : '--' }}</label>
            @if(entityForStatus?.entityStatus) {
                <div [class]="entityForStatus?.entityStatus?.toLowerCase()"></div>
                <label class="status">{{ entityForStatus?.entityStatus}}</label>
            }

        </div>
        <div class="other-details">
            @for (data of dataForStatus; track data; let i = $index) {
                @if (data.userId) {
                    <div class="user-info" >
                        <i [class]="data.icon"></i> <label class="user-name">{{ data.text }}</label>
                    </div>
                } @else {
                    @if (data.label) {
                        <label class="name">{{ data.label }} :</label>
                    }
                    <label class="value"> {{ data.text }}</label>
                    @if (i != dataForStatus.length - 1) {
                        
                        @if (i == 1){
                            <label class="separator">|</label>
                        }
                        
                        @else {
                            <label class="separator">|</label>
                        }
                    }


                }

            }
            @if (dataForVehicleStatus) {
                    @for (vehicleData of dataForVehicleStatus; track vehicleData; let i = $index) {
                        @if(vehicleData.vehicleType === 'type' || vehicleData.userId){
                            @if(vehicleData?.vehicleType === 'type'){
                                <label class="value mr-2"> {{ vehicleData.text }}</label> 
    
                                @if (i == 1){
                                    <label class="separator">|</label>
                                    <br />
                                }
                                
                                @else {
                                    <label class="separator">|</label>
                                }
                            }@else{
                                <div class="user-info align-items-center" >
                                    <i [class]="vehicleData.icon"></i> <label class="user-name mr-2">{{ vehicleData.text }}</label>
                                </div>
                            }
                        } @else {
                            @if (vehicleData.label) {
                                <label class="name">{{ vehicleData.label }} :</label>
                            }
                            <label class="value ml-1"> {{ vehicleData.text }}</label>
                            @if (i != dataForVehicleStatus.length - 1 && dataForVehicleStatus[i + 1].label !== '') {
                                
                                @if (i == 1){
                                    <label class="separator">|</label>
                                    <br />
                                }
                                
                                @else {
                                    <label class="separator">|</label>
                                }
                            }
        
                        }
        
                    }
            }
        </div>
    </div>
</div>
