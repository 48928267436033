import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from '../../../app.settings';
import { Language } from '../../../models/language';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';

@Component({
  selector: 'app-input-mileage',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, InputTextModule, InputNumberModule],
  templateUrl: './input-mileage.component.html',
  styleUrl: './input-mileage.component.scss'
})
export class InputMileageComponent implements OnInit {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  unit: string;
  languageCode: string;
  language: Language;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService, private metaDataService: MetaDataService) {
    this.formName = this.formGroupDirective.control;
  }
  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));    
    const unit = this.metaDataService.systemOfMeasurement;
    const tempUnit = unit === 'Imperial' ? JSON.parse(this.field?.imperialUnit) : JSON.parse(this.field?.metricUnit);
    this.languageCode = this.language[0].langCode;
    this.unit = tempUnit[this.languageCode];
  }
}
