
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { AppSettings } from '../../../shared/app.settings';
import { BasicTableComponent } from '../../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../../shared/components/mi-form/mi-form.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { AttributeData, entityResponse } from '../../../vehicles/models/attribute.models';
import { EntityService } from '../../../shared/services/entity.service';

import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { ConfigService } from '../../../shared/services/config.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';

@Component({
  selector: 'app-add-passengers',
  templateUrl: './add-passengers.component.html',
  styleUrls: ['./add-passengers.component.scss'],
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, BasicTableComponent, ButtonModule, TranslateModule, AccessProviderDirective]
})
export class AddPassengersComponent implements OnInit {
  activeIndex: number = 0;
  data: AttributeData;
  passengerAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  passengerAttributeLength: number | undefined;
  nextBtnLabel: string | undefined;
  previousBtnLabel: string | undefined;
  attributeValues: any[] = [];
  attributeValuesPrivilege;
  privilegeSettingsTab: any = AppSettings.TABS.PRIVILEGE_SETTINGS;
  passengerAddressEntityCode: any;
  passengerEntityId: any;
  addressEntityIdList: any[] = [];
  passengerData: any;
  privilegeData: any;
  passengerEntityData: any;
  stayHomeAddressData: any[] = [];
  prefixForOneToManyRelatedFields: any = '_';
  startIndexToPickOneRecordFromStayHomeAddressArray: any = 0;
  endIndexToPickOneRecordFromStayHomeAddressArray: any = 0;
  stayHomeAddressFields: any;;
  savePassengerLabel: any;
  edit: boolean = false;
  passengerDetails: any;
  addressPresetValues = [];
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST;
  attributeIdForStayHomeAddress: any;
  disableSaveBtn: boolean = false;
  selectLocationAddressAttribute: any;
  defaultAddressAttributeId: any;
  relationshipDataForAddress: any[] = [];
  saveUpdateBtnLabel: string;
  showAddress: boolean = false;
  passengerType;
  passengerTypeId;
  privilegeId;
  isData: boolean = true;
  isPrivilegeEdit: boolean = false;

  entityPassengerData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: '',
    tableViewCode: AppSettings.VIEW_CODE.ADD_EDIT_VIEW,
  }

  constructor(public passengerService: EntityService,
    private router: Router,
    public cs: CommonBindingDataService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private configService: ConfigService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.passengerEntityId = this.route.snapshot.paramMap.get('id');
    this.activeIndex = Number(this.route.snapshot.paramMap.get('index'));
    this.setLabels();
    this.setRoutePath();
    this.setAppSettings();
    this.loadAttributeData();
    this.cs.passengerType$.subscribe(type=> {
      this.passengerType = type;
      if(this.passengerType && this.passengerTypeId) {
        this.searchEntity(this.passengerType, this.passengerTypeId);
      }
    });
  }

  searchEntity(passengerType, passengerTypeId) {
    this.entityPassengerData.countryCode = this.country[0].countryCode;
    this.entityPassengerData.filters = [{attributeId: passengerTypeId, attributeValue: passengerType}];
    this.passengerService.searchEntity(AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, this.entityPassengerData).subscribe((res: ListResponse) => {
      if (res) {
        this.privilegeId = res.data[0].id;
        this.loadPrivilegeData();
      }
    });
  }

  private loadPrivilegeData() {
    if (this.privilegeId) {
      this.passengerService.getEntity(this.privilegeId, AppSettings.ENTITY_CODE.PASS_PRIVILEGE_CONF, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((res) => {
        if (res) {
          const data = res;
          this.attributeValuesPrivilege = this.cs.getOrgAttributeValues(data) as any;
          this.isData = false;
          this.cd.detectChanges();
          this.isData = true;
          this.parsePrivilegeAttributeValues();
        }
      });
    } else {
      // this.getAttributes();
    }
  }

  private setLabels() {
    this.nextBtnLabel = this.cs.getLabel('lbl_next');
    this.previousBtnLabel = this.cs.getLabel('lbl_cancel');
    this.savePassengerLabel = this.passengerEntityId ? this.cs.getLabel('label_update') : this.cs.getLabel('label_save');
    this.saveUpdateBtnLabel = this.passengerEntityId ? this.cs.getLabel('label_update') : this.cs.getLabel('label_save');
  }

  private setRoutePath() {
    this.routePath = [
      {
        label: this.passengerEntityId ? this.cs.getLabel('passengers.edit_passengers_lbl') : this.cs.getLabel('passengers.add_passengers_lbl'),
        routerLink: '/app/passengers/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px', 'color': '#495057' }
      },
      {
        label: this.activeIndex === 0 ? this.cs.getLabel('passengers.lbl_details') : this.cs.getLabel('passengers.lbl_privilege_settings'),
        routerLink: '../add',
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  private setAppSettings() {
    this.moduleName = AppSettings.ENTITY_TYPE.PASSENGER;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  private loadAttributeData() {
    forkJoin([
      this.passengerEntityId ? this.passengerService.getEntity(this.passengerEntityId, AppSettings.ENTITY_CODE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW) : of(null)
    ]).pipe(
      tap(([entityRes]) => {
        if (entityRes) {
          const data = entityRes;
          this.passengerDetails = entityRes;
          this.attributeValues = this.cs.getOrgAttributeValues(data);
          const key = 'passenger_type';
          this.passengerType = this.attributeValues[key];
          this.parseAttributeValues();
          this.parseAttributeValueForDateOfBirth();
          this.getStayHomeAddress();
          this.showAddress = true;
        } else {
          this.showAddress = true;
        }
        this.getAttributes(this.passengerType);
      })
    ).subscribe();
  }

  getAttributes(passengerType) {
      this.passengerService.getAttributeDefinition(AppSettings.ENTITY_TYPE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
        if (res) {
          this.setPassengerView(res, passengerType);
        }
      });
  }

  setPassengerView(res, passengerType) {
    this.data = _.cloneDeep(res);
    this.passengerAddressEntityCode = this.data.relations.find(ele => ele.otherEntityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS).otherEntityCode;
    this.attributeLabels = this.cs.getAttributeLabels(this.data);
    this.passengerAttributeData = this.cs.getOrganizedAttribute(this.data);
    this.passengerAttributeLength = this.passengerAttributeData.tabs.length;
    this.passengerTypeId = this.cs.getAttributeId('passenger_type', this.data);
    
    if(this.passengerEntityId) {
      this.attributeValuesPrivilege = this.attributeValues;
    } else {
      if(passengerType && this.passengerTypeId) {
        this.searchEntity(this.passengerType, this.passengerTypeId);
      }
    }
    this.setClassForTabPrivilegeSettings();
    this.attributeIdForStayHomeAddress = this.getAttributeIdForGroupCode(AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
    this.getLocationAddressAttribute();
    this.getNumberOfFieldsInAddressSection();
  }

  getNumberOfFieldsInAddressSection() {
    this.data.relatedAttributes.forEach(element => {
      if (element.entityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS) {
        let addressFields =  element.tabs[0].groups[0].fields;
        this.stayHomeAddressFields =  addressFields.filter(ele => ele.inputCode !== 'spacer').length;
      }
    });
  }

  getLocationAddressAttribute() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          this.selectLocationAddressAttribute = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
          this.defaultAddressAttributeId = group.fields.find(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.DEFAULT_ADDRESS)?.attributeId;
        }
      });
    });
    const stayHomeAddressGroup = this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS);
    const index = stayHomeAddressGroup.fields.findIndex(ele => ele.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS);
    stayHomeAddressGroup.fields.splice(index, 1);
  }

  private parsePrivilegeAttributeValues() {
    const dateAttributes = [
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE, 
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE, AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE
    ];
    dateAttributes.forEach(attr => {
      this.attributeValuesPrivilege[attr] = this.attributeValuesPrivilege[attr] ? new Date(this.attributeValuesPrivilege[attr]) : null;
    });
  }

  private parseAttributeValues() {
    const dateAttributes = [AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE, 
      AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE, 
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE, 
      AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });

    const addressIdArray = this.passengerDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === AppSettings.FIELDS.STAY_HOME_ADDRESS_SECTION);
    this.addressEntityIdList = (addressIdArray && addressIdArray.attributeValue.length > 0) ? addressIdArray.attributeValue : [];
  }

  private parseAttributeValueForDateOfBirth() {
    const dateAttributes = [AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH];
    dateAttributes.forEach(attr => {
      this.attributeValues[attr] = this.attributeValues[attr] ? new Date(this.attributeValues[attr]) : null;
    });
  }

  getStayHomeAddress() {
    if (this.passengerDetails.relatedData.length > 0) {
      this.defaultLocationList = [];
      this.passengerDetails.relatedData.forEach((data, index) => {
        if (data.entityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS) {
          const field = data.attributeCodeValueDtoList.find(element => element.attributeCode === AppSettings.FIELDS.SELECT_LOCATION_TYPE);
          const label = field ? field.attributeValue : '';
          this.defaultLocationList.push({ labelKey: label, labelValue: label, entityId: data.entityId, index: index });
          this.setAttributeValuesForStayHomeAddressEditMode(data, index);
        }
      });
    }
  }

  setAttributeValuesForStayHomeAddressEditMode(data, addressCount) {
    for (const labelsObj of data.attributeCodeValueDtoList) {
      this.attributeValues[addressCount + '_' + labelsObj.attributeCode] = labelsObj.attributeValue;
    }
  }

  setClassForTabPrivilegeSettings() {
    if (!this.passengerAttributeData || !this.passengerAttributeData.tabs) {
      return;
    }
    const privilegeTab = this.passengerAttributeData.tabs.find(tab => tab.tabCode === 'privilege_settings');
    if (!privilegeTab || !privilegeTab.groups) {
      return;
    }
    privilegeTab.groups.forEach(group => {
      group.activeStatus = (group.code === 'app_access') ? 'active' : 'inactive';
      group.activeLabel = (group.code === 'app_access') ? 'active' : 'inactive';
    });
  }

  scrollToGroup(event, groupCode) {
    if (event === 1) {
      document.getElementById('scrollable').scrollTo(0, 150);
    } else if (event === 0) {
      document.getElementById('scrollable').scrollTo(0, 0);
    }
    document.getElementById(event).scrollIntoView();
    this.setActiveClassForGroupPrivilegeSettings(groupCode);
  }

  setActiveClassForGroupPrivilegeSettings(groupCode) {
    const privilegeTab = this.passengerAttributeData.tabs.find(tab => tab.tabCode === 'privilege_settings');
    if (!privilegeTab || !privilegeTab.groups) {
      return;
    }

    privilegeTab.groups.forEach(group => {
      group.activeStatus = (group.code === groupCode) ? 'active' : 'inactive';
      group.activeLabel = (group.code === groupCode) ? 'active' : 'inactive';
    });
  }

  onSavePrivilegeSettings(event) {
    this.disableSaveBtn = true;
    setTimeout(() => {
      this.disableSaveBtn = false;
    }, 500);

    if (this.passengerEntityId) {
      this.privilegeData = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.data.entityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: []
      };
      const privilegeData = event;
      const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_START_DATE, 
        AppSettings.DATE_ATTRIBUTE_IDS.TRANSPORTATION_END_DATE, 
        AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_START_DATE, 
        AppSettings.DATE_ATTRIBUTE_IDS.APP_ACCESS_END_DATE];
      for (const [key, value] of Object.entries(privilegeData)) {
        let attributeValue = value;
        if (dateIds.includes(key)) {
          attributeValue = new Date(<string>value).getTime();
        }
        this.setAttributeValuesForPrivilegeSetting(key, attributeValue);
      }
      this.privilegeData.data = this.cs.mapAttributeIds(this.privilegeData.data, this.passengerAttributeData.tabs);

      this.savePrivilegeDataToDb();
    } else {
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('passengers.please_fill_mandatory_data_in_details_tab') });
      this.activeIndex = 0;
      this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
    }
  }

  setAttributeValuesForPrivilegeSetting(key, attributeValue) {
    if (attributeValue) {
      this.privilegeData.data.push({
        attributeCode: key,
        attributeValue
      });
    }
  }

  savePrivilegeDataToDb() {
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.moduleName
    }
    if (this.passengerEntityId) {
      this.saveAttributeData(entitiesData.entityCode)
    } else {
      this.createEntityAndUpdateAttributeData(entitiesData);
    }
  }

  saveAttributeData(entityCode) {
    this.passengerService.saveAttributeData(entityCode, this.passengerEntityId, this.privilegeData).subscribe((res: any) => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.privilege_data_updated_successfully') });
      setTimeout(() => {
        this.router.navigate(['app/passengers']);
      }, 300);
    });
  }

  createEntityAndUpdateAttributeData(entitiesData) {
    this.passengerService.createEntities(entitiesData.entityCode, entitiesData).subscribe((res: entityResponse) => {
      this.passengerEntityId = res.entityId;
      this.passengerService.saveAttributeData(entitiesData.entityCode, this.passengerEntityId, this.privilegeData).subscribe((res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('passengers.privilege_data_saved_successfully') });
        setTimeout(() => {
          this.router.navigate(['app/passengers']);
        }, 300);
      });
    });
  }

  onSavePassenger(event) {
    this.disableSaveBtn = true;

    if (!this.country || !this.language) {
      console.error('Data, country, or language not initialized.');
      return;
    }

    this.stayHomeAddressData = [];
    this.passengerData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.moduleName,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    const passengerFormData = event;
    for (let k = 0; k < this.defaultLocationList.length; k++) {
      passengerFormData[`${k}_${AppSettings.ADDRESS_FIELDS.SELECT_LOCATION_TYPE}`] = this.defaultLocationList[k].labelKey
    }
    const dateIds = [AppSettings.DATE_ATTRIBUTE_IDS.PASSENGER_DATE_OF_BIRTH];
    for (const [key, value] of Object.entries(passengerFormData)) {
      let attributeValue = value;
      if (dateIds.includes(key)) {
        attributeValue = new Date(<string>value).getTime();
      }
      const arr = key.split('_');
      if (parseInt(arr[0]) >= 0) {
        this.setAttributeValuesForStayHomeAddress(key, attributeValue);
      } else {
        this.setPassengerData(key, attributeValue);
      }
    }

    this.stayHomeAddressData = this.cs.mapAttributeIdsForRelatedData(this.stayHomeAddressData, this.passengerAttributeData.tabs);
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    this.setPassengerEntityData(this.moduleName);
    this.saveAddress();
  }

  setAttributeValuesForStayHomeAddress(key, attributeValue) {
    const keyId = key.substring(key.indexOf('_') + 1);
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_STAY_HOME_ADDRESS.includes(keyId)) {
      if (keyId === AppSettings.PASSENGER_ATTRIBUTE_CODE.SELECT_LOCATION_GEOLOCATION) {
        this.setStayHomeAddress(keyId, attributeValue?.geoLocation);
        this.stayHomeAddressData.push({
          attributeId: this.selectLocationAddressAttribute?.attributeId,
          attributeValue: attributeValue?.address
        });
      } else {
        this.setStayHomeAddress(keyId, attributeValue);
      }
    }
  }

  setStayHomeAddress(keyId, attributeValue) {
    this.stayHomeAddressData.push({
      attributeCode: keyId,
      attributeValue: keyId === AppSettings.FIELDS.INSTRUCTIONS ? (attributeValue ? attributeValue : '   ') : (attributeValue ? attributeValue : '')
    });
  }

  setPassengerData(key, attributeValue) {
    if (!AppSettings.SPACERS_ATTRIBUTE_CODES_FOR_PASSENGER_DETAILS.includes(key)) {
      if (key === 'seeded_player') {
        if (attributeValue) {
          this.passengerData.data.push({
            attributeCode: key,
            attributeValue: attributeValue.toString()
          });
        }
      } else if (key === 'seed_number') {
        if (attributeValue) {
          this.passengerData.data.push({
            attributeCode: key,
            attributeValue: Number(attributeValue)
          });
        }
      } else {
      if (attributeValue) {
        this.passengerData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
  }
}

  setPassengerEntityData(entityCode) {
    this.passengerEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: entityCode
    }
  }

  async saveAddress() {
    this.relationshipDataForAddress = [];
    const numberOfStayHomeAddressRecords = this.stayHomeAddressData.length / this.stayHomeAddressFields;
    this.startIndexToPickOneRecordFromStayHomeAddressArray = 0;
    this.endIndexToPickOneRecordFromStayHomeAddressArray = this.stayHomeAddressFields;
    const addressEntitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: this.passengerAddressEntityCode
    }
    
    for (let i = 0; i < numberOfStayHomeAddressRecords; i++) {
      const address = {
        forTenantCode: this.configService.getForTenantCode(),
        entityCode: this.passengerAddressEntityCode,
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        data: this.stayHomeAddressData.slice(this.startIndexToPickOneRecordFromStayHomeAddressArray, this.endIndexToPickOneRecordFromStayHomeAddressArray)
      };
      const defaultAddressIndex =   address.data.findIndex(ele => (ele.attributeId === this.defaultAddressAttributeId && (ele.attributeValue === '' || ele.attributeValue === false)));
      if (defaultAddressIndex !== -1) {
        address.data.splice(defaultAddressIndex, 1);
      }
      this.startIndexToPickOneRecordFromStayHomeAddressArray = this.endIndexToPickOneRecordFromStayHomeAddressArray;
      this.endIndexToPickOneRecordFromStayHomeAddressArray = this.endIndexToPickOneRecordFromStayHomeAddressArray + this.stayHomeAddressFields;
      
      if (this.addressEntityIdList.length > 0) {
        await this.saveAddressAttribute(numberOfStayHomeAddressRecords, i, address, this.addressEntityIdList[i], addressEntitiesData);
      } else {
        await this.createAndSaveAddressEntity(addressEntitiesData, i, numberOfStayHomeAddressRecords, address);
      }
    }
  }

  createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address) {
    this.passengerService.createEntities(addressEntitiesData.entityCode, addressEntitiesData).subscribe({
      next: (res: entityResponse) => {
        const entityId = res.entityId;
        this.addressEntityIdList.push(res.entityId);
        this.passengerService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
        });
          this.relationshipDataForAddress.push(
            {
              entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
              otherEntityId: entityId
            }
          );

        if (index === (numberOfAddress - 1)) {
          this.passengerData.data.push({
            attributeId: this.attributeIdForStayHomeAddress,
            attributeValue: this.addressEntityIdList
          });
          this.savePassengerToDb(this.passengerEntityData.entityCode)
        }

      },
      error: (error) => {
        console.error('something wrong occurred: ' + error);
      }
    });
  }

  async saveAddressAttribute(numberOfAddress, index, address, entityId, addressEntitiesData) {
    if (entityId) {
      await this.passengerService.saveAttributeData(addressEntitiesData.entityCode, entityId, address).subscribe((res: any) => {
          this.relationshipDataForAddress.push(
            {
              entityRelationshipConfigId: this.passengerAttributeData.tabs[0].groups.find(ele => ele.code === 'stay_home_address').relation.entityRelationshipConfigId,
              otherEntityId: entityId
            });

        if (index === (numberOfAddress - 1)) {
          this.passengerData.data.push({
            attributeId: this.attributeIdForStayHomeAddress,
            attributeValue: this.addressEntityIdList
          });
          this.savePassengerToDb(this.passengerEntityData.entityCode)
        }
      });
    } else {
      this.createAndSaveAddressEntity(addressEntitiesData, index, numberOfAddress, address);
    }

  }

  getAttributeIdForStayHomeAddress() {
    this.passengerAttributeData.tabs.forEach(tab => {
      tab.groups.forEach(group => {
        if (group.code === AppSettings.GROUP_CODE.STAY_HOME_ADDRESS) {
          this.attributeIdForStayHomeAddress = group.relation.ownerAttributeId;
        }
      });
    });
  }

  getAttributeIdForGroupCode(groupCode: string): string | undefined {
    const group = _.flatMap(this.passengerAttributeData.tabs, 'groups').find(group => group.code === groupCode);
    return group ? group.relation.ownerAttributeId : undefined;
  }


  savePassengerToDb(entityCode) {
    this.passengerData.relationshipData = this.relationshipDataForAddress;
    this.passengerData.data = this.cs.mapAttributeIds(this.passengerData.data, this.passengerAttributeData.tabs);
    if (this.passengerEntityId) {
      this.savePassengerApiCall(entityCode);
    } else {
      this.createPassengerEntity();
    }
  }

  savePassengerApiCall(entityCode) {
    this.passengerService.saveAttributeData(entityCode, this.passengerEntityId, this.passengerData).subscribe(result => {
      this.cs.onDisplayMessage(this.data, 'passenger', AppSettings.MSG_ACTION.ADD, 'success');
      setTimeout(() => {
        this.onNextClick();
      }, 500);
      this.disableSaveBtn = false;
    },
      (error) => {
        this.activeIndex--;
        this.disableSaveBtn = false;
        this.updateNextBtnLabel();
        this.displayErrors(error);

      })
  }

  displayErrors(error) {
    for (const [key, value] of Object.entries(error.errors)) {
      let messageObject: any = value;
      this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(this.attributeLabels[messageObject[0].messageCode]) });
    }
  }

  createPassengerEntity() {
    this.passengerService.createEntities(this.passengerEntityData.entityCode, this.passengerEntityData).subscribe({
      next: (res: entityResponse) => {
        this.passengerEntityId = res.entityId;
        this.savePassengerApiCall(this.passengerEntityData.entityCode);
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  onCancel() {
    this.router.navigate(['app/passengers']);
  }

  onNextBtnClick() {
    const btn = document.getElementById(this.activeIndex === 0 ? 'details' : 'privilegeSettings');
    btn.click();
  }

  onPreviousClick() {
    this.router.navigate(['app/passengers']);
  }

  onNextClick() {
    if (this.activeIndex < this.getLastIndex()) {
      this.activeIndex++;
    }
    this.updateNextBtnLabel();
  }

  updateNextBtnLabel() {
    this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
  }

  onTabViewChange(event) {
    this.setRoutePath();
    this.nextBtnLabel = this.isLastIndex() ? this.saveUpdateBtnLabel : this.cs.getLabel('lbl_next');
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }

  getLastIndex(): number {
    return this.passengerAttributeLength - 1;
  }
}
