import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../../../../shared/app.settings';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { DatePipe, NgStyle } from '@angular/common';

@Component({
  selector: 'app-overview-details',
  templateUrl: './overview-details.component.html',
  styleUrl: './overview-details.component.scss',
  standalone: true,
  imports: [ButtonModule, TranslateModule, ChartModule, FormsModule, NgStyle, DatePipe,],
  providers: [DatePipe],
})
export class OverviewDetailsComponent implements OnInit {
  iconPos: any = 'right';
  @Input() attributeValues: any;
  @Input() attributeLabels: any;
  @Input() incidentsComplaintsAttributeData: any;
  @Input() incidentsComplaintsDetails: any;
  @Input() actionStatus: string;
  @Input() title: string;
  @Input() summaryValue: string;
  @Input() journeyAttributes: any;

  selectedItem: any = [];
  data: any;
  options: any;
  tab: any;
  details: any;
  id: string = '12345'; //it will change once backend issue resolved and deployed
  incidentsComplaintHistoryAttributeData;
  attributeIdForIncidentComplaint;
  historyId;
  timelineItems;
  entityData = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.BOTH,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: ''
  }

  constructor(public cs: CommonBindingDataService, private router: Router, 
    private route: ActivatedRoute, private entityService: EntityService,
    private configService: ConfigService, private datePipe: DatePipe,) {
    this.data = {
      labels: AppSettings.MONTH_LABELS,
      datasets: [
        this.createDataset('First Dataset', '--blue-500'),
        this.createDataset('Second Dataset', '--pink-500'),
      ]
    };

    this.options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: this.getTextColor()
          }
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
          }
        },
        y: {
          ticks: {
            color: this.getTextColorSecondary()
          },
          grid: {
            color: this.getSurfaceBorder(),
            drawBorder: false
          }
        }
      }
    };

  }

  ngOnInit() {
    this.historyId = this.route.snapshot.paramMap.get('id');
    this.tab = this.incidentsComplaintsAttributeData.tabs.find(ele => ele.tabCode === 'concern');
    this.details = this.tab.groups.find(ele => ele.code === 'details');
    this.getAttributes();
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_CODE.INCIDENT_COMPLAINT_HISTORY;
    this.entityService.getAttributeDefinition(entityType, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.incidentsComplaintHistoryAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.attributeIdForIncidentComplaint = this.cs.getAttributeId('incidents_and_complaint_entity_id', this.incidentsComplaintHistoryAttributeData);
        this.searchEntity(this.attributeIdForIncidentComplaint);
      }
    });
  }

  searchEntity(attributeIdForIncidentComplaint: string) {
    this.entityData.filters = [{ attributeId: attributeIdForIncidentComplaint, attributeValue: this.historyId }];
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.countryCode = country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.INCIDENT_COMPLAINT_HISTORY, this.entityData)
      .subscribe((res: any) => {
        this.processTimelineData(res.data);
      });
  }

  processTimelineData(data: any[]) {
    this.timelineItems = [];
    data.forEach(item => {
      const status = item.values.incident_complaint_status;
      const createdAt = item.values.created_at;
      const formattedTime = this.datePipe.transform(createdAt, AppSettings.DATE_FORMAT_MMM_D_YYYY);
      const statusColor = this.getStatusColor(status);
      this.timelineItems.push({
        status,
        formattedTime,
        statusColor
      });
    });
  }

  getStatusColor(status: string): string {
    switch (status) {
      case 'Resolved':
        return '#32CD32'; 
      case 'Open':
        return '#FF0000';
      case 'Under Review':
        return '#4169E1'; 
      case 'In Progress':
        return '#FFA500'; 
      default:
        return '#9E9E9E'; 
    }
  }

  private createDataset(label: string, colorVar: string): any {
    return {
      label,
      data: [15, 17, 19],
      fill: false,
      borderColor: document.documentElement.style.getPropertyValue(colorVar),
      backgroundColor: document.documentElement.style.getPropertyValue(colorVar),
      tension: 0.4,
      showLine: false
    };
  }

  private getTextColor(): string {
    return document.documentElement.style.getPropertyValue('--text-color');
  }

  private getTextColorSecondary(): string {
    return document.documentElement.style.getPropertyValue('--text-color-secondary');
  }

  private getSurfaceBorder(): string {
    return document.documentElement.style.getPropertyValue('--surface-border');
  }

  redirectToEdit() {
    this.router.navigate(['/app/concerns/add/' + this.incidentsComplaintsDetails.entityId]);
  }
}
