import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SidebarModule } from 'primeng/sidebar';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { BookingListCardComponent } from '../booking-section/booking-list-card/booking-list-card.component';
import { BookingSectionComponent } from '../booking-section/booking-section.component';
import { DispatchBookingDetailsComponent } from '../booking-section/booking-details/dispatch-booking-details.component';
import * as _ from 'lodash';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { AppSettings } from 'app/modules/shared/app.settings';
import dayjs from 'dayjs';

@Component({
  selector: 'app-booking-sidebar',
  standalone: true,
  imports: [
    SidebarModule,
    DialogHeaderComponent,
    BookingListCardComponent,
    BookingSectionComponent,
    DispatchBookingDetailsComponent,
    AccessProviderDirective
  ],
  templateUrl: './booking-sidebar.component.html',
  styleUrl: './booking-sidebar.component.scss'
})

export class BookingSidebarComponent {
  @ViewChild(BookingSectionComponent) bookingSectionComponent: BookingSectionComponent;
  @ViewChild(DialogHeaderComponent) dialogComponent: DialogHeaderComponent;
  @Input() visible: boolean
  @Input() leftDialogPosition: boolean
  @Input() showBookingDetails: boolean
  @Input() position: string;
  @Output() closeBtnClickedChanges: EventEmitter<any> = new EventEmitter();
  @Output() showBookingBtnClickedChanges: EventEmitter<any> = new EventEmitter();
  bookingDetailItem: any = {};
  bookingId: string;
  dispatchBookingDetails
  geoLocationsData: any = {};
  bookingDetailsById: any[] = [];
  bookingHeader: string;
  advanceFilterAttributeCodes = [AppSettings.BOOKING_ATTRIBUTE_CODE.ADDITIONAL_PASSENGER_VEHICLE,
    AppSettings.BOOKING_ATTRIBUTE_CODE.PET_COUNT,
    AppSettings.BOOKING_ATTRIBUTE_CODE.PICK_UP_DATE,
  ]
  constructor(private cs: CommonBindingDataService,
     private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.bookingHeader = this.cs.getLabel("dispatch.bookings");
  }

  onBookingFilterValueChange(event) {
    console.log(event);
    console.log(this.dialogComponent.filterList);
    this.bookingSectionComponent.entityData.advanceFilters = [];
    const bookingData = event;
    this.bookingSectionComponent.entityData.filters = [];
    for (const [key, value] of Object.entries(bookingData)) {
      const attributeValue = value;
      if (key === AppSettings.BOOKING_ATTRIBUTE_CODE.OWNER_WATCHLIST) {
        if (attributeValue) {
          this.bookingSectionComponent.entityData.filters.push({
            attributeCode: key,
            attributeValue : attributeValue ? [AppSettings.YES_NO_VALUES.YES] : [AppSettings.YES_NO_VALUES.NO]
          });
        }
      } else if(this.advanceFilterAttributeCodes.includes(key)) {
        this.setAdvanceFilter(key, value);
      } else if (attributeValue) {
        this.bookingSectionComponent.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    
    this.bookingSectionComponent.entityData.offset = event.first;
    this.bookingSectionComponent.entityData.filters = this.cs.mapAttributeIds(this.bookingSectionComponent.entityData.filters, this.dialogComponent.filterList, 'filter');
    this.bookingSectionComponent.getBookingList(true);
  }

  setAdvanceFilter(key, value) {
    if (value) {
      if (key === AppSettings.BOOKING_ATTRIBUTE_CODE.PET_COUNT || key === AppSettings.BOOKING_ATTRIBUTE_CODE.ADDITIONAL_PASSENGER_VEHICLE) {
        this.bookingSectionComponent.entityData.advanceFilters.push({
          attributeId: this.dialogComponent.filterList.find(ele => ele.attributeCode === key)?.attributeId,
          fromValue: value,
          comparisonOperator: AppSettings.COMPARISON_OPERATOR.GTE
      })
      } else if (key === AppSettings.BOOKING_ATTRIBUTE_CODE.PICK_UP_DATE) {
        this.bookingSectionComponent.entityData.advanceFilters.push({
          attributeId: this.dialogComponent.filterList.find(ele => ele.attributeCode === key)?.attributeId,
          fromValue: dayjs(value).startOf('day').valueOf(),
          toValue: dayjs(value).endOf('day').valueOf(),
          comparisonOperator: AppSettings.COMPARISON_OPERATOR.BETWEEN
      })
      }
    }
  }

  closeBtnClicked() {
    this.showBookingDetails = false;
    // this.closeBtnClickedChanges.emit(false);
  }

  onBookingCardClicked() {
    this.showBookingDetails = true;
    this.cd.detectChanges();
  }

  showBookingDetailsClick(event) {
    this.bookingDetailItem = event.eventData;
    this.showBookingDetails = true;
    this.showBookingBtnClickedChanges.emit(event);
  }




}
