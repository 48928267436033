<app-dashboard-navbar [includeManageWidgets]="false"></app-dashboard-navbar>

<div style="position: relative" appAccessProvider [accessCode]="{ menuCode: 'DASHBOARD', pageCode: 'MAP' }">
  <div class="flex">
    <div class="flex-50 flex col-12 md:col-12">
        <div class="p-fluid ml-1 mr-1 w-full">
            <div class="p-fluid p-formgrid grid">
                <div class="mi-table w-full">
                    <div class="flex mi-filter-container">
                        <div class="flex flex-wrap justify-content-center">
                            <div class="flex-1 flex justify-content-end mr-2">
                                <span class="p-input-icon-left">
                                    <i class="pi search-icon relative left-20 cursor-pointer"
                                        [ngClass]="{ 'pi-search': !searchValue, 'pi-times': searchValue }"
                                        (click)="searchValue ? clearSearch() : null"></i>
                                    <input class="width-200 search-filter" type="text" pInputText (input)="onSearch($event)"
                                        [placeholder]="'lbl_search' | translate" [(ngModel)]="searchValue"/>
                                </span>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-content-center">
                            <div class="flex-1 flex justify-content-end mr-2">
                                <p-multiSelect #multiSelect [placeholder]="'lbl_vehicle_type' | translate"
                                    [(ngModel)]="selectedVehicleType"
                                    [options]="vehicleTypeList"
                                    optionLabel="labelValue"
                                    [showToggleAll]="true"
                                    optionValue="labelValue"
                                    [filter]="true"
                                    (onChange)="onChangeVehicleType($event)"
                                    (onPanelHide)="clearSearchField(multiSelect)"
                                    (onFilter)="onFilter($event)">

                                    <ng-template pTemplate="filtericon">
                                        <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                    </ng-template>
                                </p-multiSelect>
                            </div>
                        </div>
                        <div class="flex flex-wrap justify-content-center">
                          <div class="flex-1 flex justify-content-end mr-2">
                              <p-multiSelect #multiSelect [placeholder]="'lbl_queue_location' | translate"
                                  [(ngModel)]="selectedQueueLocation"
                                  [options]="queueLocationList"
                                  optionLabel="labelKey"
                                  [showToggleAll]="true"
                                  optionValue="labelValue"
                                  [filter]="true"
                                  (onChange)="onChangeQueueLocation($event)"
                                  (onPanelHide)="clearSearchField(multiSelect)"
                                  (onFilter)="onFilter($event)">

                                  <ng-template pTemplate="filtericon">
                                      <i [class]="filterIcon" (click)="onFilterIconClick()"></i>
                                  </ng-template>
                              </p-multiSelect>
                          </div>
                      </div>
                      <!-- @if(selectedQueueLocation.length > 0 || selectedVehicleType.length > 0){ -->
                        <!-- <button  pButton [label]="'clear_all' | translate" (click)="clearFilter()" class="mr-2 bg-white text-primary border-none cursor-pointer clear-btn"></button> -->
                      <!-- } -->
                    </div>
                </div>    
            </div>
        </div>
    </div>
  </div>
  <div class="flex flex-wrap gap-2 justify-content-between">
    <p-button
      (click)="showRightDriversDialog('right')"
      icon="mi-lg mi-driver wh-30 inline-block"
      styleClass="toggle-btn border-radius-left absolute right-0 top-59 z-1"
      id="driver-dialog" [label]="'lbl_all_drivers_upper' | translate">
    </p-button>
  </div>
  <div class="map-div" id="map"></div>
  <div id="style-selector-control" class="map-control mr-3 dark-light-toggle">
    <p-toggleButton
      id="toggle_button"
      class="p-0"
      id="style-selector"
      offIcon="mi-lg mi-night_mode wh-40"
      onIcon="mi-lg mi-day_mode wh-40"
      (onChange)="toggleDarkAndLightMap($event)"
      [(ngModel)]="checked">
    </p-toggleButton>
  </div>
  <button id="my-location-control" class="geolocation-button" (click)="getCurrentLocation()">
    <i class="mi-lg mi-locate_me_target wh-24" style="background-color: #1e90ff"></i>
  </button>
</div>

<p-sidebar [(visible)]="rightDriversSectionVisible" [position]="rightDialogPosition">
  <ng-template pTemplate="header">
    Test
  </ng-template>
  <ng-template pTemplate="content">
    <p>lorem ipsum</p>
  </ng-template>
</p-sidebar>