<!-- <p (click)="getValues()">select-journey-id works!</p> -->
<form [formGroup]="formName">
    <input pInputText type="text" (click)="getValues()" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/>
</form>
<div>

</div>
<p-dialog [header]="'lbl_select_journey' | translate" modal="true" (onHide)="onHide()" [(visible)]="isVisible" [style]="{ width: '30vw' }" [breakpoints]="{'960px': '60vw', '640px': '80vw'}" >
    <div class="flex justify-content-start align-items-center align-content-start grid p-3 dialog-content">
        @if(tableData.length > 0) {
            @for(booking of tableData; track booking; let i = $index) {
                <div class="flex justify-content-between align-items-center w-full separator-2">
                    <div class="flex align-items-center cursor-pointer">
                        <div class="booking-details">
                            <span class="flex items-center py-2">
                                <span class="font-semibold font-20 text-color" id="bookingId">#{{ booking?.booking_code }}</span>
                                <p-radioButton name="booking" [(ngModel)]="selectedBookingId" [value]="booking?.id" class="flex radio-button-margin" (click)="selectRadioButton(booking?.id)"></p-radioButton>
                            </span>
                            <div class="font-medium relative">
                                <div class="flex-1 flex align-items-center justify-content-left ellipsis mb-3 relative">
                                    <i class="route inline-block relative mr-1 text-xs"></i>
                                    <i class="{{miIcons.BOOKING_PICKUP_POINT}} inline-block relative mr-2"></i>{{booking?.booking_pickup_location_address}}
                                </div>
                              
                                @if (booking?.booking_stops_location_address?.length > 0) {
                                    <div class="flex-1 flex align-items-center justify-content-left ellipsis mb-3 stops-text relative">                
                                        <i class="route inline-block relative mr-1 text-xs"></i>
                                        <i class="{{miIcons.BOOKING_STOP_POINT}} inline-block relative mr-2 text-xs"></i>{{booking?.booking_stops_location_address?.length}}
                                    </div>
                                }
                    
                                <div class="flex-1 flex align-items-center justify-content-left ellipsis ">
                                    <i class="{{miIcons.BOOKING_DROP_OFF_POINT}} inline-block relative mr-2"></i>{{booking?.booking_dropoff_location_address}}
                                </div>
                            </div>
                            @if(tableData.length > 1) {
                                <hr>
                            }
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            }
        }
        @else{
            <div class="w-full pt-0 pr-2 pb-0 pl-3">
                <app-no-result-found></app-no-result-found>
            </div>
        }  
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end my-2">
            <button pButton [label]="'cancel' | translate" (click)="isVisible = false" class="p-button-text"></button>
            <button pButton [label]="'lbl_select_only' | translate" (click)="assignData()" class="p-button-primary"></button>
        </div>
    </ng-template>
</p-dialog>
