<form [formGroup]="miFormGroup" class="setting-app-login-form">
  @for (group of groups; track groups.fields;let i = $index) {
    <div class="col-12 md:col-12 tab-group">
      <div class="card p-fluid ml-2 mr-2" [id]="i">

        <div class="p-fluid p-formgrid grid">
            @if (group.code === GROUP_CODE.DRIVER_DRIVING_RECORD) {
              <label class="w-full mt-0 uppercase text-lg font-semibold">{{'lbl_driving_record' | translate}}</label>
              <app-driver-driving-record class="col-12"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [driverDrivingRecordIds]="driverDrivingRecordIds"
                [edit]="edit"
                (onRemoveDriverDrivingRecord)="onRemoveDriverDrivingRecord($event)"
                [attributeValues]="attributeValues">
              </app-driver-driving-record>
            }
            @else if (group.code === GROUP_CODE.DRIVER_DOCUMENT) {
              <app-driver-document class="col-12"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [driverDocumentEntityIdList]="driverDocumentEntityIdList"
                [edit]="edit"
                [uploadDocumentValueIds]="uploadDocumentValueIds"
                (driverDocumentFieldsChanges)="driverDocumentFieldsChanges($event)"
                (onRemoveDriverDocument)="onRemoveDriverDocument($event)"
                [attributeValues]="attributeValues">
              </app-driver-document>
            }
            @else if (group.code === GROUP_CODE.DOCUMENTS) {
              <app-vehicle-document class="col-12 p-0"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [vehicleDocumentEntityIdList]="vehicleDocumentEntityIdList"
                [edit]="edit"
                (vehicleDocumentFieldsChanges)="vehicleDocumentFieldsChanges($event)"
                (onRemoveVehicleDocument)="onRemoveVehicleDocument($event)"
                [attributeValues]="attributeValues">
              </app-vehicle-document>
            }
            @else if (group.code === GROUP_CODE.BOOKING_TIME_RESTRICTION) {
              <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
              <booking-time-restriction class="col-12"
                [isHorizontalForm]="isHorizontalForm"
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [attributeValues]="attributeValues"
                [bookingTimeRestrictionIds]="bookingTimeRestrictionIds"
                [edit]="edit"
              ></booking-time-restriction>
            } @else if ((group.code === GROUP_CODE.STAY_HOME_ADDRESS && moduleName === entityCodes.PASSENGER) || (group.code === 'address' && moduleName === entityTypes.GENERAL_SETTINGS)) {
              <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
             <div class="md:col-12">
               <app-stay-home-address
               [group]="group"
               [attributeLabels]="attributeLabels"
               [moduleName]="moduleName"
               [miFormGroup]="miFormGroup"
               [attributeValues]="attributeValues"
               [addressEntityIdList]="addressEntityIdList"
               [defaultLocationList]="defaultLocationList"
               (formGroupEmit)="formGroupEmitEvent($event)"
               [showImg]="showImg"
               [isShowBookingMap]="isShowBookingMap"
               ></app-stay-home-address>
              </div>
            }  @else if (group.code === GROUP_CODE.TIME_SETTINGS) {
              <app-time-settings
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [attributeValues]="attributeValues"
              ></app-time-settings>
            } @else if (group.code === GROUP_CODE.ASSIGN_LOCATION) {
              <app-privilege-settings-assign-location
                [group]="group"
                [attributeLabels]="attributeLabels"
                [moduleName]="moduleName"
                [miFormGroup]="miFormGroup"
                [attributeValues]="attributeValues">
              </app-privilege-settings-assign-location>
            }
            @else {
              @if(group.code !== 'send_notification' && group.code !== 'default' && group.code !== 'setting') {
                @if(cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code) === 'Selected People') {
                  <label class="people-count"></label>
                }
                @else if(group.code === GROUP_CODE.DEFAULT_VALUES && moduleName === 'pass_privilege_conf') {
                  <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
                  <p class="font-14 default-text-light-gray" translate>settings.passenger.default_values_placeholder</p>
                }
                @else {
                  <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
                }
              }
              @if(group.code === GROUP_CODE.DOWNLOAD_ACCOUNT_DATA) {
                <label class="note-text w-full mt-2" translate>lbl_text_for_download_data</label>
                <div class="mt-3 mb-2 pb-2 note-div align-content-center">
                  <div class="p-2">
                    <img class="note-img" src="../../../../../assets/images/mi-theme/light/basic/error_outline.svg">
                  </div>
                  <div class="align-content-center">
                    <label class="text-sm opacity-70" translate>lbl_note_for_download_date</label>
                  </div>
                </div>
              }

              @if(group.code === GROUP_CODE.PASSWORD_REQUIREMENTS) {
                <label class="note-text w-full mt-3" translate>lbl_text_for_password_requirement</label>
                <div class="mt-3 flex mb-2 note2-div align-content-center">
                  <div class="p-2">
                    <img class="note-img" src="../../../../../assets/images/mi-theme/light/basic/error_outline.svg">
                  </div>
                  <div class="align-content-center">
                    <label class="text-sm opacity-70" translate>lbl_note_for_password_requirement</label>
                  </div>
                </div>
              }

              @if(group.code === GROUP_CODE.SESSION_TIMEOUT) {
                <label class="note-text w-full mt-3" translate>lbl_text_for_session_timeout</label>
              }

              <div class="w-full formgrid grid pt-3">
                @for (field of group.fields; track field.attributeCode;let j = $index) {
                  @if(field.attributeCode !== 'location_address' && field.attributeCode !== 'event_address' && field.attributeCode !== 'driver_address'){
                    <div [id]="field.attributeCode" class="field input-field-wrapper col-12 md:col-12  lg:col-{{field.uiColumns}}">
                      @if(field.inputCode !== 'spacer'){
                        @if (!isHorizontalForm) {
                          @if (field.attributeCode === 'car_service_auto_dispatch' || field.attributeCode === 'shuttle_bookings') {
                            <div class="grid mt pt-2 pl-2">
                              <div class="col-auto horizontal">
                                <label class="flex">{{cs.getLabelValue(moduleName
                                  +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                  @if (field.validation?.required) {
                                    <span class="required">*</span>
                                  }
                                  @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                                    <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
                                      +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                                    </app-mi-tooltip>
                                  }
                                </label>
                              </div>
                              <div class="col-auto ml-3">
                                <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"  [dateFormat]="dateFormat"
                                [moduleName]="moduleName" [multiSelectDisplay]="multiSelectDisplay"></app-mi-fields>
  
                                <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                                [moduleName]="moduleName"></app-mi-error>
                              </div>
                            </div>
                          } @else if (field.attributeCode === 'seeded_player') {
                            <div class="grid mt pt-2 pl-2">
                              <div class="col-auto">
                                <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"  [dateFormat]="dateFormat"
                                [moduleName]="moduleName" [multiSelectDisplay]="multiSelectDisplay"></app-mi-fields>
  
                                <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                                [moduleName]="moduleName"></app-mi-error>
                              </div>
                              <div class="col-auto horizontal  ml-2">
                                <label class="flex">{{cs.getLabelValue(moduleName
                                  +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                  @if (field.validation?.required) {
                                    <span class="required">*</span>
                                  }
                                  @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                                    <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
                                      +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                                    </app-mi-tooltip>
                                  }
                                </label>
                              </div>
                            </div>
                          }
                          @else if (excludeAttributeCodes.includes(field.attributeCode)) {
                            <div class="people-count"></div>
                          }
                          @else {
                            @if(field.attributeCode ==='generate_code_for_booking' ){
                              <label class="ml-4">{{cs.getLabelValue(moduleName
                                +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                              </label>
                            } @else if(field.attributeCode ==='special_login' ){
                              <label class="ml-4">{{cs.getLabelValue(moduleName
                                +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                              </label>
                            } @else if((field.attributeCode !== 'add_segment' && group.code !== 'features') && (field.attributeCode !== 'account_owner_user_id') && (field.attributeCode !== 'reset_all_password')) {
                              <label class="field-label flex">{{cs.getLabelValue(moduleName
                                +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                @if (field.validation?.required) {
                                  <span class="required">*</span>
                                }
                                @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                                  <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
                                    +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                                  </app-mi-tooltip>
                                }
                              </label>
                            }
                            @if (field.attributeCode === 'barcode') {
                              <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [pKeyFilter]="pKeyFilterForBarcode"
                              [moduleName]="moduleName" ></app-mi-fields>
                            } @else if (field.attributeCode === 'require_at_least_one' || field.attributeCode === 'select_data_to_include') {
                              <app-checkbox-multiselect [field]="field" [attributeLabels]="attributeLabels"
                              [moduleName]="moduleName" ></app-checkbox-multiselect>
                            } @else if (field.attributeCode === 'generate_code_for_booking') {
                              <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"
                               class="relative genereate-code-field"></app-mi-fields>
                            } @else if (field.attributeCode === 'special_login') {
                              <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"
                               class="relative special-login-field"></app-mi-fields>
                            } @else  {
                              <app-mi-fields [field]="field" [multiSelectDisplay]="multiSelectDisplay" [attributeLabels]="attributeLabels"  [showImg]="showImg" [isMultipleFilesUpload]="isMultipleFilesUpload"
                              [moduleName]="moduleName" [dateFormat]="dateFormat"></app-mi-fields>
                            }
                            @if (field.attributeCode === 'generate_code_for_booking'){
                              <app-mi-error class="generate" [field]="field" [attributeLabels]="attributeLabels" [moduleName]="moduleName"
                               class="generate-code-msg relative block"></app-mi-error>
                            } @else if (field.attributeCode === 'select_login_option'){
                              <div class="people-count"></div>
                            } @else{
                              <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                              [moduleName]="moduleName"></app-mi-error>
                            }
                          }
  
                        }
                         @else {
                          @if (field.attributeCode === 'passenger_type' && moduleName === 'pass_privilege_conf') {
                            <div class="relative">
                              <div class="grid absolute bottom-25px w-full">
                                <div class="col-7"></div>
                                <div class="col-2 horizontal">
                                  <label class="flex font-semibold">{{cs.getLabelValue(moduleName
                                    +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                    @if (field.validation?.required) {
                                      <span class="required">*</span>
                                    }
                                    @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                                      <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
                                        +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                                      </app-mi-tooltip>
                                    }
                                  </label>
                                </div>
                                <div class="col-3">
                                  <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"  [dateFormat]="dateFormat"
                                  [moduleName]="moduleName" [multiSelectDisplay]="multiSelectDisplay"></app-mi-fields>
      
                                  <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                                  [moduleName]="moduleName"></app-mi-error>
                                </div>
                              </div>
                            </div>
                          }
                          @else if (field.attributeCode === 'predefined_location_category_for_pickup_check' || field.attributeCode === 'predefined_location_category_for_drop_check') {
                            <div class="grid">
                              <div class="col-5 horizontal">
                                <label class="hidden"></label>
                              </div>
                              <div class="col-5">
                                <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"  [dateFormat]="dateFormat"
                                [moduleName]="moduleName" [multiSelectDisplay]="multiSelectDisplay"></app-mi-fields>
                              </div>
                            </div>
                          }
                          @else if (field.attributeCode === 'select_location_address' && (moduleName === 'pass_privilege_conf' || moduleName === 'passenger')) {
                            <div class="hidden"></div>
                          }
                          @else {
                            <div class="grid pt-2">
                              <div class="col-5 horizontal">
                                <label class="flex font-semibold">{{cs.getLabelValue(moduleName
                                  +'.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}
                                  @if (field.validation?.required) {
                                    <span class="required">*</span>
                                  }
                                  @if (cs.getLabelValue(moduleName+'.fields.'+field.attributeCode+'.helpTips',attributeLabels,0)) {
                                    <app-mi-tooltip [messageData]="cs.getLabelValue(moduleName
                                      +'.fields.'+field.attributeCode+'.helpTips',attributeLabels,field.attributeCode)">
                                    </app-mi-tooltip>
                                  }
                                </label>
                              </div>
                              <div class="col-5 privilege-radio">
                                <app-mi-fields [field]="field" [attributeLabels]="attributeLabels"  [dateFormat]="dateFormat"
                                [moduleName]="moduleName" [multiSelectDisplay]="multiSelectDisplay"></app-mi-fields>
    
                                <app-mi-error [field]="field" [attributeLabels]="attributeLabels"
                                [moduleName]="moduleName"></app-mi-error>
                              </div>
                              @if (field.attributeCode === fieldsToDisplayHRLine.AIRPORT_TRANSPORT || field.attributeCode === fieldsToDisplayHRLine.TRANSPORTATION_END_DATE ||
                                  field.attributeCode === fieldsToDisplayHRLine.DEPARTURE_JOURNEY || field.attributeCode === fieldsToDisplayHRLine.NUMBER_OF_BOOKING_PER_DAY ||
                                  field.attributeCode === fieldsToDisplayHRLine.BOOKING_CANCELLATION_IN_STATUS || field.attributeCode === fieldsToDisplayHRLine.OTP_VERIFICATION_FOR_BOOKING_END) {
                                <hr class="hr-line">
                              }
                            </div>
                          }
                        }
                      }
  
                    </div>
                  }
                }
              </div>
            }
        </div>
      </div>
    </div>
  }

  @if (displaySubmitButtons) {
    <div [style.display]="hideCancelAndSubmitBtn ? 'none' : block" class="col-12 save-action-div">
      @if(groups[0]?.code !== 'group_dimensions' && (groups[0]?.code !== 'details' || moduleName !== 'incidents_and_complaint')) {
        @if(moduleName !== 'broadcast_message') {
          @if(activeIndex !== 0) {
            <button [id]="previousBtnId" pButton class="settings-app-login mr-2 border-none btn-bg-color default-text-black-color height-44" (click)="onPreviousClick()" [label]="previousBtnText"></button>
          } @else if(moduleName === 'location') {
            <button pButton class="mr-2 surface-100 border-100 hover:border-400 default-text-light-black-color height-44" (click)="onClickCancelFun()" [label]="previousBtnText"></button>
          }
        }
        @else {
          <button pButton class="mr-20 border-600 surface-ground text-color height-44" (click)="onClickCancelFun()" [label]="previousBtnText"></button>
        }
      }
      @else {
        <button [id]="previousBtnId" pButton class="mr-20 text-color height-44 save-add-hover" (click)="saveAndAddData($event)" [label]="previousBtnText"></button>
      }
      <p-button [disabled]="saveBtnDisabled" [id]="saveBtnId" class="inline-flex height-44" (click)="submitData($event)" [label]="submitBtnText"></p-button>
    </div>
  }

</form>
