<app-dashboard-navbar [includeRearrange]="true" [includeManageWidgets]="true"></app-dashboard-navbar>

<div class="grid m-3 dashboard-vehicle-content" appAccessProvider [accessCode]="{ menuCode: 'DASHBOARD', pageCode: 'LIVE_DASHBOARD', subPageCode: 'VEHICLE' }">
  <div class="col-12 lg:col-6 xl:col-3">
    <!-- vehicle status -->
    @if(isVehicleStatusVisible()) {
      <app-vehicle-status [vehicleStatusData]="vehicleStatusData"></app-vehicle-status>
    }

    <!-- vehicle assignments -->
    @if(vehicleAssignmentData) {
      @if(isVehicleAssignmentVisible()) {
        <app-vehicle-assignments [vehicleAssignmentData]="vehicleAssignmentData"></app-vehicle-assignments>
      }
    }
  </div>

  <!-- booking status -->
  @if(isBookingStatusVisible()) {
    @if(bookingStatusData?.length > 0) {
      <div class="col-12 lg:col-6 xl:col-3">
        <app-booking-status [bookingStatusData]="bookingStatusData"></app-booking-status>
      </div>
    }
  }

  <!-- journey status -->
  @if(isJourneyStatusVisible()) {
    @if(journeyStatusData?.length > 0) {
      <div class="col-12 lg:col-6 xl:col-3">
        <app-journey-status [journeyStatusData]="journeyStatusData"></app-journey-status>
      </div>
    }
  }

  <!-- passenger by types -->
  @if(isPassengerByTypesVisible()) {
    <div class="col-12 lg:col-6 xl:col-3">
      <app-passenger-by-types [playerData]="playerData"></app-passenger-by-types>
    </div>
  }

  <!-- cancelled journey -->
  <!-- @if(cancelledJourneyData) { -->
    @if(isCancelledJourneyVisible()) {
      <div class="col-12 lg:col-6 xl:col-3">
        <app-cancelled-journey [cancelledJourneyData]="cancelledJourneyData"></app-cancelled-journey>
      </div>
    }
  <!-- } -->

</div>

  <!-- equipment status -->
  <!-- <div class="col-12 lg:col-6 xl:col-3">
    <app-equipment-status
      [equipmentStatusData]="equipmentStatusData"
    ></app-equipment-status>
  </div> -->

<!-- <div class="grid m-3">
  <div class="col-12 lg:col-6 xl:col-3">
    <app-vehicle-assignments
    [vehicleAssignmentData]="vehicleAssignmentData"
    ></app-vehicle-assignments>
  </div>
</div> -->

<!-- <div class="grid m-3"> -->
  <!-- open issues -->
  <!-- <div class="col-12 lg:col-6 xl:col-3">
    <app-open-issues></app-open-issues>
  </div> -->
  <!-- service reminders -->
  <!-- <div class="col-12 lg:col-6 xl:col-3">
    <app-service-reminders></app-service-reminders>
  </div> -->
  <!-- on-time service compliance -->
  <!-- <div class="col-12 lg:col-6 xl:col-3">
    <app-service-compliance></app-service-compliance>
  </div> -->
  <!-- time to resolve graph -->
  <!-- <div class="col-12 lg:col-12 xl:col-6">
    <app-time-to-resolve-graph></app-time-to-resolve-graph>
  </div> -->
  <!-- repair repair-priority-container line chart -->
  <!-- <div class="col-12 lg:col-12 xl:col-6">
    <app-repair-priority-line-chart
      [lineChartData]="lineChartData"
      [lineChartOptions]="lineChartOptions"
    ></app-repair-priority-line-chart>
  </div> -->
  <!-- inspection item failure.inspection summary pie chart,inspection submissions, overdue inspections -->
  <!-- <div class="col-12 lg:col-12 xl:col-6">
    <div class="grid">
      <div class="col-12 lg:col-12 xl:col-6">
        <app-inspection-failure></app-inspection-failure>
      </div>
      <div class="col-12 lg:col-12 xl:col-6">
        <app-overdue-inspections></app-overdue-inspections>
      </div>
      <div class="col-12 lg:col-12 xl:col-6 pb-0">
        <app-inspection-pie-chart
          [pieChartData]="pieChartData"
          [pieChartOptions]="pieChartOptions"
        ></app-inspection-pie-chart>
      </div>
      <div class="col-12 lg:col-12 xl:col-6 pb-0">
        <app-inspection-submissions></app-inspection-submissions>
      </div>
    </div>
  </div> -->

  <!-- line chart of total service costs-->
  <!-- <div class="col-12 lg:col-12 xl:col-6 mb-6">
    <app-service-cost-bar-chart
      [barData]="barData"
      [barOptions]="barOptions"
    ></app-service-cost-bar-chart>
  </div> -->

  <!-- recent comments section -->
  <!-- <div class="col-12 lg:col-12 xl:col-6 mb-6">
    <app-recent-comments-section
      [commentSectionsData]="commentSectionsData"
    ></app-recent-comments-section>
  </div> -->
<!-- </div> -->
