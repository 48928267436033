<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'VIEW' }">
    <div class="grid mt-1 details-div border-bottom-1 border-200 mr-0">
        <div class="col-9 md:col-10  details-header-spacing">
            @if (showStatus) {
                <status [dataForVehicleStatus]="dataForVehicleStatus" [userId]="userId" [entityForStatus]="entityForStatus" [imgFileId]="imgFileId"></status>
            }
        </div>
        <div appAccessProvider [accessCode]="{ menuCode: 'VEHICLES', pageCode: 'ALL', subPageCode: 'EDIT' }" class="col-3 text-right align-content-center">
            <button (click)="edit()" pButton class=" border-400 bg-white text-color mr-1 pl-2 pr-2 pt-1 pb-1 mr-2 hover:surface-100" [label]="editBtnLabel">
                <i class="mi-lg mr-2 edit-pen edit_pencil"></i>
            </button>
        </div>
    </div>


    <p-tabView [(activeIndex)]="activeIndex"  [scrollable]="true" (activeIndexChange)="activeIndexChange($event)">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg overview"></i>
                <label class="capitalize" translate>vehicle.lbl_overview</label>
            </ng-template>
            @if (vehicleAttributeData) {
                <app-overview-details
                    [attributeValues]="attributeValues"
                    [attributeLabels]="attributeLabels"
                    [vehicleAttributeData]="vehicleAttributeData"
                    [vehicleEntityId]="vehicleId"
                    [vehicleRowData]="currentRow"
                    [vehicleDetails]="vehicleDetails"
                    [driverList]="driverList"
                ></app-overview-details>
            }
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg documents"></i>
                <label translate>vehicle.lbl_documents</label>
            </ng-template>
            <ng-template pTemplate="body">
                <app-document-list
                    [attributeValues]="attributeValues"
                    [attributeLabels]="attributeLabels"
                    [vehicleDetails]="vehicleDetails"
                    [entityCode]="entityCode">
                </app-document-list>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg specs"></i>
                <label translate>vehicle.lbl_specs</label>
            </ng-template>
            @if (vehicleAttributeData) {
                <vehicle-specification
                    [attributeValues]="attributeValues"
                    [attributeLabels]="attributeLabels"
                    [vehicleAttributeData]="vehicleAttributeData"
                    [vehicleEntityId]="vehicleId"
                ></vehicle-specification>
            }

        </p-tabPanel>

        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg journey-history"></i>
                <label translate>vehicle.lbl_journey_history</label>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg assignment-history"></i>
                <label translate>vehicle.lbl_assignment_history</label>
            </ng-template>
            <ng-template pTemplate="body">
                <app-vehicle-assignment-history [vehicleDetails]="vehicleDetails"></app-vehicle-assignment-history>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</div>
