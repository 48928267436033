<p-table [value]="bookingActivities">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 15%;" translate>bookings.lbl_activity_status</th>
      <th style="width: 15%;" translate>bookings.lbl_activity_time_date</th>
      <th style="width: 25%;" translate>bookings.lbl_activity_updated_by</th>
      <th style="width: 45%;" translate>bookings.lbl_activity_location</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-activity>
    <tr>
      <td class="status-code font-medium">
        <i [ngClass]="activity.iconClass"
          class="mi-lg inline-block vertical-align-text-bottom wh-20 mr-2"></i>{{ activity.status }}</td>
      <td class="font-normal time-date">
        <span class="time-style font-bold">
          {{ activity.timeAndDate | date: HOUR_MINUTE }}
          <span class="font-bold seperate-time-date">, </span>
        </span>
        <span class="date-style  font-normal ">
          {{ activity.timeAndDate | date: dd_MMM_yyyy_FORMAT }}
        </span>

      </td>
      <td>
        <span class="first-name">
          {{ activity.updatedBy?.split('(')[0] }}
        </span>
        <span class="role-name font-light">
          ({{ activity.updatedBy?.split('(')[1]?.split(')')[0] }})
        </span>
      </td>
      <td class="first-name">{{ activity.pickupLocation }}</td>
    </tr>
  </ng-template>
</p-table>
