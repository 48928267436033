<div class="select-passenger-box">
  @if (this.addedPassengerList.length === 0) {
    <div class="flex justify-content-center" translate>bookings.view_passenger_info</div>
  }
  @if(this.addedPassengerList.length > 0){
  <app-added-passenger-input [addedPassengerList]="addedPassengerList" [selectAddedPassenger]="selectAddedPassenger" (onAddedPassenger)="onSelectAddedPassenger($event)" (onCloseRemove)="onRemove($event)" ></app-added-passenger-input>
  }
  @if(selectAddedPassenger && this.addedPassengerList.length > 0){
    <div class="formgrid grid mr-2">
      <div class="field col p-2">
        <i class="{{miIcons.BOOKING_PASSENGER_TYPE_PHOTO}} inline-block cursor-pointer icon-color lock-icon mr-3 mb-0"></i>
        <span class="ellipsis text-color" translate>
          bookings.lbl_photo
        </span>
      </div>
      <div class="field value col p-2">
        @if(selectAddedPassenger?.passenger_profile_image){
          <p-image 
          [src]="selectAddedPassenger?.passenger_profile_image" 
          alt="Image" 
          width="35"
          class="vertical-align-middle border-round-sm hw-30 list-img mr-2"
          [preview]="true" />

        }@else {
          <p-image 
          [src]="defaultImage" 
          alt="Image" 
          width="35"
          class="vertical-align-middle border-round-sm hw-30 list-img mr-2"
          [preview]="true" />
        }
      </div>

    </div>

    @for (group of groups; track $index) {

      @if(!excludeGroupLabel.includes(group.code)){
      <div class="mb-2 mt-4">
        <label class="font-semibold previleges-header "
          translate>{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
      </div>
      }
      @for (field of group.fields; track $index) {
        @if (!excludeAttributeInLoop.includes(field.attributeCode)) { 

          <div class="formgrid grid mr-2">
            <div class="field col p-2 text-500 lock-text-wrapper">
              @if (group.code === DETAILS_GROUP_CODE) {
              <i class="{{informationPhoto[field.attributeCode]}} inline-block lock-icon cursor-pointer icon-color mr-2"></i>
              }
              @else if( field.attributeCode === 'booking_pass.fields.transport_services_allowed.label'){
                <app-mi-tooltip></app-mi-tooltip>
              } @else {
                <i class="{{miIcons.BOOKING_LOCK}} inline-block cursor-pointer icon-color lock-icon mr-2 "></i>
                }
                
              <label class="flex-1 flex font-normal mb-0 ml-2 field-label-wrapper" translate>
                {{field.label}}
              </label>
              
            </div>
            @if(!field.isFormVisible){
              <div class="field value col p-2 text-color info-hover-effect">

                @if(field.attributeCode === 'void_date_time'){
                  @if(field?.attributeValue){
                    {{field?.attributeValue| dateformat}}
                  }@else {
                    -
                  }
                } @else if(field.attributeCode === 'mobile_number'){
                    <app-mi-mobile-value [moduleName]="moduleName" [countryCodeForMobileNumber]="countryCodeForMobileNumber" [attributeValues]="field.attributeValue" [field]="field"></app-mi-mobile-value>
                } @else if(field.attributeCode === 'passenger_type'){
                    <span class="passenger-type-info">{{field.attributeValue || '-'}}</span>
                } @else if(field.attributeCode === 'unique_id'){
                  <span class="unique-id-info font-semibold text-base">{{field.attributeValue || '-'}}</span>
                } @else if(field.attributeCode ==='barcode'){
                  <span class="font-semibold text-base" translate>{{field.attributeValue || '-'}}</span>
                } @else if(field.attributeCode ==='email'){
                  <span class="font-semibold text-base" translate>{{field.attributeValue || '-'}}</span>
                } @else if(field.attributeCode ==='gender'){
                  <span class="font-semibold text-base" translate>{{field.attributeValue || '-'}}</span>
                } @else { 
                  {{field.attributeValue|| '-'}}
                }

              </div>
            }@else if(field.isFormVisible){
              <div class="field value col p-2 font-semibold text-color" [ngClass]="{'edit': field.attributeCode !== 'trip_notification'}">
                <form [formGroup]="miFormGroup" class="booking-passenger">
                  <div class="flex align-items-start">
                    <div class="flex align-items-start justify-content-start">
                      <app-mi-fields [field]="field" [attributeLabels]="attributeLabels" [showImg]=""
                        [moduleName]="moduleName"></app-mi-fields>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>

        }
      }
    }

  }



  <p-toast></p-toast>

</div>