import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MenuItem, MessageService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { TabViewModule } from "primeng/tabview";
import * as _ from 'lodash';
import { TranslateModule } from "@ngx-translate/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { InputNumberModule } from "primeng/inputnumber";
import { InputTextareaModule } from "primeng/inputtextarea";
import { RadioButtonModule } from "primeng/radiobutton";
import { DropdownModule } from "primeng/dropdown";
import { ColorPickerModule } from "primeng/colorpicker";
import { ManageZonesService } from "../../services/manage-zones.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ZoneMapComponent } from "../zone-map/zone-map.component";
import { Circle, Polygon, Zone, ZoneItem, ZoneItemPagination, ZonesData } from "../../models/zone.models";
import { AppSettings } from "../../../shared/app.settings";
import { ConfigService } from "../../../shared/services/config.service";
import { AppIcons } from "app/modules/shared/app.icons";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";

enum RadiusOption {
  UseRadius = "RADIUS",
  DrawGeofence = "GEO",
}
@Component({
  selector: "app-add-zone",
  templateUrl: "./add-zone.component.html",
  styleUrls: ["./add-zone.component.scss"],
  standalone: true,
  imports: [
    ButtonModule,
    BreadcrumbModule,
    TabViewModule,
    TranslateModule,
    InputSwitchModule,
    InputTextModule,
    InputNumberModule,
    InputTextareaModule,
    RadioButtonModule,
    DropdownModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    ZoneMapComponent,
    AccessProviderDirective
  ],
})
export class AddZoneComponent implements OnInit {
  @ViewChild(ZoneMapComponent) zoneMapComponent: ZoneMapComponent;
  cancelLabel: string;
  resetLabel: string;
  saveLabel: string;
  routePath: MenuItem[];
  activeIndex: number = 0;
  zoneForm: FormGroup;
  selectedColor: string = AppSettings.COLOR_PICKER_DEFAULT_COLOR;
  zoneId: string;
  formSubmitted: boolean = false;
  drawGeofence: boolean = false;
  polygonCoordinates: string = "";
  formRadius: number = 500;
  formLat: number;
  formLng: number;
  zoneFormDetails;
  zonesData: any;
  zoneListData: ZoneItem[];
  paginator: ZoneItemPagination = {
    limit: 20,
    offset: 0,
    searchStr: "",
    defaultSortColumn: "updatedAt",
    defaultSortType: "desc",
  };
  centerCordinateList: any;
  miIcons = AppIcons;
  isPolygonCoordinatesSet = false;
  geoFenceCenterCordsList;

  constructor(
    private route: ActivatedRoute,
    private cs: CommonBindingDataService,
    private fb: FormBuilder,
    private manageZoneService: ManageZonesService,
    private router: Router,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private configService: ConfigService,
    private manageZonesService: ManageZonesService,
  ) { }

  ngOnInit() {
    this.zoneId = this.route.snapshot.paramMap.get("id");
    this.setRoutePath();
    this.setHeadersAndLabels();
    this.initForm();
    this.getZonesList();
    if (this.zoneId) {
      this.getZoneData();
    }
  }

  initForm(): void {
    this.zoneForm = this.fb.group({
      active: [false],
      zoneName: ["", [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]],
      aliasName: ["", [Validators.required, Validators.maxLength(50), Validators.pattern(/^[a-zA-Z0-9][a-zA-Z0-9\s]*$/)]],
      description: [""],
      zoneType: ["RADIUS", Validators.required],
      zoneColor: [""],
      latitude: ["", [Validators.required, Validators.pattern('^.{0,20}$')]],
      longitude: ["", [Validators.required, Validators.pattern('^.{0,20}$')]],
      radius: ["", [Validators.required, this.radiusNonZero(), Validators.min(0)]],
      geoFence: [""],
    });
    this.drawGeofenceToggle();
  }

  radiusNonZero() {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value < 0) {
        return { negativeValue: true };
      } else if (value === 0) {
        return { zeroValue: true };
      }
      return null;
    };
  }

  Space(event: any) {
    if (event.target.selectionStart === 0 && event.code === 'Space') {
      event.preventDefault();
    }
  }

  drawGeofenceToggle() {
    this.zoneForm.get("zoneType").valueChanges.subscribe((option) => {
      this.drawGeofence = option === RadiusOption.DrawGeofence;

      if (this.zoneMapComponent) {
        if (this.drawGeofence) {
          this.zoneMapComponent.initDrawingPolygon(this.selectedColor);
        } else {
          this.zoneMapComponent.disableDrawingMode();
        }
      }
    });
  }

  clearValidatorsAndUpdateValidity(formControl: AbstractControl) {
    formControl.clearValidators();
    formControl.updateValueAndValidity();
  }

  onSubmit(): void {
    this.formSubmitted = true;
    if (this.zoneForm.controls["zoneType"].value === "GEO") {
      this.clearValidatorsAndUpdateValidity(this.zoneForm.get("radius"));
      this.clearValidatorsAndUpdateValidity(this.zoneForm.get("latitude"));
      this.clearValidatorsAndUpdateValidity(this.zoneForm.get("longitude"));
    }
    if (this.zoneForm.valid) {
      const formData = { ...this.zoneForm.value };
      formData["forTenantCode"] = this.configService.getForTenantCode();
      if (formData["zoneType"] === "RADIUS") {
        formData["geoFence"] = "";
      } else {
        if (this.polygonCoordinates) {
          formData["geoFence"] = this.polygonCoordinates;
        } else { 
          formData["geoFence"] =this.geoFenceCenterCordsList
          .map(coord => `[${coord.lng},${coord.lat}]`)
          .join(',');;
        }
      }
      formData["radius"] = this.getRadius();
      formData["zoneColor"] = this.selectedColor;
      const zoneFormData = formData;
      if (this.zoneId) {
        this.onEditZone(zoneFormData);
      } else {
        this.onAddZone(zoneFormData);
      }
    } else {
      console.error("Form is invalid");
    }
  }

  onGetPolygonCoordinates(event) {
    this.polygonCoordinates = event;
    this.isPolygonCoordinatesSet = !!event;
    this.cdr.detectChanges();
  }
  onGetSearchLatLng(event) {
    if (event) {
      this.zoneForm.patchValue({
        latitude: event.lat,
        longitude: event.lng,
      });
      this.zoneMapComponent.updateCirclePositionWhenDrag(event);
    }
  }
  getRadius() {
    const formData = { ...this.zoneForm.value };
    return formData["zoneType"] === RadiusOption.DrawGeofence ? 0 : formData["radius"] || 500;
  }

  onAddZone(zoneFormData) {
    this.manageZoneService.addZone(zoneFormData).subscribe(
      (res: ZoneItem) => {
        this.messageService.add({
          key: "tst",
          severity: "success",
          summary: "Successful",
          detail: this.cs.getLabel(res.message),
        });

        this.router.navigate(["app/manage-zones"]);
      },
      (error) => {
        const errorMessage =
          error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
        this.messageService.add({
          key: "tst",
          severity: "error",
          summary: "Error",
          detail: errorMessage,
        });
      }
    );
  }

  onEditZone(zoneFormData) {
    this.manageZoneService.editZone(this.zoneId, zoneFormData).subscribe(
      (res: ZoneItem) => {
        this.messageService.add({
          key: "tst",
          severity: "success",
          summary: "Successful",
          detail: this.cs.getLabel('manage_zones.zone_update_msg'),
        });

        this.router.navigate(["app/manage-zones"]);
      },
      (error) => {
        const errorMessage =
          error?.errors?.general?.[0]?.message ?? "An unknown error occurred.";
        this.messageService.add({
          key: "tst",
          severity: "error",
          summary: "Error",
          detail: errorMessage,
        });
      }
    );
  }

  getZonesList(paginator: ZoneItemPagination = this.paginator) {
    this.manageZonesService.getZonesList(paginator).subscribe((zoneData) => {
      const zonesData: ZonesData = { polygons: [], circles: [] };

      const tempzonesData = _.cloneDeep(zoneData);

      this.centerCordinateList = _.map(tempzonesData.data, ({ latitude, longitude }) => {
        if (latitude != null && longitude != null && latitude !== '' && longitude !== '' && latitude !== 0 && longitude !== 0) {
          return { lat: latitude, lng: longitude };
        }
        return null;
      }).filter(coordinate => coordinate !== null);

      this.geoFenceCenterCordsList = _.flatMap(tempzonesData.data, (zoneData) => {
        if (zoneData.geoFence) {
          const coordinatePairs = zoneData.geoFence.split(',');

          return _.map(coordinatePairs, pair => {
            const [lng, lat] = pair.trim().split(' ').map(Number);
            return { lat, lng };
          });
        }
        return [];
      });

      this.centerCordinateList = [...this.centerCordinateList, ...this.geoFenceCenterCordsList]

      const commonZoneProps = (zone: Zone) => ({
        strokeColor: zone.zoneColor,
        fillColor: zone.zoneColor,
        label: zone.zoneName,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.35
      });

      const transformGeoZone = (zone: Zone): Polygon => {
        const coords = zone.geoFence?.split(",").map(coord => {
          const [lng, lat] = coord.split(" ").map(parseFloat);
          return { lat, lng };
        });

        return {
          coords,
          ...commonZoneProps(zone)
        };
      };

      const transformRadiusZone = (zone: Zone): Circle => ({
        center: { lat: zone.latitude, lng: zone.longitude },
        radius: zone.radius,
        ...commonZoneProps(zone)
      });

      zonesData.polygons = zoneData.data.filter(zone => zone.zoneType === "GEO").map(transformGeoZone);

      zonesData.circles = zoneData.data.filter(zone => zone.zoneType === "RADIUS").map(transformRadiusZone);

      this.zonesData = zonesData;
    });
  }

  getZoneData() {
    this.manageZoneService
      .getZoneDetails(this.zoneId)
      .subscribe((zoneDetails: ZoneItem) => {
        this.zoneForm.patchValue(zoneDetails);
        this.selectedColor = zoneDetails.zoneColor;
        this.zoneFormDetails = zoneDetails;
      });
  }
  onResetForm(): void {
    this.zoneForm.reset({
      active: false,
      zoneName: "",
      aliasName: "",
      description: "",
      latitude: "",
      longitude: "",
      radius: 0,
      zoneType: RadiusOption.UseRadius,
    });
    this.selectedColor = "#e00d23";
    this.drawGeofence = false;

    if (this.zoneMapComponent) {
      this.zoneMapComponent.clearPolygon();
    }
    this.getRadius();
  }

  onCancelForm() {
    this.router.navigate(["app/manage-zones"]);
  }

  onColorChange(color: string) {
    this.selectedColor = color;
  }

  setRoutePath() {
    const manageZoneLabel = this.cs.getLabel("manage_zones.manage_zone");
    const addEditLabel = this.zoneId
      ? this.cs.getLabel("manage_zones.edit_zone")
      : this.cs.getLabel("manage_zones.add_zone");
    this.routePath = [
      {
        label: manageZoneLabel,
        routerLink: this.zoneId ? "../../" : "../",
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
      {
        label: addEditLabel,
        styleClass: "breadcrumb-child forward-slash breadcrumb-text",
        style: { display: "flex", top: "2px", position: "relative" },
      },
    ];
  }

  showRequiredErrorMessage(fieldName: string): boolean {
    const field = this.zoneForm.get(fieldName);
    return this.formSubmitted && field.invalid && field.errors?.required && !field.errors?.maxLength;
  }

  setHeadersAndLabels() {
    this.cancelLabel = this.cs.getLabel("cancel");
    this.resetLabel = this.cs.getLabel("reset");
    this.saveLabel = this.zoneId ? this.cs.getLabel("label_update") : this.cs.getLabel("label_save");
  }
}
