<div class="company-logo">
  <div class="shrink-width-logo">LOGO</div>
  <img class="full-width-logo"src="assets/images/{{layoutService.config.theme}}/{{layoutService.config.colorScheme}}/logo.png">
</div>
<div class="sidebar-dropdown">
  <div class="event-dropdown">
    <form [formGroup]="eventDropdownForm">
      <p-dropdown [options]="listColumns" optionLabel="label" optionValue="eventShortcode" formControlName="eventDropdown"
      [placeholder]="'events.lbl_select_an_event'|translate" (onChange)="onEventChangeChange($event)" (mouseleave)="onDropdownMouseLeave()">
        <ng-template let-option pTemplate="selectedItem">
          <!-- @if (selectedEvent) { -->
            <div class="flex align-items-center">
              @if (option.icon) {
                <img [src]="option?.icon" class="option-icon mr-2">
              } @else {
                <div class="inline-flex mr-2">
                  <img [src]="option?.icon" class="option-icon mr-2">
                </div>
              }
              <span class="sidebar-event">{{ option.eventName }}</span>
            </div>
          <!-- } -->
        </ng-template>
    
        <ng-template let-option pTemplate="item">
          <div class="flex align-items-center">
            @if (option.icon) {
              <img [src]="option?.icon" class="option-icon mr-2">
            } @else {
              <div class="inline-flex mr-2">
                <img [src]="option?.icon" class="option-icon mr-2">
              </div>
            }
            <span class="sidebar-event">{{ option.eventName }}</span>
          </div>
        </ng-template>
      </p-dropdown>
    </form>

  </div>
  <div class="event-image">
    <img [src]="selectedEventImage?.icon || defaultIconUrl" class="event-img ml-2 mr-2">
  </div>
</div>
<div class="sidebar-body hide-scrollbar">
  <p-panelMenu class="sidebar-panel-menu " [model]="items" [multiple]="true"></p-panelMenu>
</div>