<div class="card p-3 mb-3 border-none overflow-hidden">
    <div>
        <p-tabMenu 
            [model]="items" 
            [activeItem]="activeItem" 
            [scrollable]="true"
            [style]="{'max-width': '100%'}"
            (activeItemChange)="onActiveItemChange($event)">
        </p-tabMenu>
    </div>
</div>