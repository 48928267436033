<div class="flex justify-content-start booking-action-button">
    <!-- @if(SHOW_REASSIGN.includes(rowData.booking_status)){
        <p-button label="{{'bookings.reassign'| translate }}" (click)="onAssign(rowData)" [outlined]="true"></p-button>
    } -->
    <!-- @if (SHOW_ASSIGN.includes(rowData.booking_status)) {
        <button pButton class="assign-journey" label="{{'bookings.assign'| translate }}" (click)="onAssign(rowData)"></button>
    } -->
    @if(rowData.auto_dispatch){
        <p-button label="{{'bookings.autoAssign'| translate }}" (click)="onAssign(rowData)" [text]="true"></p-button>
    } @else {
        <button pButton class="assign-journey" label="{{'bookings.assign'| translate }}" (click)="onAssign(rowData)"></button>
    }
    <!-- @else {
         <p-button label="{{'bookings.replace'| translate }}" class="mr-2" (click)="onReplace()" ></p-button>
    } -->
</div>
