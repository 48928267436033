import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { AppSettings } from '../../shared/app.settings';
import { BasicTableComponent } from '../../shared/components/basic-table/basic-table.component';
import { MiFormComponent } from '../../shared/components/mi-form/mi-form.component';
import { Country } from '../../shared/models/country';
import { Language } from '../../shared/models/language';
import { CommonBindingDataService } from '../../shared/services/common-binding-data.service';
import { ConfigService } from '../../shared/services/config.service';
import type { AttributeData, entityResponse } from '../../vehicles/models/attribute.models';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Countries } from 'app/modules/shared/countries';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import { GeoSearchComponent } from 'app/modules/shared/components/mi-fields/geo-search/geo-search.component';
import { MiUploadFileComponent } from 'app/modules/shared/components/mi-fields/mi-upload-file/mi-upload-file.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { EventService } from '../services/event.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { JsonPipe } from '@angular/common';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { MetaDataService } from 'app/modules/shared/services/meta-data.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-add-event',
  standalone: true,
  imports: [BreadcrumbModule, TabViewModule, MiFormComponent, DialogModule, FormsModule, ReactiveFormsModule,
    BasicTableComponent, ButtonModule, TranslateModule, InputTextModule, DropdownModule, GeoSearchComponent,
    MiUploadFileComponent, MultiSelectModule, CalendarModule, InputNumberModule, InputTextareaModule,
    JsonPipe,  AccessProviderDirective],
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss', '../../shared/components/mi-fields/mobile-input//flags.scss'],
  providers: [
    CommonBindingDataService,
  ]
})
export class AddEventComponent {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 0;
  testVariable: any;
  data: AttributeData;
  eventAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  eventAttributeLength;
  eventId: string;
  attributeValues = [];
  nextBtnLabel;
  eventEntityData: any;
  multiSelectDisplay: string = 'chip';
  dateFormat: any = AppSettings.DATE_FORMAT_DD_MMM_YYYY;
  imgFileId: any;
  showImgUpload: boolean = true;


  addEventForm: FormGroup;
  regionalSettingForm: FormGroup;
  countries: any[] | undefined;
  filterIcon = 'pi pi-search';
  geoLocationAddressField: any;
  imgField: any;
  transportationTypeList: any[] = [];
  currentDate: Date = new Date();
  eventDetails: any;
  timeSettingsAttributeData: any;
  attributeLabelsForTimeSettings = {};
  timeSettingsModuleName = AppSettings.ENTITY_TYPE.TIME_SETTINGS;
  previousBtnLabel: any = this.cs.getLabel('lbl_cancel');
  saveTimeSettingsBtnLabel: any = this.cs.getLabel('settings.lbl_save_time_settings');
  dateFormatList: any = [];
  timeFormatList: any = [];
  showGeoLocation: boolean = true;
  transportationStartDate: Date = new Date();
  transportationEndDate: Date = new Date();
  unit;
  timeSettingsEntityId: any;
  timeSettingsEntityData: any;
  eventCode: any;
  showForm: boolean = false;
  view: any;

  constructor(private eventService: EventService,
    private entityService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private metaDataService: MetaDataService,
    private configService: ConfigService,
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('id');
    this.view = this.route.snapshot.paramMap.get('view');
    this.activeIndex = Number(this.route.snapshot.paramMap.get('index'));
    this.setImgField();
    this.initializeForm();
    this.setLabels();
    this.setAppSettings();
    this.setRoutePath();
    this.setCountries();
    this.setGeoLocationField();
    this.getAttributesForTimeSetting();
    this.setDateFormatList();
    this.getCommonPresetLabels();
    this.setTimeSettingsEntityData();
  }

  ngAfterViewInit() {
    var elements = document.getElementById(this.view);
    elements.scrollIntoView();
  }

  setTimeSettingsEntityData() {
    this.timeSettingsEntityData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getLoggedInTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.TIME_SETTINGS
    }
  }

  getTimeSettings() {
    this.eventService.getEventSettingsDetailsByView(AppSettings.ENTITY_TYPE.TIME_SETTINGS, this.eventCode, {})
    .subscribe({
      next: (response: any) => {
        if (response) {
          this.timeSettingsEntityId = response.entityId;
          this.getEntityDetails();
        }
      },
      error: (error) => {
        this.showForm = true;
      }
    })
  }

  getEntityDetails() {
    this.eventService.getEventSettingsEntity(this.timeSettingsEntityId, AppSettings.ENTITY_TYPE.TIME_SETTINGS, AppSettings.VIEW_CODE.ADD_EDIT_VIEW, this.eventCode).subscribe({
      next: (response: any) => {
        this.attributeValues = this.cs.getOrgAttributeValues(response);
        this.showForm = true;
        this.cd.detectChanges();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    })
  }

  getCommonPresetLabels() {
    let requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      attributeCode: 'transportation_type',
      searchStr: '',
      usageRequired: true
    }
    this.entityService.getCommonPresetLabels(requestBody).subscribe((result: any) => {
      this.transportationTypeList = result.data.map(res => {
        return {
          labelValue: res.labelValue,
          labelKey: res.labelKey,
        }
      })
    })
  }

  setDateFormatList() {
    this.dateFormatList = [
      {
        name: 'MM/DD/YYYY',
        value: 'MM/DD/YYYY'
      },
      {
        name: 'DD/MM/YYYY',
        value: 'DD/MM/YYYY'
      },
      {
        name: 'YYYY/MM/DD',
        value: 'YYYY/MM/DD'
      }
    ];

    this.timeFormatList = [
      {
        name: '12- hours format',
        value: '12- hours format'
      },
      {
        name: '24- hours format',
        value: '24- hours format'
      }
    ];
  }

  setImgField() {
    this.imgField = {
      attributeId: AppSettings.ATTRIBUTE_ID_FOR_IMAGE_UPLOAD_IN_ORGANIZATION,
      attributeCode: 'event_image',
      attributeValue: null,
      inputCode: 'fileImg',
      imgId: null
    }
  }

  fileUploadEvent(event) {
    this.imgFileId = event[0];
  }

  setGeoLocationField() {
    this.geoLocationAddressField = {
      attributeCode: 'event_geolocation'
    };
    this.attributeLabels = {
      'event.fields.event_address.placeholder': 'select address'
    }
  }

  setCountries() {
    this.countries = Countries.COUNTRY_CODE;
  }

  initializeForm() {
    this.addEventForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(AppSettings.NAME_SPACE_PATTERN2)]],
      shortCode: ['', [Validators.required, Validators.pattern(AppSettings.SHORT_CODE)]],
      description: [''],
      event_address: ['', [Validators.required]],
      event_geolocation: ['', [Validators.required]],
      logo: [''],
      transportationType: ['', [Validators.required]],
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]],
      startBookingDate: ['', [Validators.required]],
      endBookingDate: ['', [Validators.required]],
      operatingRadius: ['', [Validators.required]],
      broadcastRadius: [''],
    });

    this.regionalSettingForm = this.formBuilder.group({
      shortDateFormat: ['', [Validators.required]],
      timeFormat: [''],
    });
    this.getEventDetails();
  }

  getEventDetails() {
    if (!this.eventId) {
      this.showForm = true
      return;
    }
    this.eventService.getEvent(this.eventId).subscribe( result => {
      this.eventDetails = result;
      this.setEventData();
    });
  }

  setEventData() {
    let operatingRadius = this.eventDetails.serviceAreaRadius;
    let broadcastRadius = this.eventDetails.broadcastRadius;
    if(this.unit === 'miles') {
      operatingRadius = this.cs.convertMetersToMiles(operatingRadius);
      broadcastRadius = this.cs.convertMetersToMiles(broadcastRadius);
    }
    else {
      operatingRadius = this.cs.convertMetersToKm(operatingRadius);
      broadcastRadius = this.cs.convertMetersToKm(broadcastRadius);
    }
    let transportationType = JSON.parse(this.eventDetails.transportationType);
    this.eventCode = this.eventDetails.eventShortcode;
    this.addEventForm.controls['name'].setValue(this.eventDetails.eventName);
    this.addEventForm.controls['shortCode'].setValue(this.eventDetails.eventShortcode);
    this.addEventForm.controls['description'].setValue(this.eventDetails.eventDescription);
    this.addEventForm.controls['transportationType'].setValue(transportationType);
    this.addEventForm.controls['startDate'].setValue(new Date(this.eventDetails.eventStartDateTime));
    this.addEventForm.controls['endDate'].setValue(new Date( this.eventDetails.eventEndDateTime));
    this.addEventForm.controls['startBookingDate'].setValue(new Date(this.eventDetails.startBookingDatePassengerApp));
    this.addEventForm.controls['endBookingDate'].setValue( new Date(this.eventDetails.stopBookingDatePassengerApp));
    this.addEventForm.controls['operatingRadius'].setValue(operatingRadius);
    this.addEventForm.controls['broadcastRadius'].setValue(broadcastRadius);

    this.regionalSettingForm.controls['shortDateFormat'].setValue(this.eventDetails.shortDateFormat);
    this.regionalSettingForm.controls['timeFormat'].setValue(this.eventDetails.timeFormat);
    this.imgFileId = this.eventDetails.eventLogoImgId;
    this.setGeoLocation();
    this.imgField.imgId = this.eventDetails.eventLogoImgId;
    this.showImgUpload = false;
    this.cd.detectChanges();
    this.showImgUpload = true;
    this.getTimeSettings();
  }

  setGeoLocation() {
    const geoLocationValue = {
      address: this.eventDetails.eventAddress,
      lat:  this.eventDetails.latitude,
      lng: this.eventDetails.longitude,
    };
    this.addEventForm.controls['event_address'].setValue(geoLocationValue);
    this.addEventForm.controls['event_geolocation'].setValue(this.eventDetails.eventAddress);
    this.showGeoLocation = false;
    this.cd.detectChanges();
    this.showGeoLocation = true;
  }

  setLabels() {
    this.nextBtnLabel = this.eventId ? this.cs.getLabel('label_update') : this.cs.getLabel('lbl_save');
  }

  setAppSettings() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.moduleName = AppSettings.ENTITY_TYPE.EVENTS;
    const tempUnit = this.metaDataService.systemOfMeasurement;
    this.unit = tempUnit === 'Imperial' ? 'miles' : 'km';
  }

  setAddressInPresetValuesIfNotPresent() {
    const addressField = this.eventAttributeData.tabs[0].groups[1].fields.find(element => element.attributeCode === 'address');
    if (!addressField) {
      return;
    }
    const addressPresent = addressField.presetValues.find(element => element.labelKey === this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue);
    this.eventAttributeData.tabs[0].groups[1].fields.forEach(element => {
      if (element.attributeCode === 'address') {
        element.presetValues.push({
          labelKey: this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue,
          labelValue: this.eventDetails.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'address')?.attributeValue,
        });
      }
    });
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.eventId ? this.cs.getLabel('events.edit_event') : this.cs.getLabel('events.add_event'),
        routerLink: '/app/events/list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      }
    ];
  }

  saveEvent() {
    if (this.activeIndex === 0) {
      if (!this.addEventForm.valid) {
        this.addEventForm.markAllAsTouched();
      } else {
        const requestBody  = this.setRequestBody();
        if (!this.eventId) {
          this.createEvent(requestBody);
        } else {
          this.updateEvent(requestBody);
        }
      }
    } else if(this.activeIndex === 1) {
      const element = document.getElementById('time');
      if (element) {
        element.click();
      }
    }

  }

  setRequestBody() {
    let operatingRadius = this.addEventForm.controls['operatingRadius'].value;
    let broadcastRadius = this.addEventForm.controls['broadcastRadius'].value;
    if(this.unit === 'miles') {
      operatingRadius = this.cs.convertMilesToMeters(operatingRadius);
      broadcastRadius = this.cs.convertMilesToMeters(broadcastRadius);
    }
    else {
      operatingRadius = this.cs.convertKmToMeters(operatingRadius);
      broadcastRadius = this.cs.convertKmToMeters(broadcastRadius);
    }
    let eventAddress = this.addEventForm.controls['event_address'].value;
    this.eventCode = this.addEventForm.controls['shortCode'].value;
    return {
      eventName: this.addEventForm.controls['name'].value,
      eventShortcode: this.addEventForm.controls['shortCode'].value,
      eventDescription: this.addEventForm.controls['description'].value,
      eventAddress: this.addEventForm.controls['event_geolocation'].value,
      latitude: eventAddress ? eventAddress.lat : 0,
      longitude: eventAddress ? eventAddress.lng : 0,
      eventLogoImgId: this.imgFileId,
      eventBannerImgId: this.addEventForm.controls['name'].value,
      transportationType: JSON.stringify(this.addEventForm.controls['transportationType'].value),
      eventStartDateTime: dayjs(new Date(this.addEventForm.controls['startDate'].value).getTime()).startOf('day').valueOf(),
      eventEndDateTime: dayjs(new Date(this.addEventForm.controls['endDate'].value).getTime()).endOf('day').valueOf(),
      startBookingDatePassengerApp: dayjs(new Date(this.addEventForm.controls['startBookingDate'].value).getTime()).startOf('day').valueOf(),
      stopBookingDatePassengerApp: dayjs(new Date(this.addEventForm.controls['endBookingDate'].value).getTime()).endOf('day').valueOf(),
      serviceAreaRadius: operatingRadius,
      broadcastRadius: broadcastRadius,
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: this.country[0].countryCode
    }
  }

  createEvent(requestBody) {
    this.eventService.createEvent(requestBody).subscribe({
      next : (res: any) => {
        this.eventId = res.eventId;
        this.eventService.setEventAdded(true);
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail:  this.cs.getLabel('events.event_added_successfully')});
        this.activeIndex += 1;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  updateEvent(requestBody) {
    this.eventService.updateEvent(requestBody, this.eventId).subscribe({
      next : (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: res.message });
        this.activeIndex += 1;
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

 
  onCancel() {
    this.miFormComponent.resetForm();
    this.router.navigate(['app/events/list']);
  }

  onFilter(event) {
    if (event.filter) {
      this.filterIcon = 'pi pi-times'
    } else {
      this.filterIcon = 'pi pi-search';
    }
  }

  getAttributesForTimeSetting() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_TYPE.TIME_SETTINGS,  AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe(res => {
      if (res) {
        this.attributeLabelsForTimeSettings = this.cs.getAttributeLabels(res);
        console.log(this.attributeLabelsForTimeSettings);
        this.timeSettingsAttributeData = this.cs.getOrganizedAttribute(res);
      }
    });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  saveTimeSettings(timeSettingsFormData) {
    if (this.eventCode) {
      if (this.timeSettingsEntityId) {
        if (!this.regionalSettingForm.valid) {
          this.regionalSettingForm.markAllAsTouched();
          const firstElementWithError = document.querySelector('.ng-invalid');
          this.scrollTo(firstElementWithError);
          return;
        }
        this.saveTimeSettingsData(AppSettings.ENTITY_TYPE.TIME_SETTINGS, timeSettingsFormData);
      } else {
        this.timeSettingsEntityData.eventCode = this.eventCode;
        this.eventService.createEntityForTimeSettings(AppSettings.ENTITY_TYPE.TIME_SETTINGS, this.timeSettingsEntityData).subscribe({
          next: (res: entityResponse) => {
            this.timeSettingsEntityId = res.entityId;
            this.saveTimeSettingsData(this.timeSettingsEntityData.entityCode, timeSettingsFormData);
          },
          error: (error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
          }
        });
      }
    } else {
      this.messageService.add({ key: 'tst', severity: 'info', summary: 'Info', detail: this.cs.getLabel('events.please_add_event_to_add_time_settings')});
    }
  }

  saveTimeSettingsData(entityCode, timeSettingsFormData) {
    this.timeSettingsEntityData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_TYPE.TIME_SETTINGS,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: []
    };
    for (const [key, value] of Object.entries(timeSettingsFormData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.timeSettingsEntityData.data.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.timeSettingsEntityData.data = this.cs.mapAttributeIds(this.timeSettingsEntityData.data, this.timeSettingsAttributeData.tabs);
    this.timeSettingsEntityData.eventCode = this.eventCode;
    this.eventService.saveTimeSettingsForEvent(entityCode, this.timeSettingsEntityId, this.timeSettingsEntityData).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.lbl_time_settings_saved_successfully') });
        this.saveRegionalSettings();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  saveRegionalSettings() {
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      shortDateFormat: this.regionalSettingForm.controls['shortDateFormat'].value,
      timeFormat: this.regionalSettingForm.controls['timeFormat'].value,
    }
    this.eventService.saveRegionalSettings(this.eventId, requestBody).subscribe({
      next: (res: any) => {
        this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('events.lbl_regional_settings_saved_successfully') });
        this.router.navigate(['app/events'])
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }
}
