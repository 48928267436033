import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import cloneDeep from 'lodash/cloneDeep';
import { SettingsService } from 'app/modules/settings/settings.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { CommonModule, NgClass } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { EventService } from 'app/modules/events/services/event.service';

@Component({
  selector: 'app-settings-notification-settings',
  standalone: true,
  imports: [TranslateModule, ButtonModule, ToastModule, CommonModule, InputTextModule, InputSwitchModule, CheckboxModule, ReactiveFormsModule, FormsModule, TabViewModule, NgClass],
  templateUrl: './settings-notification-settings.component.html',
  styleUrl: './settings-notification-settings.component.scss'
})
export class SettingsNotificationSettingsComponent implements OnInit {
  @ViewChild('nameInput', { static: false }) nameInput!: ElementRef;
  miIcons = AppIcons;
  language: Language;
  country: Country;
  modules;
  originalModules;
  searchValue: string = '';
  entityData: EntityList = {
    searchStr: "",
    countryCode: '',
    languageCode: '',
    eventCode: '',
    forTenantCode: this.configService.getLoggedInTenantCode(),
  };
  
  constructor(private cs: CommonBindingDataService, private configService: ConfigService, 
    private settingsService: SettingsService, private eventService: EventService,
    private cdr: ChangeDetectorRef, private confirmationService: ConfirmationService,
    private messageService: MessageService) {
  }

  ngOnInit() {
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.searchEntity();
  }

  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.languageCode = this.language[0].langCode;
    this.settingsService.searchNotifications(this.entityData).subscribe((res: any) => {
      this.modules = res;
      this.originalModules = cloneDeep(this.modules);
      this.createIconsFromModuleName();
      this.modules.forEach(module => {
        module.notificationMatrix.forEach(notification => {
          notification.title = notification.title.replace(/_/g, ' ').split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
        });
      });
    })
  }

  createIconsFromModuleName() {
    this.modules.forEach(module => {
      const iconClassName = module.moduleName.replace(/\s|\/+/g, "").toLowerCase();
      module.moduleIcons = `mi-${iconClassName} wh-24 mi-lg`;
    });
  }

  onConfigUpdate(event, notificationModuleConfigId) {
    const reqBody = {
      forTenantCode: this.configService?.getLoggedInTenantCode(),
      languageCode: this.language[0]?.langCode,
      countryCode: this.country[0]?.countryCode,
      eventCode: this.eventService?.getSelectedEventcode(),
      enabledModule: event?.checked
    }
    this.confirmationService.confirm({
      header: this.cs.getLabel('settings.notification.confirm_delete_header'),
      message: this.cs.getLabel('settings.notification.confirm_delete_msg'),
      acceptIcon: null,
      rejectIcon: null,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      acceptLabel: this.cs.getLabel('lbl_yes'),
      rejectLabel: this.cs.getLabel('lbl_no'),
      accept: () => {
        this.settingsService.updateNotificationConfig(notificationModuleConfigId, reqBody).subscribe(res => {
          this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.notification.notification_config_successfully') });
          this.searchEntity();
        })
      },
      reject: () => {
        this.searchEntity();
      }
    });
  }

  onSaveNotification() {
    const reqBody = {
      forTenantCode: this.configService?.getLoggedInTenantCode(),
      languageCode: this.language[0]?.langCode,
      countryCode: this.country[0]?.countryCode,
      eventCode: this.eventService?.getSelectedEventcode(),
      configs: []
    };

    for (let module of this.modules) {
      if (module.enabledModule) {
        for (let notification of module.notificationMatrix) {
          const notificationConfig = {
            notificationModuleConfigId: module.notificationModuleConfigId,
            notificationMatrixId: notification.notificationMatrixId,
            enabledPush: notification.enabledPush,
            enabledSms: notification.enabledSms,
            enabledEmail: notification.enabledEmail
          };
          notificationConfig.enabledPush = notification.enabledPush;
          notificationConfig.enabledSms = notification.enabledSms;
          notificationConfig.enabledEmail = notification.enabledEmail;
          reqBody.configs.push(notificationConfig);
        }
      }
    }
    this.settingsService.saveNotificationMatrix(reqBody).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('settings.notification.notification_matrix_successfully') });
      this.searchEntity();
    });
  }

  onCancel() {
    const isDataChanged = !this.areModulesEqual(this.originalModules, this.modules);
    if (isDataChanged) {
      this.confirmationService.confirm({
        header: this.cs.getLabel('settings.notification.lbl_confirmation'),
        message: this.cs.getLabel('settings.notification.confirm_discard_header'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.modules = cloneDeep(this.originalModules);
        },
        reject: () => {
        }
      });
    } else {
      this.searchEntity();
    }
  }

  areModulesEqual(originalModules, updatedModules) {
    return JSON.stringify(originalModules) === JSON.stringify(updatedModules);
  }

  onSearch(event) {
    const value = event.target.value;
    this.entityData.searchStr = value;
    this.searchEntity();
  }

  clearSearch() {
    this.searchValue = "";
    this.entityData.searchStr = "";
    this.searchEntity();
  }
}
