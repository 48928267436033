<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'VIEW' }">
<div class="grid mt-1 details-div border-bottom-1 border-200 mr-0">
    <div class="col-9">
        @if (showStatus) {
            <status [dataForStatus]="dataForStatus" [entityForStatus]="entityForStatus" [userId]="userId" [imgFileId]="imgFileId"></status>
        }
    </div>
    <div class="col-3 pr-3 text-right align-content-center">
        <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'DELETE' }">
            <button icon="mi-lg basic_tray" (click)="deleteDriver()" pButton class="border-600 bg-white text-color mr-1 delete-btn" [label]="deleteBtnLabel"></button>
        </div>
    </div>
</div>


<p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="activeIndexChange($event)" class="driver-detail-tab">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="mi-lg overview p-98px"></i>
                <label class="capitalize" translate>driver.lbl_overview</label>
            </ng-template>
            @if (driverAttributeData && driverDetails) {
                <app-driver-overview-details 
                    [attributeValues]="attributeValues"
                    [attributeLabels]="attributeLabels"
                    [driverAttributeData]="driverAttributeData"
                    [driverDetails]="driverDetails"
                    [driverGroupList]="driverGroupList">
                </app-driver-overview-details>
            }
        </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg specs"></i>
            <label translate>driver.lbl_assignment_history</label>
        </ng-template>
        <ng-template pTemplate="body">
            <app-assignment-history [driverDetails]="driverDetails"></app-assignment-history>
        </ng-template>

    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg journey-history"></i>
            <label translate>driver.lbl_journey_history</label>
        </ng-template>
        
    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg journey-history"></i>
            <label translate>driver.lbl_ratings_performance_analytics</label>
        </ng-template>
    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg journey-history"></i>
            <label translate>driver.lbl_training</label>
        </ng-template>
    </p-tabPanel>

    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg journey-history"></i>
            <label translate>driver.lbl_attendance</label>
        </ng-template>
    </p-tabPanel>
    <p-tabPanel>
        <ng-template pTemplate="header">
            <i class="mi-lg documents"></i>
            <label translate>driver.lbl_documents</label>
        </ng-template>
        <ng-template pTemplate="body">
            <app-document-list
                [attributeValues]="attributeValues"
                [attributeLabels]="attributeLabels"
                [driverDetails]="driverDetails"
                [entityCode]="entityCode">
            </app-document-list>
        </ng-template>

    </p-tabPanel>
</p-tabView>
</div>
