import { JsonPipe, NgClass, NgStyle } from '@angular/common';
import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DispatchMapComponent } from 'app/modules/dispatch/components/dispatch-map/dispatch-map.component';
import * as _ from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppIcons } from '../../../../shared/app.icons';
import { AppSettings } from '../../../../shared/app.settings';
import { StatusComponent } from '../../../../shared/components/status/status.component';
import { Country } from '../../../../shared/models/country';
import { Status } from '../../../../shared/models/status';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../../shared/services/config.service';
import { EntityService } from '../../../../shared/services/entity.service';
import { SpecificationComponent } from '../../../../vehicles/components/vehicle-details/specification/specification.component';
import type { AttributeData } from '../../../../vehicles/models/attribute.models';
import type { StatusData } from '../../../models/booking.models';
import { BookingService } from '../../../services/booking.service';
import { BookingStatusComponent } from '../../booking-status/booking-status.component';
import { ActionButtonsComponent } from '../../ui-components/action-buttons/action-buttons.component';
import { BookingHeaderComponent } from '../booking-header/booking-header.component';
import { BookingInfoComponent } from '../booking-info/booking-info.component';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { Language } from 'app/modules/shared/models/language';
import { BookingActivityComponent } from '../booking-activity/booking-activity.component';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-booking-details',
  standalone: true,
  imports: [
    BreadcrumbModule,
    StatusComponent,
    TabViewModule,
    TranslateModule,
    MenuModule,
    ToastModule,
    BookingInfoComponent,
    ButtonModule,
    SpecificationComponent,
    BookingHeaderComponent,
    ActionButtonsComponent,
    JsonPipe,
    NgStyle,
    BookingStatusComponent,
    BookingActivityComponent,
    DispatchMapComponent,
    AccessProviderDirective,
    DialogModule,
    CheckboxModule,
    FormsModule,
    CommonModule,
    NgClass
  ],
  templateUrl: './booking-details.component.html',
  styleUrl: './booking-details.component.scss'
})
export class BookingDetailsComponent {
  
  routePath: MenuItem[] = [];
  bookingId: string;
  dataForStatus: Array<Status> = [];
  userId: any = '232323';
  country: Country;
  activeIndex: any = 0;
  data: AttributeData;
  driverAttributeData: any;
  vehicleAttributeData: any;
  bookingAttributeData: any;
  attributeLabels: any;
  vehicleAttributeLength;
  bookingAttributeLength
  attributeValues: any;
  imgFileId: any;
  showStatus: boolean = false;
  bookingDetails: any;
  entityForStatus: any;
  deleteBtnLabel: string;
  reAssign: string;
  relatedData: any;
  vehicleRelatedData: any;
  passengerRelatedData: any;
  driverRelatedData: any;
  isStatusDialogShow: boolean = false;
  bookingStatus: any;
  statusData: StatusData;
  BOOKING_STATUS_LABEL = AppSettings.BOOKING.STATUS_BY_ID;
  miIcons = AppIcons;
  isReady = false;
  bookingStyle: any;
  geoLocationsData: any = {};
  language: Language;
  bookingStatusDisplayStr: any;
  visible: boolean = false;
  isMainBooking: boolean = false; 
  isPassengerSubBooking = [];
  isLuggageSubBooking = [];
  isAssignedBooking: boolean = false;
  bookingCode:any;
  confirmDialogVisible: boolean = false;
  confirmVisible: boolean = false; 
  bookingStyleStatus;
  bookingStatusId;
  bookingStatusAttributeId;
  relevantStatusCodes = ['3', '6','7','8','9', '12']; 
  @ViewChild(BookingActivityComponent) bookingActivity!: BookingActivityComponent;
  passengerBookingId;
  passengerIdData;
  luggageIdData;
  luggageBookingId;
  selectedEntityId;
  filteredLuggageIdData;
  onTheWayBookings = [];

  constructor(private route: ActivatedRoute,
    public cs: CommonBindingDataService,
    private configService: ConfigService,
    private entityService: EntityService,
    private bookingService: BookingService,
    private confService: ConfigService,
    private messageService: MessageService,
    private router: Router) {

  }

  ngOnInit() {
    this.setLabels();
    this.initData();
    this.setRoutePath();
  }

  isRelevantBookingStatus(status: string): boolean {
    return this.relevantStatusCodes.includes(status);
  }


  setLabels() {
    this.bookingId = this.route.snapshot.paramMap.get('id');
    this.deleteBtnLabel = this.cs.getLabel('lbl_delete');
    this.reAssign = this.cs.getLabel('bookings.reassign');
  }

  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel('bookings.header'),
        routerLink: '../current-bookings',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: this.cs.getLabel('bookings.details'),
        routerLink: '../' + this.bookingId,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  initData() {
    const apiCalls = [];
    apiCalls.push(this.getBookingDetails());
    apiCalls.push(this.getAttributeData());
    apiCalls.push(this.getDriverData());
    apiCalls.push(this.setBookingStatus());

    forkJoin(apiCalls).subscribe(() => {
      this.isReady = true;
      this.setBookingStatusLabel();
      this.bookingStyle = this.getStatusStyle(this.attributeValues['booking_status']);
    });
  }



  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    return this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.DETAIL_VIEW).pipe(
      tap(res => {
        this.data = res;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.bookingAttributeData = this.cs.getOrganizedAttribute(this.data);
        this.bookingAttributeLength = this.bookingAttributeData.tabs.length;
        this.driverAttributeData = _.find(res.relatedAttributes, { entityCode: 'driver' });
      }));
  }

  getDriverData() {
    return this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.DRIVER, AppSettings.VIEW_CODE.DRIVER_DETAILS_ON_BOOKING_VIEW).pipe(
      tap(() => {

      }));
  }

  getBookingDetails() {
    return this.entityService.getEntity(this.bookingId, AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.DETAIL_VIEW).pipe(
      tap((result: any) => {
        const response = _.cloneDeep(result);
        this.setBookingDetails(response);
    }))
    
  }


  setBookingDetails(result) {
    this.bookingDetails = result;
    this.attributeValues = this.cs.getOrgAttributeValues(result);
    this.attributeValues.id=this.bookingId;
    this.setBookingStatusLabel();
    this.relatedData = _.groupBy(result?.relatedData, 'entityCode');
    this.vehicleRelatedData = this.formattedArray(this.relatedData?.vehicle || []);
    this.passengerRelatedData = this.formattedArray(this.relatedData?.booking_pass || []);
    this.driverRelatedData = this.relatedData?.driver ? this.formattedArray(this.relatedData?.driver || []) : [];
    this.showStatus = true;
    this.passengerRelatedData.forEach(rowData => {
      if (rowData?.passenger_profile_image && (rowData?.passenger_profile_image[0])) {
        this.getFile(rowData)
      }
    });
    this.geoLocationsData = this.cs.getGeoLocations(this.attributeValues);
    this.passengerBookingId = this.bookingDetails.attributeCodeValueDtoList.find(item => item.attributeCode === 'passenger_booking_ids');
    const passengerBookingIdsEntry = result.attributeCodeValueDtoList.find(entry => entry.attributeCode === 'passenger_booking_ids');
    const passengerBookingIds = passengerBookingIdsEntry ? passengerBookingIdsEntry.attributeValue : null;

    this.passengerLuggageData();
  }

  passengerLuggageData(){
    this.passengerBookingId = this.bookingDetails.attributeCodeValueDtoList.find(item => item.attributeCode === 'passenger_booking_ids');
    if (this.passengerBookingId){
      const passengerIds = this.passengerBookingId.attributeValue;
      this.passengerIdData = [];

      const matchingRelatedData = this.bookingDetails.relatedData.filter(related => 
        passengerIds.includes(related.entityId) 
      );
  
      if (matchingRelatedData.length > 0) {
        matchingRelatedData.forEach(data => {
          const passengerBookingCode = this.getBookingCode(data);
          if (Number(passengerBookingCode.bookingStatus) <= 6) {
            this.luggageIdData.push({
              bookingCode: passengerBookingCode.bookingCode,
              entityId: data.entityId,
              bookingStatus: passengerBookingCode.bookingStatus,
              statusLabel: this.getBookingStatusLabel(passengerBookingCode.bookingStatus),
              statusStyle: this.getStatusStyle(passengerBookingCode.bookingStatus)
            });
          }else { 
            this.onTheWayBookings.push({
              bookingCode: passengerBookingCode.bookingCode,
              entityId: data.entityId,
              bookingStatus: passengerBookingCode.bookingStatus,
              statusLabel: this.getBookingStatusLabel(passengerBookingCode.bookingStatus),
              statusStyle: this.getStatusStyle(passengerBookingCode.bookingStatus)
            });
          }
        });
      } else {
        console.log('No matching related data found for passenger IDs');
      }
    }

    this.luggageBookingId = this.bookingDetails.attributeCodeValueDtoList.find(item => item.attributeCode === 'luggage_booking_ids');
    if (this.luggageBookingId){
      const luggageIds = this.luggageBookingId.attributeValue;
      this.luggageIdData = [];

      const matchingRelatedData = this.bookingDetails.relatedData.filter(related => 
        luggageIds.includes(related.entityId) 
      );

  
    if (matchingRelatedData.length > 0) {
  matchingRelatedData.forEach(data => {
    const luggageBookingCode = this.getBookingCode(data);
    if (Number(luggageBookingCode.bookingStatus) <= 6) {
      this.luggageIdData.push({
        bookingCode: luggageBookingCode.bookingCode,
        entityId: data.entityId,
        bookingStatus: luggageBookingCode.bookingStatus,
        statusLabel: this.getBookingStatusLabel(luggageBookingCode.bookingStatus),
        statusStyle: this.getStatusStyle(luggageBookingCode.bookingStatus)
      });
    } else { 
      this.onTheWayBookings.push({
        bookingCode: luggageBookingCode.bookingCode,
        entityId: data.entityId,
        bookingStatus: luggageBookingCode.bookingStatus,
        statusLabel: this.getBookingStatusLabel(luggageBookingCode.bookingStatus),
        statusStyle: this.getStatusStyle(luggageBookingCode.bookingStatus)
      });
    }
          });
        } else {
          console.log('No matching related data found for passenger IDs');
        }
    }
  }

  getBookingCode(related: any): { bookingCode: string, bookingStatus: string } {
    const bookingCodeObj = related.attributeCodeValueDtoList.find(
      (attribute) => attribute.attributeCode === 'booking_code'
    );
    const bookingStatusObj = related.attributeCodeValueDtoList.find(
      (attribute) => attribute.attributeCode === 'booking_status'
    );

    return {
      bookingCode: bookingCodeObj ? bookingCodeObj.attributeValue : 'N/A',
      bookingStatus: bookingStatusObj ? bookingStatusObj.attributeValue : 'N/A'
    };
  }

  getFile(passenger) {

    if (passenger?.passenger_profile_image) {
      this.entityService.getFile(passenger.passenger_profile_image[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onloadend = function() {
          const base64data = reader.result;
          passenger.passenger_profile_image = base64data;
        }
      })
    } else {
      return AppSettings.DEFAULT_LOADER_IMAGE;
    }
  }

  formattedArray(originalArray) {
    return originalArray.map(item => {
      const newObj = { entityId: item.entityId };
      item.attributeCodeValueDtoList.forEach(attr => {
        newObj[attr.attributeCode] = attr.attributeValue;
      });
      return newObj;
    });

  }

  showStatusDialog(event: MouseEvent, rowData) {
    event.stopPropagation();
    event.preventDefault();
    if (this.isStatusDialogShow) {
      this.isStatusDialogShow = false;
  }

    setTimeout(() => {
        this.isStatusDialogShow = true;
    }, 0);
    this.statusData = {
      bookingId: this.bookingId,
      currentStatus: rowData.booking_status || 'NEW_REQUEST',
      bookingStatusList: this.bookingStatus || []
    }
  }



  setBookingStatus() {
    return this.bookingService.getBookingStatus().pipe(
      tap((res) => {
        this.bookingStatus = res;
        this.bookingStatusId = this.getBookingStatusIdByCode("CANCELLED",this.bookingStatus);
        this.bookingStatus.forEach(booking => {
          this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
          booking.bookingStatusDisplayStr = JSON.parse(booking?.bookingStatusDisplayStr);
          booking.bookingStatusDisplayStr = booking?.bookingStatusDisplayStr?.filter(status => status.langId === this.language[0].languageId
            ,);
          
        });
      }))
  }

  getBookingStatusIdByCode(bookingCode: string, bookingStatus): string | undefined {
    const status = bookingStatus.find(status => status.bookingCode === bookingCode);
    return status ? status.bookingStatusId : undefined;
  }

  onCancelJourney() {
    this.visible = true;

  }

  onUpdatedStatus() {
    this.initData();
  }

  onHideStatusDialog() {

  }

  setBookingStatusLabel() {
    if (this.attributeValues?.booking_status && this.bookingStatus) {
      const selectedStatus = this.findStatusObj(this.attributeValues.booking_status);
      if (selectedStatus?.bookingStatusDisplayStr) {
        this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
        const displayStrings = selectedStatus.bookingStatusDisplayStr;
        this.bookingStatusDisplayStr = displayStrings.find(
          status => status.langId === this.language[0].languageId
        );
      }
    }
  }

  keepJourney(){
    this.visible = false;
  }

  showConfirmDialog() {
    this.visible = false;  
    this.confirmVisible = true; 
  }

  onGoBack() {
    this.confirmVisible = false; 
  }

  cancelBooking(){
    
    const bookingEntityId = this.selectedEntityId || this.bookingId;
    if (bookingEntityId) {
      this.onConfirmCancel(bookingEntityId);
    } 
  }

  onConfirmCancel(entityId: any) {

    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));

    const bookingData = {
      forTenantCode: this.confService.getForTenantCode(),
      entityCode: AppSettings.ENTITY_CODE.BOOKING,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
    };

    let apiCallCounter = 0;
    const totalCalls = this.isPassengerSubBooking.length + this.isLuggageSubBooking.length + (this.isMainBooking ? 1 : 0);

    const sendUpdate = (bookingId: string) => {
      const data = {
        forTenantCode: this.confService.getForTenantCode(),
        countryCode: this.country[0].countryCode,
        languageCode: this.language[0].langCode,
        bookingEntityId: bookingId,
        bookingStatus: this.bookingStatusId
      };

      this.bookingService.updateStatus(data).subscribe(res => {
        apiCallCounter++;
        // If all calls are done, show success message
        if (apiCallCounter === totalCalls) {
          this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {
              this.data = res;
              this.attributeLabels = this.cs.getAttributeLabels(this.data);
              this.bookingAttributeData = this.cs.getOrganizedAttribute(this.data);
              this.bookingAttributeLength = this.bookingAttributeData.tabs.length;
              this.driverAttributeData = _.find(res.relatedAttributes, { entityCode: 'driver' });
            });

          this.messageService.add({ 
            key: 'tst', 
            severity: 'success', 
            summary: 'Successful', 
            detail: this.cs.getLabel('bookings.booking_status_added') 
          });
        }
      });
    };

    if(this.isMainBooking){
      const bookingId = this.bookingId;
      bookingData.data.push({
        'attributeId': this.bookingStatusAttributeId,
        attributeValue : this.getBookingStatusIdByCode("CANCELLED",this.bookingStatus)
      });

      sendUpdate(bookingId);
      this.confirmVisible = false; 
    }
    


    for (let index = 0; index < this.isPassengerSubBooking.length; index++) {
      const bookingId = this.isPassengerSubBooking[index];
      bookingData.data.push({
        'attributeId': this.bookingStatusAttributeId,
        attributeValue : this.getBookingStatusIdByCode("CANCELLED",this.bookingStatus)
      });

      sendUpdate(bookingId);
      this.confirmVisible = false;
    }

    for (let index = 0; index < this.isLuggageSubBooking.length; index++) {
      const bookingId = this.isLuggageSubBooking[index];
      bookingData.data.push({
        'attributeId': this.bookingStatusAttributeId,
        attributeValue : this.getBookingStatusIdByCode("CANCELLED",this.bookingStatus)
      });

      sendUpdate(bookingId);
      this.confirmVisible = false; 
  
    }
   
  }

  onStatusUpdated(): void {
    if (this.bookingActivity) {
      this.bookingActivity.getFilterView();
    }
  }

  getStatusColor(statusCode: string, type: 'background' | 'text'): string {
    const status = this.bookingStatus.find(s => s.bookingStatusId === statusCode);
    if (!status) return '';
    return type === 'background' ? status.backgroundColorCode : status.textColorCode;
  }


  getBookingStatusLabel(status) { 
    const selectedBookingStatus = this.findStatusObj(status);
    return selectedBookingStatus?.bookingStatusDisplayStr?.[0]?.label
  }

  getStatusStyle(status) {
    const selectedBookingStatus = this.findStatusObj(status);
    return { 'background-color': selectedBookingStatus?.backgroundColorCode || "#ddeeff", 'color': selectedBookingStatus?.textColorCode || "#1e90ff" };
  }
  
  private findStatusObj(selectedStatus) {
    return this.bookingStatus?.find(ele => ele.bookingStatusId === selectedStatus);
  }

}
