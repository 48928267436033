<div class="main-settings">
    <p-tabView>
        @for(tab of passengerAttributes?.tabs; track tab?.tabCode) {
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <i class="{{miIcons.CALENDAR_CHECK}} inline-block wh-14 mr-2 relative"></i>
                    <span translate>settings.lbl_default_values</span>
                </ng-template>
                <div class="">
                    <app-mi-form [attributeLabels]="attributeLabels" [attributeValues]="attributeValues" [moduleName]="moduleName" [previousBtnText]="previousBtnLabel"
                        (previousClick)="onPreviousClick()" [isHorizontalForm]="true" [groups]="tab.groups" (saveData)="onSavePrivilegeSettings($event)"
                        [submitBtnText]="saveLabel"></app-mi-form>
                </div>
            </p-tabPanel>
        }
    </p-tabView>
</div>
